import axios from "axios";

export default {
  async GET_BY_ID({ commit }, payload) {
    const { data } = await axios.get(`doctors/${payload.id}`);
    payload.onData(data);
  },

  async GET_TWILIO_TOKEN({ commit }, onData) {
    const { data } = await axios.get(
      "chat/generateToken?userId=" +
        (JSON.parse(localStorage.getItem("userInfo")).clientId ||
          JSON.parse(localStorage.getItem("userInfo"))._id)
    );
    onData(data);
  },

  async GET_LIST({ commit }, payload) {
    const { data } = await axios.get("doctors", {
      params: { ...payload.params },
    });
    payload.onData(data);
    return data;
  },

  async GET_PENDING_APPOINTMENTS({ commit }, payload) {
    const { data } = await axios.get("users/appointments", {
      params: { status: "PENDING", ...payload.paginate },
    });
    payload.onData(data);
  },

  async UPDATE_APPOINTMENT({ commit }, payload) {
    const { data } = await axios.put(`folder/${payload._id}`, payload.data);
    payload.onData(data);
  },

  async SCHEDULE_APPOINTMENT({ commit }, payload) {
    const { data } = await axios.post(
      `doctors/${payload.doctorId}/schedule-appointment`,
      payload.data
    );
    payload.onData(data);
  },

  async CREATE_USER({ commit }, payload) {
    const { data } = await axios.post("doctors", payload);
    if (data.ok) commit("ADD_ITEM", data.data);
    return data;
  },

  REGISTER({ commit }, payload) {
    return axios.post("users/register", payload);
  },

  async UPDATE_USER({ commit }, payload) {
    const { data } = await axios.put(`users/${payload._id}`, payload);
    if (data.ok) commit("EDIT_ITEM", { index: payload.index, user: data.data });
    return data;
  },

  async UPDATE_PROFILE({ commit }, payload) {
    const { data } = await axios.put(`users/me`, payload);
    payload.onData(data);
  },

  async DELETE({ commit }, payload) {
    const { data } = await axios.delete(`users/${payload}`);
    if (data.ok) commit("DELETE", payload);
    return data;
  },

  async GET_SPECIALITIES_LIST({ commit }, payload) {
    const { data } = await axios.get("specialities", {
      params: { ...payload.params },
    });
    return data;
  },

  async GATHER_INFO({ commit }, payload) {
    const { data } = await axios.post(`doctors/gather-info`, payload.data);
    payload.onData(data);
  },

  async PREFILL_BY_INPE({ commit }, payload) {
    const { data } = await axios.get(`inpes`, { params: payload.params });
    payload.onData(data);
  },

  async GET_MY_DOCTORS({ commit }, payload) {
    const { data } = await axios.get("mydoctors", {
      params: { ...payload.params },
    });
    payload.onData(data);
    return data;
  },

  async SAVE_PROFILE({}, payload) {
    const { data } = await axios.post("doctors/myprofile", {
      ...payload.params,
    });
    payload.onData(data);
    return data;
  },

  async PREVIEW_MY_MODEL({}, payload) {
    const { data } = await axios.post("prescription/preview/mymodels", {
      ...payload.params,
    });
    payload.onData(data);
    return data;
  },

  async CREATE_PATIENT({ commit }, payload) {
    const data = await axios.post("doctors/patient/add", payload);

    return data;
  },

  async GET_ASSISTANTS({}, payload) {
    const { data } = await axios.get(`doctors/assistants`, payload);
    if (payload.onData) payload.onData(data);
  },

  async GET_NOTIFICATIONS({}, payload) {
    const { data } = await axios.get(`doctors/notifications`, {
      params: {
        page: payload.page,
      },
    });
    payload.onData(data);
  },

  async GET_UNREADED_NOTIFICATIONS({}, payload) {
    const { data } = await axios.get(`doctors/notifications/unread`);
    payload.onData(data);
  },

  async MARK_NOTIFICATION_AS_READ({}, notificationId) {
    const { data } = await axios.get(`doctors/notifications/${notificationId}`);
  },

  async GET_NOTIFICATION({}, { notificationId, onData }) {
    const { data } = await axios.get(
      `doctors/notifications/${notificationId}/show`
    );
    onData(data);
  },

  async GET_CITY_SEO({}, { city, query, onData }) {
    const { data } = await axios.get(`seo/${city}`, { params: { query } });
    onData(data);
  },

  async UPLOAD_FILE(_, { type, doctorId, file, onData }) {
    let endpoint = `doctors/${doctorId}/rib/files`;

    let formData = new FormData();
    formData.append("file", file);
    const { data } = await axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    onData(data);
  },

  async GET_CONSULTIONS_OF_MONTH({}, payload) {
    const { data } = await axios.get(`doctors/payment/consultations`);
    return data;
  },

  async SAVE_PROFILE_ORDONNANCE({ commit }, payload) {
    const { data } = await axios.post(
      "doctors/profile/ordonnance",
      payload.params
    );
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },

  async GET_HISTORIQUE_CONSULTATIONS_PATIENT_BY_CURRENT_DOCTOR({}, payload) {
    const { data } = await axios.get(
      `doctors/historique/consultations/${payload.patient_id}`
    );
    return data;
  },

  async GET_ACTS_BY_SPECIALITY({ commit }, payload) {
    const { data } = await axios.get(`doctors/acts/list`, {
      params: { specs: payload.specs },
    });
    return data;
  },

  async GET_ACTE_BY_ID({ commit }, payload) {
    const { data } = await axios.get(`/doctors/acte/${payload.id}`);
    return data;
  },

  async VALIDATE_ONBOARDING_FORM({ commit }, payload) {
    const { data } = await axios.post(`/onboarding`, {
      formData: payload.formData,
    });
    return data;
  },
};
