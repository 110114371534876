<template>
  <div
    class="bg-red-600 w-full origin-top fixed py-3 flex items-center justify-center text-white text-lg px-5 xl:px-0"
    style="z-index: 9000"
  >
    Veuillez confirmer votre numéro de téléphone Vérification

    <router-link
      :to="{ name: '2fa' }"
      class="ml-3 bg-transparent border-2 border-solid border-white py-2 px-2 text-white hover:bg-white hover:text-red-600 rounded-cu cursor-pointer"
    >
      Activation
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>