import axios from "axios";
import { EventBus } from "@/event-bus";

export default {
  async GET_LIST({ commit }, payload) {
    let parent = payload.parent || "";
    const { data } = await axios.get(`files/${payload.folder}/${parent}`);
    commit("SET_LIST", data.data);
  },
  async GET_BY_CLIENT({ commit }, payload) {
    const { data } = await axios.get(`consultations/files`, {
      params: payload.params
    });
    payload.onData(data);
  },
  async SET_CURRENTFILE({ commit }, payload) {
    commit("UDATE_CURRENTFILE", payload);
  },
  async GO_BACK({ commit }) {
    commit("GO_TO_THE_PARENT");
  },
  async UPLOAD_FILES({ commit }, payload) {
    let formData = new FormData();
    formData.append("parent", payload.parent);
    for (let index = 0; index < payload.data.length; index++) {
      const file = payload.data[index];
      formData.append("files[]", file);
    }
    const { data } = await axios.post(`upload/files/${payload.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    if (data.ok) {
      commit("ADD_FILE", data);
    }
  },
  async CREATE_DIRECTORY({ commit }, payload) {
    const { data } = await axios.post(`create/directory`, payload.data);
    if (data.ok) {
      commit("ADD_FILE", data);
    }
  },
  async GET_FILES_CATEGORIES({ commit }, payload) {
    const { data } = await axios.get(`files/categories`);
    payload.onData(data);
  },
  async UPLODAD_DOCUMENT({ commit }, payload) {
    let formData = new FormData();
    Object.keys(payload.data).forEach(key => {
      if (Array.isArray(payload.data[key])) {
        payload.data[key].forEach(item => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(key, payload.data[key]);
      }
    });
    const { data } = await axios.post(`upload/document`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    payload.onData(data);
  },
  async SHARE_WITH({ commit }, payload) {
    const { data } = await axios.post(`files/${payload.fileID}/share`, {
      shareWith: payload.shareWith
    });
    payload.onData(data);
  },
  async DELETE_FILE({ commit }, payload) {
    const { data } = await axios.delete(
      `files/${payload.id}/delete`,
      payload.data
    );
    payload.onData(data);
  },
  async UPLOAD_IDENTITY_DOCUMENT({ commit }, { file, token }) {
    let _this = this;
    let formData = new FormData();
    formData.append("file", file.file);
    formData.append("token", token);
    const { data } = await axios.post(
      `files/identity?type=${file.type}`,
      formData,
      {
        onUploadProgress: progressEvent => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          EventBus.$emit("CountProgressUpload", percentage);
        },
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    return data;
  },
  async GET_IDENTITY_DOCUMENT({ commit }, { onData, token }) {
    const { data } = await axios.get(`files/identity`, { params: { token } });
    onData(data);
  }
};
