"use strict";

import axios from "axios";

export default {
  async GET_SUGGESTIONS({}, payload) {
    const { data } = await axios.get(`dictionary/${payload.terme}`);
    return data;
  }
};
