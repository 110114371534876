import getDefaultState from "./State";
import Vue from "vue";

export default {
  SET_DATE_PICKET(state, payload) {
    state.pickerDate = payload;
  },
  SET_EVENTS(state, payload) {
    state.eventsAgenda = payload;
  },
  SET_VIEW(state, payload) {
    state.typeAgenda = payload;
  },
  SET_SELECTED_SLOT(state, payload) {
    state.selectedSlot = payload;
  },
  SET_LIST(state, payload) {
    state.calendarEvents = payload;
  },
  ADD_EVENT_TO_CALENDAR(state, event) {
    const length = state.calendarEvents.length;
    let lastIndex = 0;
    if (length) {
      lastIndex = state.calendarEvents[length - 1]._id;
    }
    event.id = lastIndex + 1;
    const newEvent = Object.assign({}, event);
    state.calendarEvents.push(newEvent);
  },
  EDIT_CALENDAR_EVENT(state, event) {
    const eventIndex = state.calendarEvents.findIndex(e => e._id == event._id);
    state.calendarEvents[eventIndex] = event;
  },
  REMOVE_CALENDAR_EVENT(state, eventId) {
    const eventIndex = state.calendarEvents.findIndex(e => e._id == eventId);
    state.calendarEvents.splice(eventIndex, 1);
  },
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_REASONS(state, payload) {
    state.reasons = payload;
  },
  SET_SLOTS(state, payload) {
    state.slots = payload;
  }
};
