import axios from "axios";
import { isArray } from "lodash";

export default {
  async GET_BY_ID({ commit }, payload) {
    const { data } = await axios.get(`folder/${payload.id}`);
    payload.onData(data);
  },

  async GET_USER({ commit }, payload) {
    const { data } = await axios.get(`consultations/${payload.id}/user`);
    payload.onData(data);
  },

  async GET_USER_CONSULTATION({ commit }, payload) {
    const { data } = await axios.get(`consultations/check/user`);
    payload.onData(data);
  },

  async GET_FILES({ commit }, payload) {
    const { data } = await axios.get(`consultations/${payload.id}/documents`);
    payload.onData(data);
  },

  async ASSIGN_FILE({ commit }, payload) {
    let formData = new FormData();
    Object.keys(payload.data).forEach(key => {
      if (Array.isArray(payload.data[key])) {
        payload.data[key].forEach(item => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(key, payload.data[key]);
      }
    });
    const { data } = await axios.post(
      `consultations/${payload.id}/files`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    payload.onData(data);
  },

  async REMOVE_FILE({ commit }, payload) {
    const { data } = await axios.delete(
      `consultations/${payload.consultationId}/documents/${payload.documentId}`
    );
    if (payload.onData) {
      payload.onData(payload.documentId, data);
    }
  },

  // Medicament
  async GET_PRESCRIPTIONS({ commit }, payload) {
    const { data } = await axios.get(`prescription/${payload.id}`, {
      params: { type: payload.category || "DRUG" }
    });
    payload.onData(data);
  },

  async SAVE_PRESCRIPTION_NOTE({ commit }, payload) {
    const { data } = await axios.put(
      `prescription/${payload.id}?type=${payload.category || "DRUG"}`,
      {
        note: payload.note,
        subcategory: payload.subcategory ? payload.subcategory : null
      }
    );

    payload.onData(data);
  },

  async SAVE_PRESCRIPTION_MULTI_NOTES({ commit }, payload) {
    const { data } = await axios.put(
      `prescription/${payload.id}/notes?type=${payload.category || "DRUG"}`,
      {
        note: payload.note,
        subcategory: payload.subcategory ? payload.subcategory : null
      }
    );

    payload.onData(data);
  },

  async GET_PRESCRIPTION_ITEMS({ commit }, payload) {
    const { data } = await axios.get(`prescription/${payload.id}/items`);
    payload.onData(data);
  },

  async CREATE_PRESCRIPTIONS({ commit }, payload) {
    const { data } = await axios.post(
      `prescription/${payload.id}/items`,
      payload.data
    );
    payload.onData(data);
  },

  async UPDATE_PRESCRIPTIONS({ commit }, payload) {
    const { data } = await axios.put(
      `prescription/${payload.id}/items`,
      payload.data
    );
    payload.onData(data);
  },

  async DELETE_PRESCRIPTIONS({ commit }, payload) {
    const { data } = await axios.delete(
      `prescription/${payload.id}/items`,
      payload.data
    );
    payload.onData(data);
  },
  // End Medicament

  // BIOLOGIQUE
  async CREATE_BIOLOGIQUE({ commit }, payload) {
    const { data } = await axios.post(
      `prescription/${payload.id}/items?type=BIOLOGICAL`,
      payload.data
    );
    payload.onData(data);
  },
  // End BIOLOGIQUE

  // SYMPTOMES
  async GET_SYMPTOMES({ commit }, payload) {
    const { data } = await axios.get(`symptom/${payload.id}`);
    payload.onData(data);
  },

  async UPDATE_SYMPTOMES({ commit }, payload) {
    const { data } = await axios.post(`symptom/${payload.id}`, {
      note: payload.note
    });
    payload.onData(data);
  },
  // End SYMPTOMES

  // DIAGNOSTIC
  async GET_DIAGNOSTIC({ commit }, payload) {
    const { data } = await axios.get(`diagnostic/${payload.id}`);
    payload.onData(data);
  },

  async UPDATE_DIAGNOSTIC({ commit }, payload) {
    const { data } = await axios.post(`diagnostic/${payload.id}`, {
      note: payload.note
    });
    payload.onData(data);
  },
  // End DIAGNOSTIC

  // NOTE CONFIDENTIELLE
  async GET_NOTE_CONFIDENTIELLE({ commit }, payload) {
    const { data } = await axios.get(`confidential-note/${payload.id}`);
    payload.onData(data);
  },

  async UPDATE_NOTE_CONFIDENTIELLE({ commit }, payload) {
    const { data } = await axios.post(`confidential-note/${payload.id}`, {
      note: payload.note
    });
    payload.onData(data);
  },
  // End CONFIDENTIELLE

  // NOTE EXPLORATION_EXAMEN
  async GET_EXPLORATION_EXAMEN({ commit }, payload) {
    const { data } = await axios.get(`exploration-examen/${payload.id}`);
    payload.onData(data);
  },

  async UPDATE_EXPLORATION_EXAMEN({ commit }, payload) {
    const { data } = await axios.post(`exploration-examen/${payload.id}`, {
      note: payload.note
    });
    payload.onData(data);
  },
  // End EXPLORATION_EXAMEN

  // ACTES BIOLOGIQUE
  async GET_ACTS({ commit }, payload) {
    const { data } = await axios.get(`acts`, {
      params: {
        term: payload.term,
        consultationId: payload.consultationId
      }
    });
    payload.onData(data);
  },

  async CREATE_ACTS({ commit }, payload) {
    const { data } = await axios.post(
      `prescription/${payload.id}/items?type=ACTS`,
      payload.data
    );
    payload.onData(data);
  },

  async DELETE_ACTES({ commit }, payload) {
    const { data } = await axios.delete(
      `prescription/${payload.id}/items`,
      payload.data
    );
    payload.onData(data);
  },

  async GET_STANDARD_ACTBIOS({ commit }, payload) {
    const { data } = await axios.get(`acts/standards`, {
      params: {
        term: payload.term,
        consultationId: payload.consultationId
      }
    });
    payload.onData(data);
  },

  async GET_MY_FAVORITE_ACTS({ commit }, payload) {
    const { data } = await axios.get(`acts/myfavorites`, payload.data);
    if (payload.onData) payload.onData(data);
  },

  async ADD_ACTS_TO_FAVORITE({ commit }, payload) {
    const { data } = await axios.post(`acts/myfavorites`, payload.data);
    if (payload.onData) payload.onData(data);
  },

  async REMOVE_ACTS_TO_FAVORITE({}, payload) {
    if (!payload.favoriteId) return null;
    const { data } = await axios.delete(
      `acts/myfavorites/${payload.favoriteId}`,
      {}
    );
    if (payload.onData) payload.onData(data);
  },
  // END ACTES BIOLOGIQUE

  // ACTES  IMAGE
  async GET_STANDARD_IMAGERIES({ commit }, payload) {
    let level = payload.level ? payload.level : 1;
    let url = `acts/imageries?level=${level}`;
    if (payload.cat) {
      url += "&cat=" + payload.cat;
    }
    if (payload.subcat) {
      url += "&subcat=" + payload.subcat;
    }
    const { data } = await axios.get(url, {});
    if (payload.onData) payload.onData(data);
  },

  async ADD_IMAGE_TO_PRESCRIPTION({ commit }, payload) {
    const { data } = await axios.post(
      `prescription/${payload.id}/items?type=IMAGERY`,
      payload.data
    );
    payload.onData(data);
  },

  async REMOVE_IMAGERIE_ROM_FAVORITE({ commit }, payload) {
    if (!payload.favoriteId) return null;
    const { data } = await axios.delete(
      `acts/imageries/myfavorites/${payload.favoriteId}`,
      {}
    );
    if (payload.onData) payload.onData(data);
  },

  async REMOVE_IMAGE_FROM_PRESCRIPTION({ commit }, payload) {
    const { data } = await axios.delete(
      `prescription/${payload.id}/items/${payload.refId}`,
      {}
    );
    payload.onData(data);
  },

  async GET_MY_FAVORITE_IMAGERIE({ commit }, payload) {
    const { data } = await axios.get(
      `acts/imageries/myfavorites`,
      payload.data
    );
    if (payload.onData) payload.onData(data);
  },

  async ADD_IMAGERIE_TO_FAVORITE({ commit }, payload) {
    const { data } = await axios.post(
      `acts/imageries/myfavorites`,
      payload.data
    );
    if (payload.onData) payload.onData(data);
  },
  // END ACTES  IMAGE

  // Generate
  async GENERATION_DOCUMENT({ commit }, payload) {
    const { data } = await axios.get(
      `prescription/${payload.data.id}/render` +
        (payload.data.type ? "?type=" + payload.data.type : "") +
        (payload.data.typeAnalyses
          ? "&typeAnalyses=" + payload.data.typeAnalyses
          : "")
    );
    payload.onData(data);
  },
  // End Generate

  // SMA
  async GET_QUESTIONS({ commit }, payload) {
    const data = await axios.get(`sma/questions?consultationId=${payload.id}`);
    return data;
  },

  async DELETE_QUESTION({ commit }, payload) {
    // //console.log(payload);
  },

  async UPDATE_QUESTION({ commit }, payload) {
    const data = await axios.put(`sma/questions/${payload.uid}`, payload);
    return data;
  },

  async DELETE_ANSWER({ commit }, payload) {
    // //console.log(payload);
  },

  async UPDATE_ANSWER({ commit }, payload) {
    const data = await axios.put(`sma/choices/${payload.uid}`, payload);
    return data;
  },

  async GET_RESULTS_SMA({ commit }, payload) {
    const { data } = await axios.get(`sma-questions/${payload.id}`);
    payload.onData(data);
  },

  async GET_SMA_CONFIG({}, { consultationId, onData }) {
    const { data } = await axios.get(`sma-questions/${consultationId}/config`);
    onData(data);
  },

  async UPDATE_SMA_CONFIG({}, { consultationId, config, onData }) {
    const { data } = await axios.put(
      `sma-questions/${consultationId}/config`,
      config
    );
    onData(data);
  },

  async DELETE_SMA_CONFIG({}, { consultationId, onData }) {
    const { data } = await axios.delete(
      `sma-questions/${consultationId}/config`
    );
    onData(data);
  },

  async PROPOSE_NEW_DATE({}, { consultationId, start, onData }) {
    const { data } = await axios.put(
      `consultations/${consultationId}/new-proposition`,
      {
        start
      }
    );
    onData(data);
  },

  async PROPOSE_UPDATE_DATE(
    {},
    { consultationId, status, notificationId, onData }
  ) {
    const { data } = await axios.put(
      `consultations/${consultationId}/update-proposition`,
      {
        status,
        notificationId
      }
    );
    onData(data);
  },

  // Update Appointments Notifications
  async UPDATE_APPONTMENT_NOTIFICATIONS({ commit }, payload) {
    const { data } = await axios.get(
      `consultations/notifications/seen`,
      payload
    );
    return data;
  },

  async UPLOAD_RAPPORT_FILE(_, { type, consultationId, file, onData }) {
    let endpoint;
    switch (type) {
      case "symptomes":
        endpoint = `symptom/${consultationId}/files`;
        break;
      case "examen":
        endpoint = `exploration-examen/${consultationId}/files`;
        break;
      case "diagnostic":
        endpoint = `diagnostic/${consultationId}/files`;
        break;
    }
    let formData = new FormData();
    formData.append("file", file);
    const { data } = await axios.post(endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    onData(data);
  },

  async GET_RAPPORT_FILES(_, { type, consultationId, onData }) {
    let endpoint;
    switch (type) {
      case "symptomes":
        endpoint = `symptom/${consultationId}/files`;
        break;
      case "examen":
        endpoint = `exploration-examen/${consultationId}/files`;
        break;
      case "diagnostic":
        endpoint = `diagnostic/${consultationId}/files`;
        break;
    }

    const { data } = await axios.get(endpoint, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    onData(data);
  },

  async SWITCH_CONSULTATION_TO_VIDEO({ commit }, payload) {
    const { data } = await axios.get(
      `agenda/events/${payload.id}/switch-to-video`
    );
    payload.onData(data);
  },

  /**
   * PARA_MEDICAL ACTIONs
   */
  async GET_SOINS_PARA_MEDICAL_MODELS({}, payload ) {
    const { data } = await axios.get(`acts/soins-paramedicaux`);
    payload.onData(data);
  },
   async GET_SOINS_PARA_MEDICAL_MY_MODELS({}, payload ) {
    const { data } = await axios.get(`acts/soins-paramedicaux/${payload.modelId}/submodels`);
    payload.onData(data);

  },
  async SAVE_DOCTOR_PARA_MEDICAL_MODEL({}, payload) {

    const { data } = await axios.post(
      `acts/soins-paramedicaux/models/save`,
      payload.params
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async UPDATE_DOCTOR_PARA_MEDICAL_MODEL({}, payload) {
    const { data } = await axios.put(
      `acts/soins-paramedicaux/models/${payload.modelId}`,
      payload.params
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async DELETE_DOCTOR_PARA_MEDICAL_MODEL({}, payload) {
    const { data } = await axios.delete(
      `acts/soins-paramedicaux/models/${payload.modelId}`,{}
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async GET_SOINS_PARA_MEDICAL_CONSULTATION({}, payload) {
    const { data } = await axios.get(
      `acts/soins-paramedicaux/consultation/${payload.consultationId}`
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async SAVE_PARAMEDICAL_SOIN_FOR_PATIENT({}, payload){
    const { data } = await axios.post(
      `acts/soins-paramedicaux/consultation/${payload.consultationId}`,
      payload.params
    );

    return data;
  },
  async GENERATE_PARAMEDICAL_SOIN({}, payload) {
    const { data } = await axios.get(
      `acts/soins-paramedicaux/consultation/${payload.paraSoinId}/generate`
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async UPDATE_PARAMEDICAL_SOIN_FOR_PATIENT({}, payload) {
    const { data } = await axios.put(
      `acts/soins-paramedicaux/consultationPatient/${payload.patientSoinId}`,
      payload.params
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async REMOVE_PARAMEDICAL_SOIN_FOR_PATIENT({}, payload) {
    const { data } = await axios.delete(
      `acts/soins-paramedicaux/consultationPatient/${payload.patientSoinId}`
    );
    if (payload.onData) {
      payload.onData(payload.patientSoinId, data);
    }
  },
};
