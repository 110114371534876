<template>
  <div class="w-full flex flex-col" :class="label && 'gap-y-1'">
    <label
      :for="`${label}-${generationRandom}`"
      class="font-EffraR text-base text-gray-800"
      >{{ label }}</label
    >
    <date-picker
      v-model="value"
      :value-type="format"
      type="date"
      :input-class="sizeInput"
      @change="setDate"
    >
      <template v-slot:icon-calendar>
        <img src="/svg/calendar_sm.svg" />
      </template>
    </date-picker>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";

export default {
  props: {
    format: {
      type: String,
      required: false,
      default: "YYYY-MM-DD"
    },
    dModel: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: "sm" // sm, md, lg
    },
    label: {
      type: String,
      required: false
    }
  },
  watch: {
    dModel(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.value = newVal;
      }
    }
  },
  components: { DatePicker },
  computed: {
    sizeInput() {
      switch (this.size) {
        case "sm":
          return "dok-date-input-sm";
          break;
        case "md":
          return "dok-date-input-md";
          break;
        case "lg":
          return "dok-date-input-lg";
          break;
        default:
          return "dok-date-input-lg";
      }
    }
  },
  data() {
    return {
      value: null,
      generationRandom: Math.random()
    };
  },
  created() {
    this.value = this.dModel;
  },
  methods: {
    setDate(date) {
      this.value = date;
      this.$emit("update:dModel", date);
    }
  }
};
</script>

<style lang="scss">
.dok-date-input-sm {
  height: 32px;
  font-size: 16px;
  @apply border-2 border-solid border-borderInput rounded px-1 w-full font-EffraR text-black;
}
.dok-date-input-md {
  height: 40px;
  font-size: 16px;
  @apply border-2 border-solid border-borderInput rounded px-1 w-full font-EffraR text-black;
}
.dok-date-input-lg {
  height: 48px;
  font-size: 16px;
  @apply border-2 border-solid border-borderInput rounded px-1 w-full font-EffraR text-black;
}
.mx-datepicker {
  width: 100% !important;
}
</style>