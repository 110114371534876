/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/factures/pixinvent
==========================================================================================*/
import axios from "axios";
export default {
  async GET_LIST({
    commit
  }, payload) {
    const {
      data
    } = await axios.get("facture", {
      params: payload ? payload.search : {}
    });
    commit("SET_LIST", data);
  },
  async CREATE_FACTURE({
    dispatch
  }, payload) {
    const {
      data
    } = await axios.post("facture", payload);
    if (data.ok) {
      dispatch("GET_LIST");
    }
    return data;
  },
  async UPDATE_FACTURE({
    commit
  }, payload) {
    const {
      data
    } = await axios.put(`facture/${payload._id}`, payload);
    if (data.ok) {
      commit("EDIT_ITEM", {
        index: payload.index,
        facture: data.data
      });
    }
  },
  async DELETE({
    commit
  }, payload) {
    const {
      data
    } = await axios.delete(`facture/${payload}`);
    if (data.ok) commit("DELETE", payload);
  }
};
