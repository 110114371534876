import Vue from "vue";
import VueI18n from "vue-i18n";
import i18nData from "./i18nData";
import router from "../router";

Vue.use(VueI18n);


let localStorageLang = localStorage.getItem('sessionLang');

export default new VueI18n({
  navigator: 'fr',
  locale: localStorageLang &&  window.location.pathname === '/' ? JSON.parse(localStorageLang).default : 'fr', // Default Fr
  fallbackLocale: "fr",
  messages: i18nData
});
