<template>
  <div class="relative">
    <div
      class="z-40 bg-dokBlue-lighter bg-opacity-10 fixed inset-0"
      v-if="drop"
      @click="drop = false"
    ></div>
    <div
      :class="`
        ${sizeInput}
        bg-white
        text-black
        font-EffraR
        text-lg
        border border-solid border-borderInput px-5
        flex items-center
        cursor-pointer
        `"
      @click="drop = !drop"
    >
      <div class="flex justify-between items-center w-full gap-x-3">
        <slot name="name"> </slot>
        <i class="fas fa-angle-down"></i>
      </div>
    </div>

    <div
      v-if="drop"
      class="
        bg-white
        border border-solid border-borderInput
        rounded
        absolute
        mt-1
        inset-x-0
        p-1
        z-50
      "
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
      default: "sm", // sm, md, lg
    },
  },
  data() {
    return {
      drop: false,
    };
  },
  computed: {
    sizeInput() {
      switch (this.size) {
        case "sm":
          return "drop-sm";
          break;
        case "md":
          return "drop-md";
          break;
        case "lg":
          return "drop-lg";
          break;
        default:
          return "drop-md";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-sm {
  height: 32px;
  font-size: 16px;
}
.drop-md {
  height: 40px;
  font-size: 16px;
}
.drop-lg {
  height: 48px;
  font-size: 16px;
}
</style>