import Vue from "vue";


/**
 * Forms Inputs, Buttons, Dropdowns
 */
Vue.component(
  "dok-input",
  require("./views/global-components/form/input.vue").default
);

Vue.component(
  "dok-input-date",
  require("./views/global-components/form/input-date.vue").default
);

Vue.component(
  "dok-button",
  require("./views/global-components/form/button.vue").default
);

Vue.component(
  "dok-gp-button",
  require("./views/global-components/form/button-group.vue").default
);

Vue.component(
  "dok-dropdown",
  require("./views/global-components/form/dropdown.vue").default
);

Vue.component(
  "dok-select",
  require("./views/global-components/form/select.vue").default
);

Vue.component(
  "dok-textarea",
  require("./views/global-components/form/textarea.vue").default
);
/**
 * End Forms
 */

/*
 * Consultation Components
 */
Vue.component(
  "dok-share-consultation",
  require("./views/global-components/consultation/share.vue").default
);
/**
 * End Consultation Components
 */

// Alerts
Vue.component("empty", require("./views/global-components/empty.vue").default);

// Message For Beta Version
Vue.component("beta", require("./views/global-components/beta.vue").default);

// DropDown
Vue.component(
  "dropDown",
  require("./views/global-components/dropdown.vue").default
);

// Dropdown Algolia
Vue.component(
  "dropDownAlgolia",
  require("./views/global-components/dropdownAlgolia.vue").default
);

// Inputs
Vue.component(
  "dInput",
  require("./views/global-components/inputs.vue").default
);

// Aide Button
Vue.component("aide", require("./views/global-components/aide.vue").default);

// Alerts Dok
Vue.component(
  "alertsDok",
  require("./views/global-components/alertsDok.vue").default
);

// Dropdown Dok
Vue.component(
  "dok-downdrop",
  require("./views/global-components/dokDowndrop.vue").default
);

// Component for user information
Vue.component(
  "dok-information-patient",
  require("./views/global-components/pa-information.vue").default
);
