export default {
  homePage: {
    artwork: "/svg/Infographic_arabic.svg",
    msg_header_patient_side: "لنبني معاً مستقبل صحتنا",
    app_download_patient: "تحميل التطبيق الخاص بالمرضي",
    patient: "المريض",
    praticiens_sante: "هل أنت مهني في مجال الصحة؟",
    one_free_month: "مجاني لمدة شهر",
    sans_engagement: "دون التزام",
    start_at_dok: "انضم إلى dok pro",
    pour_les_patient: "من أجل المرضى",
    etes_vous_un_professionnel: "هل أنت مهني في مجال الصحة ؟",
    beneficiez_avantages: "استفد من مميزاتنا",
    service_free: "خدمات مجانية :",
    free_days: "مجاني لمدة 30 يوم:",
    functionsOne: "جدول زمني شخصي متصل",
    functionsTwo: "تدبيرالمواعيد على الإنترنت",
    functionsThree: "نشر الموجز المهني و التزويد بالمراجع الإلكترونية",
    functionsFor: "إدارة سجلات المرضى",
    functionss: "نظام الرسائل الإلكتروني المؤمن",
    functionsss: "ملف طبي مشترك",
    functionssss: "متابعة طبية مستمرة",
    functionsssss: "الاستشارة الطبية عن بعد",
    functionssssss: "وصفات و شهادات طبية رقمية",
    show_more: "المزيد من التفاصيل...",
    essayez_pro_free: "جرب dok pro مجاناً",
    one_free_month: "مجاني لمدة شهر",
    abonnement_moment: "إلغاء الانخراط في أي وقت",
    dok_vous_offre: "يقدم لك dok العديد من الميزات التي تتناسب مع احتياجاتك",
    pack: {
      one: {
        title: "الملف الطبي المشترك",
        content:
          "تجنب إضاعة الوثائق الطبية! لاتنسى أخد الدواء بعد الآن! خزن و شارك مستنداتك بكل أمان"
      },
      two: {
        title: "منظم الدواء الرقمي",
        content:
          "لاتنسى أخد الدواء بعد الآن! قم ببرمجة و إدارة التذكيرات الدوائية وفقا لإحتياجاتك "
      },
      three: {
        title: "تحديد المواعيد",
        content: "انتهى وقت الإنتظار! احجز موعداً عبر الإنترنت و وفر وقتك"
      },
      foor: {
        title: "الاستشارة عن بعد",
        content: "لا داعي إلى التنقل! استفد من الاستشارة الطبية عن بعد بالفديو"
      }
    }
  },
  phone_pro: "Téléphone Mobile Pro",
  phone_fix: "Téléphone Fixe",
  contact: "Contact",
  no_result: "Il n'ya aucun résultat",
  forget_password: "Mot de passe oublié",
  se_connecter: "Se connecter",
  remember_me: "Se souvenir de moi",
  idont_have_account: "Pas encore inscrit",
  create_account: "Créez un compte",
  copyright: "© 2020 Dok.ma Tous les droits sont réservés",
  create_account_dok: "S'inscrire gratuitement à dok Pro",
  INPE: "Code INPE",
  INPE_description: ` L'INPE nous facilitera la vérification de votre identité et
  simplifiera la saisie de vos <br />données dans le formulaire
  ci-dessous`,
  info_perso: "Informations Personnelles",
  homme: "Homme",
  femme: "Femme",
  lastName: "Votre Nom",
  firstName: "Votre Prénom",
  nameComercial: "Nom commercial",
  vente: "vente",
  et_de: "et de",
  utilisation: "utilisation",
  condition_partone: "J'accepte les conditions d'",
  condition_parttwo:
    ", la politique de confidentialité, et les informations relatives aux cookies.",
  certification:
    "Je certifie sur l'honneur l'exactitude des renseignements fournis.",
  create_account: "Créer Votre Compte",
  patient: {
    communaute: "المجتمع",
    pour_les_praticiens: "Pour les praticiens",
    pour_les_patient: "Pour les patients",
    patients: "Patients",
    patient: "Patient",
    praticiens: "Praticiens",
    praticien: "Praticien",
    address: "Adresse",
    tarifs: "Tarifs",
    diplomes: "Diplomes",
    spoken_languages: "Spoken Languages",
    open_hours: "Horaires d’overtures",
    parcours_professionnel: "Parcours Professionnel",
    lang_talk: "Langues parlées",
    actes: "Actes médicaux",
    sans_engagement: "Sans engagement",
    start_at_dok: "Rejoignez dok pro",
    date: "Date",
    heure: "Heure",
    signin: "login",
    signup: "register",
    app_download: "تحميل تطبيق",
    search: "Rechercher",
    nom: "Nom",
    specialite: "Specialité",
    specialites: "Spécialité(s)",
    phone: "Téléphone",
    years: "année(s)",
    experience: "Expérience",
    ville: "Ville",
    postal: "Code Postal",
    dok_pro: "dok Pro",
    aide: "Aide",
    assistance: "Assistance",
    etes_vous_un_professionnel: "Êtes-vous un professionnel de la santé",
    beneficiez_avantages: "Bénéficiez de nos avantages",
    essayez_pro_free: "Essayez dok pro gratuitement",
    dok_vous_offre:
      "dok vous offre plusieurs fonctionnalités adaptées à vos besoins",
    download_app_description:
      "قم بإدارة ملفك الطبي وحدد مواعداً مع طبيبك من هاتفك الذكي علي مدار الساعة طوال أيام اﻷسبوع",
    obtenir_un_lien: "احصل على رابط التحميل",
    sent_sms: "استقبال الرسائل القصيرة",
    googlePlay: {
      one: "متاح لنظام Android في متجر Play Store",
      two: "Supports Android"
    },
    appStore: {
      one: "يتوفر قريبًا لنظام iOS على متجر التطبيقات",
      two: "Supports iOS"
    },
    trafic_dok: "أسعار dok pro",
    simulation: "محاكاة",
    politique_confidentiale: "سياسة الخصوصية",
    faq: "التعليمات",
    support: "دعم",
    about_dok: "في ما يخص dok.ma",
    praticiens_sante: "Vous êtes professionnel de santé ?",
    one_free_month: "1 mois d'essai gratuit",
    abonnement_moment: "Résiliez votre abonnement à tout moment",
    msg_header_patient_side: "Construire ensemble la santé de demain",
    back: "Retour",
    change_appointment: "Changer le rendez-vous",
    take_appointment_success: "Demande de rendez-vous envoyée avec succès",
    take_appointment: "Prendre rendez-vous",
    patient_recever_sms_appointment:
      "Vous recevrez un SMS dès que que votre demande sera traitée par le praticien",
    status: "Statut",
    pending_confirmation: "en attente d confirmation",
    motif: "Motif",
    select_motif: "Choisir le motif",
    choices_motif: "Sélectionnez un motif",
    aucune_disponibilité: "Aucune disponibilité",
    matin: "Matin",
    disponibilites: "disponibilités",
    pas_de_creneau: "Pas de créneau disponible",
    apres_midi: "Après-midi",
    reserver: "Réserver",
    back_home: "Retour à la page d'accueil de dok.ma",
    home: "Accueil",
    d_experience: "d'éxperience",
    success: "Succès",
    text_document_sent_success: `
        Vos documents ont été envoyés avec succès! L'équipe Dok vous
        remercie pour votre patience. Votre demande est en cours de
        traitement, nous vous enverrons un mail de confirmation dans les
        plus brefs délais.
    `,
    text_document_sent: `
        Pour des raisons de sécurité et pour éviter toute usurpation
        d'identité, il est nécessaire de fournir les documents<br />
        demandés ci-dessous. La vérification ne dépassera pas 12 heures
        ouvrées, merci pour votre patience.
    `,
    title_active_account: "Activation de votre compte dok Pro",
    access_ext_files: " Les fichiers doivent être en png, jpeg, pdf",
    upload_cin_title: "Téléchargez votre CIN",
    drag_document_here: "Glissez vos documents ici",
    upload_CPEM: "Téléchargez votre CPEM",
    document_delete_auto: `
          Ces documents seront automatiquement supprimés de notre base de donnée
          dès que votre compte est activé.`,
    cart_national: "Carte Nationale d'Identité",
    cart_cpem: "Carte CPEM",
    sent_documents: "Envoyer les documents",
    sent_in_whatsapp: `Si vous n'arrivez pas à charger les documents, vous pouvez nous
    les envoyer <br />
    via WhatsApp sur le numéro`,
    certified_doctors: "Médecins certifiés",
    reservez_une_consultation:
      "Réservez une consultation physique ou vidéo chez un professionnel de santé à proximité",
    placeholder_input_card_search_doctor: "Nom, établissement, spécialité...",
    doctor: "Doctor",
    appointment: "Rendez-vou",
    free: "Gratuit",
    tarif_description: `
    Les honoraires sont affichés par le prestataire des soins
    médicaux à titre informatifs. Ils peuvent varier en fonction des
    traitements, procédés, et du nombre de consultations réalisées.`
  },
  signin: {
    se_connecter: "Se connecter",
    accedez_a_votre_compte: "Accédez à votre compte professionnel",
    email: "Email",
    password: "Mot de passe"
  },
  validation_password: {
    moins_chart: "Veuillez saisir au moins 8 caractères.",
    chiffres: "Veuillez saisir quelques chiffres",
    minuscules: "Veuillez saisir des lettres minuscules",
    majuscules: "Veuillez saisir des lettres majuscules",
    caracteres_speciaux: "Veuillez saisir quelques caractères spéciaux"
  }
};
