import axios from "axios";
import { EventBus } from "@/event-bus";

export default {
  async GET_LIST({ commit }, payload) {
    let params = {};
    if (payload && payload.search) {
      params = payload.search;
    }
    if (payload && payload.client) {
      params = { client: payload.client };
    }
    if (payload.date) {
      payload = { ...payload, ...payload.date };
    }
    delete payload.date;
    params = { ...payload, ...params };
    commit("SET_LOADING_AGENDA_STATUS", true);
    const { data } = await axios.get("folder", {
      params
    });

    if (data.ok) {
      await commit("SET_LIST", data);
      commit("SET_LOADING_AGENDA_STATUS", false);
    }

    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },
  async CHECK_FOLDER({ commit }, payload) {
    const { data } = await axios.get(`folder/${payload}`);
    if (data.ok) {
      commit("SET", data.data);
    }
  },
  async DELETE({ commit }, payload) {
    const { data } = await axios.delete(`folder/${payload.id}`);
    if (data.ok) {
      commit("REMOVE_RECORD", payload.index);
    }
    return data;
  },
  async CREATE_NEW({ commit }, payload) {
    const { data } = await axios.post("folder", payload);
    if (data.ok) {
      commit("ADD_NEW", data.data);
    }
    return data;
  },
  async UPDATE({ commit }, payload) {
    const { data } = await axios.put(`folder/${payload._id}`, payload);
    if (data.ok) {
      commit("EDIT", {
        folder: data.data,
        index: payload.index
      });
    }
    return data;
  },
  async SET({ commit }, payload) {
    commit("SET", payload);
  },
  async UPLOAD_FILES({ commit }, payload) {
    let formData = new FormData();
    formData.append("parent", payload.parent);
    for (let index = 0; index < payload.data.length; index++) {
      const file = payload.data[index];
      formData.append("files[]", file);
    }
    const { data } = await axios.post(`upload/files/${payload.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  async CREATE_DIRECTORY({ commit }, payload) {
    const { data } = await axios.post(`create/directory`, payload.data);
    return data;
  },

  async RESERVED_SLOT({ commit }, payload) {
    const { data } = await axios.post("folder/slots/reserve", payload);
    if (data.ok) {
      commit;//("ADD_NEW_", data.data);
    }
    return data;
  }
};
