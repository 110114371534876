/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/factures/pixinvent
==========================================================================================*/
import axios from "axios";
export default {
  async GET_GLOBAL({ commit }, payload) {
    const { data } = await axios.get("analytics");
    payload.onData(data);
    commit("SET_LIST", data.data);
  },
  async GET_HOTEL({ commit }, payload) {
    const { data } = await axios.get("hotel");
    payload.onData(data);
    commit("SET_LIST", data);
  }
};
