<template>
  <div class="w-full flex flex-col" :class="label && 'gap-y-1'">
    <label
      :for="`${label}-${generationRandom}`"
      class="font-EffraR text-base text-gray-800"
      >{{ label }}</label
    >
    <div class="relative">
      <textarea
        :class="
          [
            'bg-white border-2 border-solid rounded px-1 py-1 w-full font-EffraR text-black bg-transparent ',
            !reSize && 'resize-none ',
            sizeInput,
            hasLeftIcon && 'pl-1O ',
            customClass && customClass.includes('border')
              ? customClass
              : 'border-borderInput'
          ].join(' ')
        "
        v-model="valueInput"
        :placeholder="dPlaceholder"
      >
      </textarea>
      <span
        class="absolute right-0 top-0 h-full flex items-center px-2"
        v-if="hasRightIcon"
      >
        <slot name="rightIcon"></slot>
      </span>
      <span
        class="absolute left-0 top-0 h-full flex items-center px-2"
        v-if="hasLeftIcon"
      >
        <slot name="leftIcon"></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      generationRandom: Math.random()
    };
  },
  props: {
    dPlaceholder: {
      type: String,
      required: false,
      default: ""
    },
    dModel: {
      type: String | Number | Date,
      required: false
    },
    reSize: {
      type: Boolean,
      required: false,
      default: true
    },
    customClass: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: "sm" // sm, md, lg
    }
  },
  computed: {
    valueInput: {
      get() {
        return this.dModel;
      },
      set(value) {
        this.$emit("update:dModel", value);
      }
    },
    hasLeftIcon() {
      return this.$slots.leftIcon;
    },
    hasRightIcon() {
      return this.$slots.rightIcon;
    },
    sizeInput() {
      switch (this.size) {
        case "sm":
          return "textarea-sm";
          break;
        case "md":
          return "textarea-md";
          break;
        case "lg":
          return "textarea-lg";
          break;
        default:
          return "textarea-md";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.textarea-sm {
  height: 52px;
  font-size: 16px;
}
.textarea-md {
  height: 100px;
  font-size: 16px;
}
.textarea-lg {
  height: 143px;
  font-size: 16px;
}
</style>
