import axios from "axios";

export default {
  /// Global Notifications
  async GET({ commit }, payload) {
    const { data } = await axios.get("notifications", { params: payload });
    return data;
  },
  async READ_ALL_NOTIFICATION({ commit }, payload) {
    const { data } = await axios.put(`notifications/read/all`);
    payload.onData(data);
  },

  /// Appointments Notifications

  async GET_NOTIFICATION_ALLOW_SHARE({ commit }, payload) {
    const { data } = await axios.get("consultations/notification/patient");
    payload.onData(data);
  },
  async CHANGE_STATUS_NOTIFICATION({ commit }, payload) {
    const { data } = await axios.put(
      `consultations/notification/${payload._id}/status`,
      {},
      {
        params: {
          id: payload.id,
          status: payload.action
        }
      }
    );
    payload.onData(data);
  },
  async READ_ALL_NOTIFICATION_APPOINTMENTS({ commit }, payload) {
    const { data } = await axios.put(`/consultations/notification/read/all`);
    payload.onData(data);
  }
};
