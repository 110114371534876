/*=========================================================================================
  File Name: moduleChatActions.js
  Description: Chat Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "axios";
export default {
  async GET_CONVERSATIONS({ commit }, payload) {
    const { data } = await axios.get("chat/conversations");
    payload.onData(data.data);
  },
  async GET_CONVERSATION({ commit }, payload) {
    const { data } = await axios.get("chat/conversations/" + payload._id);
    payload.onData(data.data);
  },
  async SEND_MESSAGE({ commit }, payload) {
    const { data } = await axios.post("chat/conversations/send", payload.data);
    payload.onData(data.data);
  },
  async INIT_VIDEO({ commit }, payload) {
    const { data } = await axios.get(`chat/${payload.id}/init-video`);
    payload.onData(data.data);
  },
  updateAboutChat({ commit, rootState }, value) {
    commit("UPDATE_ABOUT_CHAT", { rootState: rootState, value: value });
  },
  updateStatusChat({ commit, rootState }, value) {
    commit("UPDATE_STATUS_CHAT", { rootState: rootState, value: value });
  },
  setChatSearchQuery({ commit }, query) {
    commit("SET_CHAT_SEARCH_QUERY", query);
  },
  sendChatMessage({ getters, commit }, payload) {
    payload.chatData = getters.chatDataOfUser(payload.id);
    commit("SEND_CHAT_MESSAGE", payload);
  },
  toggleIsPinned({ commit }, payload) {
    commit("TOGGLE_IS_PINNED", payload);
  },
  markSeenAllMessages({ getters, commit }, id) {
    let payload = { id: id };
    payload.chatData = getters.chatDataOfUser(payload.id);
    commit("MARK_SEEN_ALL_MESSAGES", payload);
  },
  async SEND_VIDEO_CALL_STATUS(
    { commit },
    { consultationId, conversationId, status }
  ) {
    const { data } = await axios.get(
      `/chat/call-callback/${consultationId}/${conversationId}/${status}`
    );
  }
};
