/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/factures/pixinvent
==========================================================================================*/
import axios from "axios";
export default {
  async GET({ commit }) {
    const { data } = await axios.get("settings");
    commit("SET_SETTINGS", data);
  },
  async EDIT({ commit }, payload) {
    const { data } = await axios.post("settings", payload);
    if (data.ok) {
      commit("REFRESH", data.data);
    }
    return data;
  }
};
