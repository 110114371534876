import EventEmitter from "events";

class AuthService extends EventEmitter {
  logOut() {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("hasuraToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("settings");
  }
  isAuthenticated() {
    return (
      localStorage.getItem("accessToken") && localStorage.getItem("userInfo")
    );
  }
  getToken() {
    return localStorage.getItem("accessToken");
  }
  setUserInfo(user) {
    localStorage.setItem("userInfo", JSON.stringify(user));
  }
  getUserInfo() {
    if (!this.isAuthenticated()) return null;
    return JSON.parse(localStorage.getItem("userInfo"));
  }
  getUserRoles() {
    return this.getUserInfo().roles || [];
  }
  getUserRole() {
    return this.getUserInfo().role;
  }
  getUserId() {
    if (!this.isAuthenticated()) return null;
    return this.getUserInfo()._id;
  }
  getFullName() {
    if (!this.isAuthenticated()) return null;
    const { firstName, lastName } = this.getUserInfo();
    return `${firstName} ${lastName}`;
  }
  getAvatar() {
    if (!this.isAuthenticated()) {
      return "/no-avatar.png";
    }

    const { image } = this.getUserInfo();
    if (image) return `https://api.dok.ma/uploads/${image}`;

    return "/no-avatar.png";
  }
  isSameUser(id) {
    return id === this.getUserInfo()._id;
  }
  getFirebaseToken() {
    return localStorage.getItem("firebaseToken");
  }
  setFirebaseToken(token) {
    return localStorage.setItem("firebaseToken", token);
  }
  isNotSubscribed() {
    return false;
    // const user = this.getUserInfo();
    // return !user.payment || !user.payment.done;
  }
}
export default new AuthService();
