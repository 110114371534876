import axios from "axios";
export default {
  async GET_LIST({ commit }, payload) {
    const { data } = await axios.get("task", {
      params: payload ? { folder: payload } : {}
    });
    if (data.ok) {
      commit("SET_LIST", data);
    }
  },
  async DELETE({ commit }, payload) {
    const { data } = await axios.delete(`task/${payload.id}`);
    if (data.ok) {
      commit("REMOVE_RECORD", payload.index);
    }
    return data;
  },
  async CREATE_NEW({ commit }, payload) {
    const { data } = await axios.post("task", payload);
    if (data.ok) {
      commit("ADD_NEW", data.data);
    }
    return data;
  },
  async UPDATE({ commit }, payload) {
    const { data } = await axios.put(`task/${payload.id}`, payload);
    if (data.ok) {
      commit("UPDATE", {
        task: data.data,
        index: payload.index
      });
    }
    return data;
  }
};
