/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/factures/pixinvent
==========================================================================================*/
import axios from "axios";
export default {
  async GET_SUBSCRIPTION_PRODUCTS({ commit}, payload ) {
    const { data } = await axios.get("payments/subscriptions/products");
    payload.onData(data);

    commit("SET_SUBSCRIPTION_PRODUCTS", data);
  },
  async CREATE_CUSTOMER_FOR_SUBSCRIPTION({}, payload) {
    const { data } = await axios.post(`payments/subscriptions/create-customer`);
    payload.onData(data);
  },
  async GET_SUBSCRIPTION_HISTORY({ commit}, payload ) {
    const { data } = await axios.get("payments/subscriptions/history");
    payload.onData(data);
  },

};
