/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  UPDATE_AUTHENTICATED_USER(state, data) {
    localStorage.setItem("userInfo", JSON.stringify(data.user));
    localStorage.setItem("userRole", data.user.role.toLowerCase());
    localStorage.setItem("accessToken", data.data.token);
    localStorage.setItem("hasuraToken", data.data.hasuraToken);
    localStorage.setItem("firebaseToken", data.data.firebaseToken);
  },
  SET_LOGGED_IN(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  }
};
