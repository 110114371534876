import state from "./State.js";
import mutations from "./Mutations.js";
import actions from "./Actions.js";
import getters from "./Getters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
