import Vue from "vue";
import store from "./store/store";
import { EventBus } from "@/event-bus";
import router from "./router";
import { Howl, Howler } from "howler";
import auth from "./auth/authService";

Vue.filter("pluralize", (word, length) =>
  length > 1 || length == 0 ? `${word}s` : word
);

const dokLangues = ["Arabe", "Français", "Anglais", "Espagnol", "Berbère"];
Vue.prototype.$langs = dokLangues;

const makeSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();

  var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaaaeeeeiiiioooouuuunc------";

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");

  return str;
};
Vue.prototype.$slug = makeSlug;

const family_situation = (type) => {
  if (type === "MARRIED") return "Marié(e)";
  if (type === "SINGLE") return "Célibataire";
  if (type === "DIVORCED") return "Divorcé(e)";
  if (type === "WIDOWER") return "veuf(ve)";
};
Vue.prototype.$FaSituation = family_situation;

const repasFrancais = (array) => {
  let arrayFrancais = [];
  for (let index = 0; index < array.length; index++) {
    if (array[index] === "MORNING") arrayFrancais[index] = "Matin";
    if (array[index] === "AFTERNOON") arrayFrancais[index] = "Midi";
    if (array[index] === "EVENING") arrayFrancais[index] = "Soir";
  }
  arrayFrancais = arrayFrancais.sort();
  return arrayFrancais.join(", ");
};
Vue.prototype.$repasFrancais = repasFrancais;

const mealFrancais = (met) => {
  let metFrancais = null;
  if (met === "AFTER") metFrancais = "Après repas";
  if (met === "BEFORE") metFrancais = "Avant repas";
  if (met === "BETWEEN") metFrancais = "Pendant repas";
  return metFrancais;
};
Vue.prototype.$mealFrancais = mealFrancais;

const statusConsultation = (status) => {
  let statu = null;
  // NEW STATUS
  if (status === "IN-PROGRESS") statu = "En consultation";
  if (status === "IN-WAITING-ROOM") statu = "Dans la salle d'attente";
  if (status === "JUSTIFIED-ABSENCE") statu = "Absence justifiée";
  if (status === "UNJUSTIFIED-ABSENCE") statu = "Absence non justifiée";
  if (status === "INDEFINITE") statu = "Indéfini";
  // OLD STATUS
  if (status === "PENDING") statu = "En attente"; // BLACK
  if (status === "CANCELED") statu = "Annulé"; // RED
  if (status === "ACCEPTED") statu = "Accepté"; // GREEN
  if (status === "DONE") statu = "Terminé";
  if (status === "REJECTED") statu = "Rejeté"; // GRAY
  if (status === "TRANSFERED") statu = "Reporté"; // YALLOW
  if (status === "OWNER-TRANSFERED") statu = "Vers un confrer";
  return statu ? statu : "--";
};
Vue.prototype.$statusConsultation = statusConsultation;

const iconStatus = (status) => {
  let statu = null;
  // NEW STATUS
  if (status === "UNJUSTIFIED-ABSENCE")
    statu = '<i class="fas fa-user-alt-slash"></i>';
  if (status === "JUSTIFIED-ABSENCE")
    statu = '<i class="fas fa-user-times"></i>';
  if (status === "IN-WAITING-ROOM") statu = '<i class="fas fa-user-check"></i>';
  if (status === "IN-PROGRESS") statu = '<i class="fas fa-user-md"></i>';
  if (status === "INDEFINITE") statu = '<i class="fas fa-exclamation"></i>';
  if (status === "DONE") statu = '<i class="fas fa-user-check"></i>';

  return statu;
};
Vue.prototype.$iconStatus = iconStatus;

const stateConsultation = (status) => {
  let statu = null;
  // NEW STATUS
  if (status === "IN-PROGRESS") statu = "En consultation";
  if (status === "IN-WAITING-ROOM") statu = "Dans la salle d'attente";
  if (status === "JUSTIFIED-ABSENCE") statu = "Absence justifiée";
  if (status === "UNJUSTIFIED-ABSENCE") statu = "Absence non justifiée";
  if (status === "INDEFINITE") statu = "Indéfini";
  if (status === "DONE") statu = "Terminé";

  return statu ? statu : "--";
};
Vue.prototype.$stateConsultation = stateConsultation;

const generationDoctorUrl = function (user, is = null) {
  this.$router.push(
    `/${is === "algolia"
      ? user.specialities[0].name.toLowerCase()
      : this.$slug(
        user.landingPage.specialities[0]
          ? user.landingPage.specialities[0].name.toLowerCase()
          : null
      )
    }/${is === "algolia"
      ? user.cityName
      : user.landingPage.address.city.name.toLowerCase()
    }/${is === "algolia" ? user.objectID : user._id}`
  );
};
Vue.prototype.$generationDoctorUrl = generationDoctorUrl;

const getInfoBullText = (input) => {
  const infoBulls = {
    inpe: "L'INPE nous facilitera la vérification de votre identité et simplifiera aux patients le remplissage des feuilles de soins.",
    cin: "Dans le but d'éviter toute usurpation d'identité, prière de saisir votre numéro de carte nationale.",
    mobile:
      "Pour une double sécurité, un numéro de portable est nécessaire. Cette information restera confidentielle et ne sera aucunement communiquée aux patients.",
    priceStandarConsultation:
      "Le prix que vous facturez pour une consultation de base au cabinet. Vous aurez par la suite la possiblité d'ajouter des prix par motif.",
    teleconsultation:
      "Les téléconsultations vous donneront la possibilité de travailler avec des patients partout au Maroc depuis votre cabinet.",
    priceTeleconsultation:
      "Vous pouvez faire des télécosultations gratuitement ou les facturer. La totalité des paiements sera virée sur votre compte chaque mois.",
    addMotif:
      "Configurez les motifs de consultation et ajoutez le tarif de chacun. (ex: Detartrage = 300 dirhams)",
    addHour:
      "Configurez vos horaires d'ouverture et mettez les motifs que vous souhaitez pour chaque jour de la semaine.",
    duplicate:
      "Le bouton dupliquer vous permet de copier/coller les horaires et les motifs sur toute la semaine. N'oubliez pas de supprimer les horaires d'ouverture pour les weekends et les jours fériés.",
    emailChange:
      "Pour une question de suivi et de sécurité, il est nécessaire de nous contacter pour modifier votre adresse mail",
  };

  return infoBulls[input] ? infoBulls[input] : "";
};
Vue.prototype.$getInfoBull = getInfoBullText;

const getDaysOfWeek = () => {
  return {
    1: {
      name: "Lundi",
      abr: "lun",
    },
    2: {
      name: "Mardi",
      abr: "mar",
    },
    3: {
      name: "Mercredi",
      abr: "mer",
    },
    4: {
      name: "Jeudi",
      abr: "jeu",
    },
    5: {
      name: "Vendredi",
      abr: "ven",
    },
    6: {
      name: "Samedi",
      abr: "sam",
    },
    7: {
      name: "Dimanche",
      abr: "dim",
    },
  };
};
Vue.prototype.$getDaysOfWeek = getDaysOfWeek;

const decodeMessage = (mesasge) => {
  const textBuffer = new Buffer.from(mesasge, "base64");
  return textBuffer;
};
Vue.prototype.$decodeMessage = decodeMessage;

const medicamentDosageUnits = {
  gr: {
    name: "gramme",
    unity: "gr",
  },
  iu: {
    name: "International unit",
    unity: "IU",
  },
  mug: {
    name: "Microgramme",
    unity: "µg",
  },
  mug_per_hour: {
    name: "Microgramme par heure",
    unity: "µg/h",
  },
  mug_per_ml: {
    name: "Microgramme par millilitre",
    unity: "µg/ml",
  },
  meq: {
    name: "Milliequivalents",
    unity: "mEq",
  },
  mg: {
    name: "milligramme",
    unity: "mg",
  },
  mg_per_cm2: {
    name: "milligramme par centimètre carré",
    unity: "mg/cm²",
  },
  mg_per_g: {
    name: "milligramme par gramme",
    unity: "mg/g",
  },
  mg_per_ml: {
    name: "milligramme par millilitre",
    unity: "mg/ml",
  },
  ml: {
    name: "millilitre",
    unity: "ml",
  },
  percent: {
    name: "percent",
    unity: "%",
  },
  application: {
    name: "application",
    unity: "application",
  },
  comprime: {
    name: "comprimé",
    unity: "comprimé",
  },
  dose: {
    name: "dose",
    unity: "dose",
  },
  gelule: {
    name: "gelule",
    unity: "gelule",
  },
  goutte: {
    name: "goutte",
    unity: "goutte",
  },
  injection: {
    name: "injection",
    unity: "injection",
  },
  pillule: {
    name: "pillule",
    unity: "pillule",
  },
  prise: {
    name: "prise",
    unity: "prise",
  },
  sachet: {
    name: "sachet",
    unity: "sachet",
  },
  sirop: {
    name: "sirop",
    unity: "sirop",
  },
  pulverisation: {
    name: "pulvérisation",
    unity: "pulvérisation",
  },
};
Vue.prototype.$medicamentDosageUnits = medicamentDosageUnits;

const timesPeriod = {
  e24h: "Toutes les 24h",
  matin: "Matin",
  midi: "Midi",
  soir: "Soir",
  e12h: "Toutes les 12h",
  matin_midi: "Matin et Midi",
  matin_soir: "Matin et Soir",
  midi_soir: "Midi et Soir",
  e8h: "Toutes les 8h",
  matin_midi_soir: "Matin, Midi et Soir",
  e6h: "Toutes les 6h",
  e4h50: "Presque toutes les 5h",
  e4: "Toutes les 4h",
  e3h25: "Presque toutes les 3h",
  e3h: "Toutes les 3h",
  e2h40: "Presque toutes les 3h",
  e2h25: "Presque toutes les 2h",
  e2h11: "Presque toutes les 2h",
  e2h: "Toutes les 2h",
};
Vue.prototype.$timesPeriod = timesPeriod;

const timesPeriodByNbrPrise = {
  1: {
    e24h: "Toutes les 24h",
    matin: "Matin",
    midi: "Midi",
    soir: "Soir",
  },
  2: {
    e12h: "Toutes les 12h",
    matin_midi: "Matin et Midi",
    matin_soir: "Matin et Soir",
    midi_soir: "Midi et Soir",
  },
  3: {
    e8h: "Toutes les 8h",
    matin_midi_soir: "Matin, Midi et Soir",
  },
  4: {
    e6h: "Toutes les 6h",
  },
  5: {
    e4h50: "Presque toutes les 5h",
  },
  6: {
    e4: "Toutes les 4h",
  },
  7: {
    e3h25: "Presque toutes les 3h",
  },
  8: {
    e3h: "Toutes les 3h",
  },
  9: {
    e2h40: "Presque toutes les 3h",
  },
  10: {
    e2h25: "Presque toutes les 2h",
  },
  11: {
    e2h11: "Presque toutes les 2h",
  },
  12: {
    e2h: "Toutes les 2h",
  },
};
Vue.prototype.$timesPeriodByNbrPrise = timesPeriodByNbrPrise;

function gcd(a, b) {
  return b ? gcd(b, a % b) : a;
}

let toFraction = function (_decimal) {
  var top = _decimal.toString().replace(/\d+[.]/, "");
  if (top == _decimal) return _decimal;

  var bottom = Math.pow(10, top.length);
  if (_decimal > 1) {
    top = +top + Math.floor(_decimal) * bottom;
  }
  var x = gcd(top, bottom);
  const sl = {
    top: top / x,
    bottom: bottom / x,
    display: top / x + "/" + bottom / x,
  };
  return sl.display;
};
Vue.prototype.$toFraction = toFraction;

let notificationTraduction = (name) => {
  if (name === "CONSULTATION") {
    return "Consultation";
  } else if (name === "HEALTH-BOOK") {
    return "Carnet de santé";
  }

  return "Unko";
};
Vue.prototype.$notificationTraduction = notificationTraduction;

const TAKE_APPOINTMENT = async () => {
  let localStorageParse = JSON.parse(
    localStorage.getItem("booking_appointment")
  );

  if (
    !localStorage.getItem("booking_appointment") ||
    localStorage.getItem("booking_appointment") === null
  ) {
    return router.go(-1);
  }

  if (!auth.getUserInfo().isMobileVerified) {
    return console.log("Please Active Phone Number First");
  }

  let response = await new Promise((resolve, reject) => {
    store.dispatch("doctor/SCHEDULE_APPOINTMENT", {
      data: {
        categoryId: localStorageParse.static.motif,
        start: localStorageParse.static.day,
        isVideoConsultation: true,
      },
      doctorId: localStorageParse.doctor._id,
      onData: ({ ok, message, data }) => {
        if (!ok) {
          EventBus.$emit("flashDok", { type: "errorr", message });
        }

        if (ok) {
          EventBus.$emit("flashDok", { type: "success", message });
        }

        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });

        if (!ok) {
          return reject({ ok: false });
        }
        return resolve({ ok: true, data });
      },
    });
  });

  return response;
};
Vue.prototype.$TAKE_APPOINTMENT = TAKE_APPOINTMENT;

const runSoundNotification = (type) => {
  let notifications = new Howl({
    src: [
      type === "upcomming" ? "/audios/phone.mp3" : "/audios/notification.mp3",
    ],
    html5: true,
    volume: 0.5,
    loop: false,
  });

  return notifications.play();
};
Vue.prototype.$runSoundNotification = runSoundNotification;

const downloadDocumentByID = (id) => {
  window.open(
    `https://api.dok.ma/api/v1/download/document/${id}?token=${auth.getToken()}`,
    "_blank"
  );
};
Vue.prototype.$downloadDocumentByID = downloadDocumentByID;

const openPrescription = (id, params = null) => {
  window.open(
    `https://api.dok.ma/api/v1/prescription/${id}/preview${params || '?'}token=${auth.getToken()}`,
    "_blank"
  );
};
Vue.prototype.$openPrescription = openPrescription;

let localStorageLang = localStorage.getItem("sessionLang");
const setLang = (event) => {
  if (!localStorageLang) {
    localStorage.setItem(
      "sessionLang",
      JSON.stringify({ default: event.target.value })
    );
    return window.location.reload();
  }

  localStorage.setItem(
    "sessionLang",
    JSON.stringify({ default: event.target.value })
  );
  return window.location.reload();
};
Vue.prototype.$setLang = setLang;
Vue.prototype.$defaultLang = localStorageLang
  ? JSON.parse(localStorageLang).default
  : "fr";

const goTo = (url) => {
  if (!url) return;
  window.location = url;
};
Vue.prototype.$goTo = goTo;

const RoleType = (type) => {
  // Enum : "PR", "DR", "PH"
  switch (type) {
    case "DR":
      return "Dr.";
      break;
    case "PR":
      return "Pr.";
      break;
    case "PH":
      return "Ph.";
      break;
    case "M":
      return "M.";
      break;
    case "Mme":
      return "Mme.";
      break;

    default:
      return "";
      break;
  }
};
Vue.prototype.$RoleType = RoleType;

const checkAvatar = (image) => {
  if (!image) return "/no-avatar.png";
  if (process.env.VUE_APP_CLOUDINARY_ACTIVE)
    return `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_thumb,g_faces,h_170,w_170/${image}`;

  return imageExists(`https://api.dok.ma/uploads/${image}`);
};
Vue.prototype.$checkAvatar = checkAvatar;

const checkIcons = (img, typeDefault) => {
  if (img) return `https://api.dok.ma/uploads/${img}`;
  let uploadImg = typeDefault ? typeDefault : "upload";
  return "/no-" + uploadImg + ".png";
};
Vue.prototype.$checkIcons = checkIcons;

const imageExists = (url) => {
  let http = new XMLHttpRequest();

  http.open("HEAD", url, false);
  http.send();

  return http.status != 404 ? url : "/no-avatar.png";
};
Vue.prototype.$imgExists = imageExists;

const formatNumber = function (
  number /*, decimals, dec_point, thousands_sep*/
) {
  var parts = number.toString().split(".");
  return (
    parts[0].replace(/\B(?=(\d{3})+(?=$))/g, " ") +
    (parts[1] ? "." + parts[1] : "")
  );
};
Vue.prototype.$formatNumber = formatNumber;

const TrackingActions = function (data) {
  store.dispatch("user/PERFORM_TRACKING_ADMIN", { ...data });
};
Vue.prototype.$performTracking = TrackingActions;
