/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_LIST(state, payload) {
    state.doctors = payload;
  },
  ADD_ITEM(state, payload) {
    state.doctors.push(payload);
  },
  EDIT_ITEM(state, payload) {
    state.doctors[payload.index] = payload.doctor;
  },
  DELETE(state, payload) {
    state.doctors = state.doctors.filter(client => client._id !== payload);
  },
  SET_SPECIALITIES_LIST(state, payload) {
    state.specialities = payload;
  },
};
