import Vue from "vue";
import App from "./App.vue";
import auth from "@/auth/authService";

// Theme Configurations
// import "../themeConfig.js";
// ACL
import acl from "./acl/acl";
// Styles: SCSS
import "./assets/scss/main.scss";
// Tailwind
import "@/assets/css/main.css";
// Vue Router
import router from "./router";
// Vuex Store
import store from "./store/store";
// Langues
import i18n from "./i18n/i18n";

require("./helpers.js");
require("./packgeUse.js");
require("./globalComponents.js");
require("./google-analytics.js");
require("./google-gtm.js");


import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

// New Imports
import { split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from "apollo-utilities";
import VueApollo from "vue-apollo";

const HASURA_URL = `https://graph-dashboard.dok.ma/v1/graphql`;
const token = localStorage.getItem("hasuraToken") || "";
const HASURA_AUTH_HEADER = {
  authorization: `Bearer ${token}`
};

const httpLink = new HttpLink({
  uri: HASURA_URL,
  headers: { ...HASURA_AUTH_HEADER }
});

const client = new SubscriptionClient(HASURA_URL.replace("https", "wss"), {
  reconnect: true,
  lazy: true,
  connectionParams: {
    headers: { ...HASURA_AUTH_HEADER }
  },
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: {
      headers: { ...HASURA_AUTH_HEADER }
    }
  }
});

const wsLink = new WebSocketLink(client);
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

// Create the apollo client
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: true
});
Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

// Global Varibels
let viewAgenda;
Vue.prototype.$viewAgenda = viewAgenda;


Vue.config.productionTip = false;

const app = new Vue({
  router,
  apolloProvider,
  store,
  i18n,
  acl,
  data: {
    loading: true,
  },
  render: h => h(App)
}).$mount("#app");

// Route Before
router.beforeEach((to, from, next) => {
  app.loading = true;
  const pageMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta);

  if (pageMeta.meta) {
    if (pageMeta.meta.title) document.title = pageMeta.meta.title;
    if (pageMeta.meta.description) {
      var meta = document.createElement("meta");
      meta.name = "description";
      meta.content = pageMeta.meta.description;
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
  }

  // ----------------------------------------------------  Check if Guest ----------------------------------------------------
  if (to.matched.some(record => record.meta.isGuest)) {
    if (!auth.isAuthenticated()) return next();
    return next({ path: "/" });
  }

  // ----------------------------------------------------  Check if Auth ----------------------------------------------------
  if (to.matched.some(record => record.meta.isAuth)) {
    if (auth.isAuthenticated()) return next();
    return next({ path: "/" });
  }

  next();
});

// Route After
router.afterEach(() => {
  setTimeout(() => {
    app.loading = false
  }, 2000);
});