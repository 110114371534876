import axios from "axios";
export default {
  async GET_TYPE({}, payload) {
    const { data } = await axios.get(`/support/ticket/type`);
    return data;
  },

  async GET_TICKET({}, payload) {
    const { data } = await axios.get(`/support/ticket/${payload.id}`);
    if (data.ok) return data;
  },

  async GET_LIST_TICKET({}, payload) {
    const { data } = await axios.get(
      `/support/ticket/doctor/${payload.doctorId}`,
      {
        params: {
          page: payload.page
        }
      }
    );
    if (data.ok) return data;
  },

  async STORE({}, payload) {
    const { data } = await axios.post(`/support/ticket`, payload.formData);
    if (data.ok) return data;
  },

  async STORE_ASSISTANT({}, payload) {
    const { data } = await axios.post(`/support/assistant`, payload.formData);
    return data;
  },

  async DELETE({}, payload) {
    const { ok, message } = await axios.delete(`/support/ticket/${payload.id}`);
    return { ok, message };
  },

  async STORE_MESSAGE({}, payload) {
    if (payload.formData.file) {
      let dataForm = new FormData();
      dataForm.append("file", payload.formData.file);
      const { data: data1 } = await axios.post(`files`, dataForm, {
        headers: { "Content-Type": "multipart/form-data" }
      });
      payload.formData.imageUrl = data1.fileName;
    }
    const { data } = await axios.post(
      `/support/ticket/message`,
      payload.formData
    );
    return data;
  },

  async STORE_MESSAGE_ASSISTANT({}, payload) {
    const { data } = await axios.post(
      `/support/assistant/message`,
      payload.formData
    );
    return data;
  },

  async GET_LIST_MESSAGE({}, payload) {
    const { data } = await axios.get(`/support/ticket/message/${payload.id}`);
    if (data.ok) return data;
  },

  async FEED_BACK({}, payload) {
    const { data } = await axios.put(
      `/support/ticket/feedback/${payload.id}/${payload.status}`
    );
    return data;
  },

  async DEADLINE({}) {
    const { ok } = await axios.get("/support/ticket/deadline");
    return ok;
  }
};
