import axios from "axios";
export default {
  async GET_LIST({
    commit
  }, payload) {
    const {
      data
    } = await axios.get("groupe", {
      params: payload ? payload.search : {}
    });
    if (data.ok) {
      commit("SET_LIST", data);
    }
  },
  async DELETE({
    commit
  }, payload) {
    const {
      data
    } = await axios.delete(`groupe/${payload.id}`);
    if (data.ok) {
      commit("REMOVE_RECORD", payload.index);
    }
    return data;
  },
  async CREATE_NEW({
    commit
  }, payload) {
    const {
      data
    } = await axios.post("groupe", payload);
    if (data.ok) {
      commit("ADD_NEW", data.data);
    }
    return data;
  },
  async UPDATE({
    commit
  }, payload) {
    const {
      data
    } = await axios.put(`groupe/${payload.id}`, payload);
    if (data.ok) {
      commit("UPDATE", {
        groupe: data.data,
        index: payload.index
      });
    }
    return data;
  }
};
