import axios from "axios";

export default {
  async PERFORM_TRACKING_ADMIN({ }, payload) {
    await axios.post("/admin/logs", payload.data);
  },

  ////////////////////////////    PATIENT NEW CODE LOGIN & REGISTER    //////////////////////////////////////
  async PATIENT_REGISTER({ }, payload) { },

  async PATIENT_LOG_IN({ }, payload) {
    const { data } = await axios.post("patient/auth", payload);
    return data;
  },

  async PATIENT_SENT_MOBILE_ACTIVATION({ }, payload) {
    const { data } = await axios.post(
      "patient/resend-mobile-activation",
      payload
    );
    return data;
  },

  async PATIENT_VERIFIED_MOBILE_ACTIVATION({ }, payload) {
    const { data } = await axios.post("patient/verify-mobile", payload);
    return data;
  },

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  async GET_BY_ID({ commit }, payload) {
    const { data } = await axios.get(`users/${payload.id}`);
    payload.onData(data);
  },

  async GET_TWILIO_TOKEN({ commit }, onData) {
    const { data } = await axios.get("chat/generate-twilio-token");
    onData(data);
  },

  async GET_LIST({ commit }, payload) {
    //console.log(payload);
    const { data } = await axios.get("users", {
      params: { ...payload },
    });
    commit("SET_LIST", { data: data.data, more: payload.more });
    return data;
  },

  async CREATE_USER({ commit }, payload) {
    const { data } = await axios.post("users", payload);
    if (data.ok) commit("ADD_ITEM", data.data);
    return data;
  },

  REGISTER({ commit }, payload) {
    return axios.post("users/register", payload);
  },

  async UPDATE_USER({ commit }, payload) {
    const { data } = await axios.put(`users/${payload._id}`, payload);
    if (data.ok) commit("EDIT_ITEM", { index: payload.index, user: data.data });
    return data;
  },

  async DELETE_USER({ commit }, payload) {
    const { data } = await axios.delete(`users/${payload.userId}`);
    return data;
  },

  async UPDATE_PROFILE({ commit, dispatch }, payload) {
    const { data } = await axios.put(`users/me`, payload.data);
    dispatch("GET_PROFILE");
    payload.onData(data);
  },

  async UPDATE_AVATAR({ commit }, payload) {
    let dataForm = new FormData();
    dataForm.append("file", payload.file);
    const { data } = await axios.post(`files/user-avatar`, dataForm, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return data;
  },

  async GET_AVATAR_IMAGE({ commit }, payload) {
    return `https://res.cloudinary.com/dokma/image/upload/c_thumb,g_faces,h_150,w_150/${payload.image}`;
    //  const { data } = await axios.get("files/images/"+payload.image, {
    //   params:{
    //     width:payload.width,
    //     height:payload.height,
    //   }
    // });
    //  return data;
  },

  async GET_PROFILE({ commit }, payload) {
    const { data } = await axios.get(`users/me`);
    if (data.ok) localStorage.setItem("userInfo", JSON.stringify(data.data));
    if (payload && payload.onData) payload.onData(data);
  },

  async DELETE({ commit }, payload) {
    const { data } = await axios.delete(`users/${payload}`);
    if (data.ok) commit("DELETE", payload);
    return data;
  },

  async GET_APPOINTMENTS({ commit }, payload) {
    const { data } = await axios.get("users/appointments", {
      params: { ...payload.params },
    });

    payload.onData(data);
  },

  async GET_USERS_FILES({ commit }, payload) {
    const { data } = await axios.get("users/files", {
      params: { ...payload.params },
    });
    payload.onData(data);
  },

  async GET_USERS_DOCTORS({ commit }, payload) {
    const { data } = await axios.get("users/doctors", {
      params: { ...payload.params },
    });
    payload.onData(data);
  },

  async GET_ALL_DOCTORS({ commit }, payload) {
    const { data } = await axios.get("users/all/doctors", {
      params: { ...payload.params },
    });
    payload.onData(data);
  },

  async GET_USER_RESTPASSWOR({ commit }, payload) {
    const { data } = await axios.post("password-reset", payload);
    payload.onData(data);
  },

  async CHECK_RESET_PASSWORD({ commit }, payload) {
    const { data } = await axios.get("check/reset/password", {
      params: { ...payload },
    });
    payload.onData(data);
  },

  async CHAGNE_PASSWORD_TOKEN({ commit }, payload) {
    const { data } = await axios.post("password-change", payload);
    payload.onData(data);
  },

  async ACTIVATION_ACCOUNT({ commit }, payload) {
    const { data } = await axios.post("activation/account", payload);
    return data;
  },

  async SENT_2FA_ACCOUNT({ commit }, payload) {
    const { data } = await axios.post("auth/2fa-request");
    payload.onData(data);
  },

  async SENT_2FA_ACCOUNT_NOT_LOGIN({ commit }, payload) {
    const { data } = await axios.post("auth/2fa-request/new", payload);
    payload.onData(data);
    return data;
  },

  async ACTIVE_2FA_ACCOUNT({ commit }, payload) {
    const { data } = await axios.post(
      `auth/2fa-validation?token=${payload.token}`
    );
    payload.onData(data);
  },

  async SEND_ACTIVIATION_CODE({ }, payload) {
    const { data } = await axios.post(`users/activation/agine`, payload);
    payload.onData(data);
  },

  async SENT_SMS_DOWNLOAD_APP({ }, payload) {
    const { data } = await axios.post("global/send-app-link", payload, {
      params: payload.pro ? { pro: true } : {},
    });
    payload.onData(data);
  },

  async RESEND_EMAIL_ACTIVATION_CODE({ }, payload) {
    const { data } = await axios.post(
      `patient/resend-email-activation`,
      payload
    );
    payload.onData(data);
  },

  async GET_PATIENT_BY_ID({ commit }, payload) {
    const { data } = await axios.get(`patients/${payload.id}`);
    payload.onData(data);
  },

  async GET_PATIENT_NOTIFICATIONS({ commit }, payload) {
    const { data } = await axios.get("users/notifications");
    payload.onData(data);
  },

  async HEALTH_CHANGE_STATUS_PATIENTS({ commit }, payload) {
    const { data } = await axios.get(
      `server-gateways/notifications/${payload.id}/${payload.status}`
    );
    payload.onData(data);
  },

  async GET_FIREBASE_TOKEN({ commit }, payload) {
    const { data } = await axios.get("users/firebase-custom-token");
    payload.onData(data);
  },

  async VERIFED_PATIENT_ACCOUNT({ commit }, payload) {
    const { data } = await axios.get(
      `doctors/verify-patient/${payload.patientId}`
    );
    payload.onData(data);
  },
  async LOGIN_WITH_EMAIL({ commit }, payload) {
    const { data } = await axios.post(`users/magic-link`, {
      email: payload.email,
    });
    payload.onData(data);
  },
  async SYNC_EMAILS({ commit }) {
    const { data } = await axios.get(`users/sync-emails`);
    console.log({ data });
  }
};
