let getDefaultState = () => ({
  calendarLabels: [
    { text: "Urgent", value: "urgent", color: "danger" },
    { text: "Important", value: "important", color: "warning" },
    { text: "Normal", value: "normal", color: "primary" }
  ],
  calendarEvents: [],
  simpleCalendarEvents: [],
  settings: null,
  categories: [],
  reasons: [],
  slots: [],
  // New State
  pickerDate: new Date(),
  typeAgenda: "week",
  selectedSlot: "Mkach",
  eventsAgenda: []
});

export default getDefaultState;
