<template>
  <div
    class="bg-greyx rounded py-16 text-blackdok text-center px-5 text-base font-bold"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>