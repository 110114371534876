var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col",class:_vm.label && 'gap-y-1'},[_c('label',{staticClass:"font-EffraR text-base text-gray-800",attrs:{"for":(_vm.label + "-" + _vm.generationRandom)}},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"relative"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueInput),expression:"valueInput"}],class:[
          'bg-white border-2 border-solid rounded px-1 py-1 w-full font-EffraR text-black bg-transparent ',
          !_vm.reSize && 'resize-none ',
          _vm.sizeInput,
          _vm.hasLeftIcon && 'pl-1O ',
          _vm.customClass && _vm.customClass.includes('border')
            ? _vm.customClass
            : 'border-borderInput'
        ].join(' '),attrs:{"placeholder":_vm.dPlaceholder},domProps:{"value":(_vm.valueInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.valueInput=$event.target.value}}}),(_vm.hasRightIcon)?_c('span',{staticClass:"absolute right-0 top-0 h-full flex items-center px-2"},[_vm._t("rightIcon")],2):_vm._e(),(_vm.hasLeftIcon)?_c('span',{staticClass:"absolute left-0 top-0 h-full flex items-center px-2"},[_vm._t("leftIcon")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }