import axios from "axios";
export default {
  async DELETE({ commit }, payload) {
    const { data } = await axios.delete(`details/${payload.id}`);
    if (data.ok) {
      commit("REMOVE_RECORD", payload.index);
    }
  },
  async CREATE_NEW({ commit }, payload) {
    const { data } = await axios.post(`details/${payload.id}`, payload.data);
    if (data.ok) {
      commit("folder/SET", data.data, {
        root: true
      });
    }
  },
  async UPDATE({ commit }, payload) {
    const { data } = await axios.put(
      `details/${payload.id}/task/${payload.taskId}`,
      payload.data
    );
    if (data.ok) {
      commit("folder/SET", data.data, {
        root: true
      });
    }
  },
  async APPLY_TASK_FILTER({ commit }, payload) {
    commit("APPLY_TASK_FILTER", payload);
  },
  async SET_TASK_QUERY({ commit }, payload) {
    commit("SET_TASK_QUERY", payload);
  }
};
