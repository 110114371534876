export default {
  homePage: {
    artwork: "/svg/infographic.svg",
    pack: {
      one: {
        title: "Dossier médical partagé",
        content:
          "Pas de paperasse, pas de perte de documents ! Conservez et partagez tous vos certificats et ordonnances en toute sécurité",
      },
      two: {
        title: "Pilulier numérique",
        content:
          "Plus d'omission ! Programmez et gérez vos prises de médicaments selon vos besoins",
      },
      three: {
        title: "Prise de rendez-vous",
        content:
          "Terminée l'attente ! Prenez rendez-vous en ligne et économisez votre temps",
      },
      foor: {
        title: "Suivi par téléconsultation",
        content:
          "Finis les déplacements inutiles ! Optez pour la téléconsultation pour votre visite de contrôle et soyez télérassurés",
      },
    },

    app_download_patient: "Téléchargez l'App Patient",
    msg_header_patient_side: "Construire ensemble la santé de demain",
    app_download: "Téléchargez l'App",
    patient: "Patient",
    praticiens_sante: "Vous êtes professionnel de santé ?",
    one_free_month: "1 mois d'essai gratuit",
    sans_engagement: "Sans engagement",
    start_at_dok: "Rejoignez dok pro",
    pour_les_patient: "Pour les patients",
    dok_vous_offre:
      "dok vous offre plusieurs fonctionnalités adaptées à vos besoins",
    etes_vous_un_professionnel: "Êtes-vous un professionnel de santé ?",
    beneficiez_avantages: "Bénéficiez de nos avantages",
    service_free: "Services Gratuits :",
    free_days: "30 Jours d’essai :",
    functionsOne: "Agenda connecté personnalisable",
    functionsTwo: "Gestion des rendez-vous en ligne",
    functionsThree: "Publication et référencement",
    functionsFor: "Gestion de la patientèle",
    functionss: " Messagerie sécurisée",
    functionsss: "Accès au dossier médical partagé de vos patients",
    functionssss: "Suivi médical augmenté",
    functionsssss: "Suivi en téléconsultation",
    functionssssss: "Ordonnances et certificats numériques",
    show_more: "Afficher plus de détails...",
    essayez_pro_free: "Essayez dok pro gratuitement",
    one_free_month: "1 mois d'essai gratuit",
    abonnement_moment: "Résiliez votre abonnement à tout moment",
  },
  phone_pro: "Téléphone Mobile Pro",
  phone_fix: "Téléphone Fixe",
  contact: "Contact",
  no_result: "Il n'ya aucun résultat",
  forget_password: "Mot de passe oublié",
  se_connecter: "Se connecter",
  remember_me: "Se souvenir de moi",
  idont_have_account: "Pas encore inscrit",
  create_account: "Créez un compte",
  copyright: "© 2020 Dok.ma Tous les droits sont réservés",
  create_account_dok: "S'inscrire gratuitement à dok Pro",
  INPE: "Code INPE",
  INPE_description: ` L'INPE nous facilitera la vérification de votre identité et
  simplifiera la saisie de vos <br />données dans le formulaire
  ci-dessous`,
  info_perso: "Informations Personnelles",
  homme: "Homme",
  femme: "Femme",
  lastName: "Votre Nom",
  firstName: "Votre Prénom",
  nameComercial: "Nom commercial",
  vente: "vente",
  et_de: "et de",
  utilisation: "utilisation",
  condition_partone: "J'ai lu et j'accepte ",
  condition_parttwo:
    " notamment la mention relative à la protection des données personnelles.",
  certification:
    "Je certifie sur l'honneur l'exactitude des renseignements fournis.",
  create_account: "Créer Votre Compte",
  patient: {
    communaute: "Communauté",
    pour_les_praticiens: "Pour les praticiens",
    pour_les_patient: "Pour les patients",
    patients: "Patients",
    patient: "Patient",
    praticiens: "Praticiens",
    praticien: "Praticien",
    address: "Adresse",
    tarifs: "Tarifs",
    diplomes: "Diplomes",
    spoken_languages: "Spoken Languages",
    open_hours: "Horaires d’overtures",
    parcours_professionnel: "Parcours Professionnel",
    lang_talk: "Langues parlées",
    actes: "Actes médicaux",
    sans_engagement: "Sans engagement",
    start_at_dok: "Rejoignez dok pro",
    date: "Date",
    heure: "Heure",
    signin: "login",
    signup: "register",
    app_download: "Téléchargez l'App",
    search: "Rechercher",
    nom: "Nom",
    specialite: "Specialité",
    specialites: "Spécialité(s)",
    phone: "Téléphone",
    years: "année(s)",
    experience: "Expérience",
    ville: "Ville",
    postal: "Code Postal",
    dok_pro: "dok Pro",
    aide: "Aide",
    assistance: "Assistance",
    etes_vous_un_professionnel: "Êtes-vous un professionnel de la santé",
    beneficiez_avantages: "Bénéficiez de nos avantages",
    essayez_pro_free: "Essayez dok pro gratuitement",
    dok_vous_offre:
      "dok vous offre plusieurs fonctionnalités adaptées à vos besoins",
    download_app_description:
      "Gérez votre dossier médical et prenez rendez-vous chez votre praticien depuis votre smartphone 24/7",
    obtenir_un_lien: "Obtenir un lien de téléchargement",
    sent_sms: "Recevoir SMS",
    googlePlay: {
      one: "Disponible pour Android sur le Play Store",
      two: "Supports Android",
    },
    appStore: {
      one: "Disponible pour iOS sur l’App Store",
      two: "Supports iOS",
    },
    trafic_dok: "Tarification dok.ma pro",
    simulation: "Simulation de gains",
    politique_confidentiale: "Politique de confidentialité",
    faq: "FAQ",
    support: "Support",
    about_dok: "À propos de dok.ma",
    praticiens_sante: "Vous êtes professionnel de santé ?",
    one_free_month: "1 mois d'essai gratuit",
    abonnement_moment: "Résiliez votre abonnement à tout moment",
    msg_header_patient_side: "Construire ensemble la santé de demain",
    back: "Retour",
    change_appointment: "Changer le rendez-vous",
    take_appointment_success: "Demande de rendez-vous envoyée avec succès",
    take_appointment: "Prendre rendez-vous",
    patient_recever_sms_appointment:
      "Vous recevrez un SMS dès que que votre demande sera traitée par le praticien",
    status: "Statut",
    pending_confirmation: "en attente d confirmation",
    motif: "Motif",
    select_motif: "Choisir le motif",
    choices_motif: "Sélectionnez un motif",
    aucune_disponibilité: "Aucune disponibilité",
    matin: "Matin",
    disponibilites: "disponibilités",
    pas_de_creneau: "Pas de créneau disponible",
    apres_midi: "Après-midi",
    reserver: "Réserver",
    back_home: "Retour à la page d'accueil de dok.ma",
    home: "Accueil",
    d_experience: "d'éxperience",
    success: "Succès",
    text_document_sent_success: `
        Vos documents ont été envoyés avec succès! L'équipe Dok vous
        remercie pour votre patience. Votre demande est en cours de
        traitement, nous vous enverrons un mail de confirmation dans les
        plus brefs délais.
    `,
    text_document_sent: `
        Pour des raisons de sécurité et pour éviter toute usurpation
        d'identité, il est nécessaire de fournir les documents<br />
        demandés ci-dessous. La vérification ne dépassera pas 12 heures
        ouvrées, merci pour votre patience.
    `,
    title_active_account: "Activation de votre compte dok Pro",
    access_ext_files: " Les fichiers doivent être en png, jpeg, pdf",
    upload_cin_title: "Téléchargez votre CIN",
    drag_document_here: "Glissez vos documents ici",
    upload_CPEM: "Téléchargez votre CPEM",
    document_delete_auto: `
          Ces documents seront automatiquement supprimés de notre base de donnée
          dès que votre compte est activé.`,
    cart_national: "Carte Nationale d'Identité",
    cart_cpem: "Carte CPEM",
    sent_documents: "Envoyer les documents",
    sent_in_whatsapp: `Si vous n'arrivez pas à charger les documents, vous pouvez nous
    les envoyer <br />
    via WhatsApp sur le numéro`,
    certified_doctors: "Médecins certifiés",
    reservez_une_consultation:
      "Réservez une consultation physique ou vidéo chez un professionnel de santé à proximité",
    placeholder_input_card_search_doctor: "Nom, établissement, spécialité...",
    doctor: "Doctor",
    appointment: "Rendez-vou",
    free: "Gratuit",
    tarif_description: `
    Les honoraires sont affichés par le prestataire des soins
    médicaux à titre informatifs. Ils peuvent varier en fonction des
    traitements, procédés, et du nombre de consultations réalisées.`,
  },
  signin: {
    se_connecter: "Se connecter",
    accedez_a_votre_compte: "Accédez à votre compte professionnel",
    email: "Email",
    password: "Mot de passe",
  },
  validation_password: {
    moins_chart: "Veuillez saisir au moins 8 caractères.",
    chiffres: "Veuillez saisir quelques chiffres",
    minuscules: "Veuillez saisir des lettres minuscules",
    majuscules: "Veuillez saisir des lettres majuscules",
    caracteres_speciaux: "Veuillez saisir quelques caractères spéciaux",
  },
  se_connecter_with_email: "Se connecter avec l'email",
};
