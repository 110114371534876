<template>
  <div>
    <div @click="modalis = true" v-if="buttonSlot">
      <slot name="button"></slot>
    </div>
    <dok-button
      :custom-class="`flex items-center gap-x-2 text-left ${
        data && data.shareRequestStatus === 'ACCEPTED'
          ? 'text-green-800 hover:bg-dokBlue-lighter hover:text-dokBlue-ultra'
          : ''
      } ${
        data && data.shareRequestStatus === 'PENDING' ? 'text-yellow-800' : ''
      } ${
        data && ['NONE', 'REFUSED'].indexOf(data.shareRequestStatus) !== -1
          ? 'hover:bg-dokBlue-lighter hover:text-dokBlue-ultra'
          : ''
      } ${customClass}`"
      size="sm"
      bg="none"
      @click.native="modalis = true"
      v-if="!buttonSlot"
    >
      <svg
        width="18"
        height="18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.645 7.079a3.27 3.27 0 0 0-1.65-2.839c.3-.363.481-.829.481-1.336A2.101 2.101 0 0 0 14.377.805a2.101 2.101 0 0 0-2.099 2.099c0 .507.182.973.482 1.336a3.27 3.27 0 0 0-1.65 2.839V8.08c0 .329.266.596.596.596h5.343c.33 0 .596-.267.596-.596V7.079zm-3.268-5.082a.908.908 0 0 1 0 1.814.908.908 0 0 1 0-1.814zm-2.076 5.488v-.406c0-1.145.932-2.076 2.076-2.076 1.145 0 2.076.931 2.076 2.076v.406h-4.152zm-5.41.596V7.079A3.27 3.27 0 0 0 5.24 4.24c.3-.363.481-.829.481-1.336A2.101 2.101 0 0 0 3.623.805a2.101 2.101 0 0 0-2.099 2.099c0 .507.181.973.482 1.336a3.27 3.27 0 0 0-1.65 2.839V8.08c0 .329.266.596.595.596h5.344c.33 0 .596-.267.596-.596zM3.623 1.997a.908.908 0 0 1 0 1.814.908.908 0 0 1 0-1.814zM1.547 7.485v-.406c0-1.145.931-2.076 2.076-2.076s2.076.931 2.076 2.076v.406H1.547zM14.8 9.997a.596.596 0 0 0-.843 0l-2.004 2.004a.596.596 0 0 0 .843.843l.986-.987v3.31H4.22v-4.748a.596.596 0 1 0-1.192 0v5.343c0 .33.267.596.596.596h10.754c.33 0 .596-.267.596-.596v-3.905l.987.987a.596.596 0 0 0 .843-.843l-2.004-2.004z"
          fill="currentColor"
          stroke="currentColor"
          stroke-width=".19"
        />
      </svg>

      <template v-if="data && data.shareRequestStatus === 'ACCEPTED'">
        Partager
      </template>

      <template
        v-if="
          data && ['NONE', 'REFUSED'].indexOf(data.shareRequestStatus) !== -1
        "
      >
        Partager
      </template>

      <template v-if="data && data.shareRequestStatus === 'PENDING'">
        En attente
      </template>
    </dok-button>
    <!-------------------------------------------------------MODAL----------------------------------------------------------------------->
    <modal
      :isActive="modalis"
      :exitModal="() => {}"
      :width="473"
      :hidden-header="true"
      :has-no-scroll="true"
    >
      <!-- HEADER -->
      <div class="flex justify-between gap-x-2 px-3 py-5">
        <h1 class="font-EffraR text-2xl text-black">
          <span v-if="data && data.shareRequestStatus === 'ACCEPTED'">
            Partager la consultation</span
          >
        </h1>
        <svg
          class="cursor-pointer"
          @click="
            () => {
              modalis = false;
              search = '';
              share = { doctors: [] };
              listOfIDS = [];
              modalLoader = true;
            }
          "
          width="24"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6 6 18M6 6l12 12"
            stroke="#333638"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <!-- Have a Access -->
      <div
        v-if="data && data.shareRequestStatus === 'ACCEPTED'"
        class="px-5 flex flex-col gap-y-4"
      >
        <dok-input
          size="lg"
          d-placeholder="Rechercher..."
          :d-model.sync="search"
        >
          <template v-slot:rightIcon>
            <svg
              width="18"
              height="18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.25 14.25a6 6 0 1 0 0-12 6 6 0 0 0 0 12zM15.75 15.75l-3.263-3.262"
                stroke="#333638"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
        </dok-input>
        <template v-if="!modalLoader">
          <!-- List -->
          <div
            class="h-300 overflow-y-scroll scroll pr-2"
            v-if="share.doctors.length"
          >
            <div
              class="
                h-auto
                lg:h-16
                rounded
                flex flex-col
                lg:flex-row
                items-start
                lg:items-center
                justify-between
                px-4
                mb-4
                py-3
                lg:py-0
              "
              style="border: solid 1px #d7dde5"
              v-for="doctor in share.doctors"
              :key="doctor._id"
            >
              <div class="flex flex-row items-center gap-x-2">
                <input
                  type="checkbox"
                  class="h-5 w-5 border-2 border-solid border-labelGray"
                  :id="`${doctor.firstName} ${doctor.lastName}`"
                  @change="
                    choiceDoctor({
                      id: doctor._id,
                      fname: `${doctor.firstName} ${doctor.lastName}`,
                    })
                  "
                  :checked="
                    data &&
                    (lodash().find(data.sharedWith, { _id: doctor._id }) ||
                      data.sharedWith.indexOf(doctor._id) !== -1)
                      ? 'checked'
                      : ''
                  "
                />

                <label
                  class="font-EffraR text-base text-black"
                  :for="`${doctor.firstName} ${doctor.lastName}`"
                  >{{ `${doctor.firstName} ${doctor.lastName}` }}</label
                >
              </div>

              <span
                v-if="
                  doctor.landingPage &&
                  doctor.landingPage.specialities[0] &&
                  doctor.landingPage.specialities[0]._id
                "
                class="
                  bg-dokBlue-lighter
                  text-dokBlue-ultra
                  font-EffraR
                  text-sm
                  p-1
                  rounded
                "
              >
                {{ doctor.landingPage.specialities[0].name }}
              </span>
            </div>
          </div>
          <!-- Actions -->
          <dok-button
            size="md"
            custom-class="w-full rounded-none"
            @click.native="onShare"
            v-if="share.doctors.length"
          >
            Partager ({{ data.sharedWith.length }})
          </dok-button>
          <template v-if="!share.doctors.length">
            <div class="flex flex-col gap-y-8 items-center justify-center">
              <img src="/svg/no_results.svg" />
              <h1 class="font-EffraM text-lg text-black">
                Aucun résultat trouvé essayez une autre recherche
              </h1>

              <p class="text-lg text-black font-EffraR">
                Invitez "{{ search }}" à rejoindre gratuitement dok Pro
              </p>

              <!-- Actions -->
              <dok-button
                size="md"
                custom-class="w-full rounded flex items-center gap-x-2 justify-center w-full lg:w-250"
              >
                <svg
                  width="25"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m22.5 2-11 11M22.5 2l-7 20-4-9-9-4 20-7z"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Inviter à rejoindre dok pro
              </dok-button>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="w-full py-3 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="120px"
              height="120px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle cx="30" cy="50" fill="#fd5b68" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="-0.5s"
                ></animate>
              </circle>
              <circle cx="70" cy="50" fill="#2e6bff" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="0s"
                ></animate>
              </circle>
              <circle cx="30" cy="50" fill="#fd5b68" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="-0.5s"
                ></animate>
                <animate
                  attributeName="fill-opacity"
                  values="0;0;1;1"
                  calcMode="discrete"
                  keyTimes="0;0.499;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              </circle>
            </svg>
          </div>
        </template>
      </div>

      <!-- Sent Request for Access -->
      <div
        v-if="
          data && ['NONE', 'REFUSED'].indexOf(data.shareRequestStatus) !== -1
        "
        class="flex flex-col justify-center gap-y-5 px-5"
      >
        <template v-if="!modalLoader">
          <img src="/svg/share_1.svg" class="max-h-300" />

          <div class="text-center text-black font-EffraR text-xl py-2">
            L'autorisation du patient est nécessaire pour partager sa
            consultation!
          </div>

          <dok-button
            size="md"
            custom-class="w-full rounded-none flex items-center justify-center gap-x-2 font-EffraM text-lg"
            @click.native="onDemandeAccess"
          >
            <svg
              width="24"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 2 11 13M22 2l-7 20-4-9-9-4 20-7z"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            Envoyer la demande au patient
          </dok-button>
        </template>
        <template v-else>
          <div class="w-full py-3 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="120px"
              height="120px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle cx="30" cy="50" fill="#fd5b68" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="-0.5s"
                ></animate>
              </circle>
              <circle cx="70" cy="50" fill="#2e6bff" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="0s"
                ></animate>
              </circle>
              <circle cx="30" cy="50" fill="#fd5b68" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="-0.5s"
                ></animate>
                <animate
                  attributeName="fill-opacity"
                  values="0;0;1;1"
                  calcMode="discrete"
                  keyTimes="0;0.499;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              </circle>
            </svg>
          </div>
        </template>
      </div>

      <!-- Waiting For Accept -->
      <div
        v-if="data && data.shareRequestStatus === 'PENDING'"
        class="px-5 flex flex-col justify-center gap-y-6"
      >
        <template v-if="!modalLoader">
          <img src="/svg/wait_confirm.svg" />
          <div class="flex flex-col gap-y-1 justify-center items-center">
            <h1 class="text-lg font-EffraM text-black">
              Nous sommes désolés pour l'attente!
            </h1>
            <span class="text-lg font-EffraR text-black text-center">
              Le patient n'a pas encore approuver le partage de sa
              consultation...
            </span>
          </div>
        </template>
        <template v-else>
          <div class="w-full py-3 flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="120px"
              height="120px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle cx="30" cy="50" fill="#fd5b68" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="-0.5s"
                ></animate>
              </circle>
              <circle cx="70" cy="50" fill="#2e6bff" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="0s"
                ></animate>
              </circle>
              <circle cx="30" cy="50" fill="#fd5b68" r="20">
                <animate
                  attributeName="cx"
                  repeatCount="indefinite"
                  dur="1s"
                  keyTimes="0;0.5;1"
                  values="30;70;30"
                  begin="-0.5s"
                ></animate>
                <animate
                  attributeName="fill-opacity"
                  values="0;0;1;1"
                  calcMode="discrete"
                  keyTimes="0;0.499;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                ></animate>
              </circle>
            </svg>
          </div>
        </template>
      </div>
    </modal>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";
import { debounce } from "lodash";
const modal = () => import("@/views/global-components/modal");

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  components: {
    modal,
  },
  data() {
    return {
      modalLoader: true,
      modalis: false,
      search: "",
      share: { doctors: [] },
      listOfIDS: [],
    };
  },
  watch: {
    modalis(newVal, oldVal) {
      if (newVal) {
        this.onGetDoctors();
      }
    },
    search: debounce(function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.onGetDoctors();
      }
    }, 500),
  },
  computed: {
    buttonSlot() {
      return this.$slots.button;
    },
  },
  methods: {
    ...mapActions({ getDoctors: "user/GET_ALL_DOCTORS" }),
    lodash() {
      return _;
    },
    onGetDoctors() {
      this.modalLoader = true;
      this.getDoctors({
        params: { term: this.search },
        onData: ({ ok, data }) => {
          if (ok) {
            this.share.doctors = data;
            this.$nextTick(() => {
              this.modalLoader = false;
            });
            return;
          }

          this.modalLoader = false;
        },
      });
    },
    choiceDoctor({ id, fname }) {
      if (id || fname) {
        let currentExist = this.data.sharedWith.indexOf(id) !== -1;

        if (currentExist) {
          return this.data.sharedWith.splice(
            this.data.sharedWith.indexOf(id),
            1
          );
        }

        this.data.sharedWith.push(id);
      }
    },
    async onShare() {
      this.$vs.loading();
      await this.$store.dispatch("dmp/SET_SHARE_WITH_DOCTOR", {
        data: this.data,
        onData: ({ ok, message, data }) => {
          if (ok) {
            this.$vs.loading.close();
            this.data.sharedWith = data.sharedWith;
            this.$performTracking({
              data: {
                id: this.$auth.getUserId(),
                route: "CONSULTATION",
                action: "SHARE",
                data: {},
              },
            });
            return EventBus.$emit("flashDok", {
              type: "success",
              message: "Consultation partagée avec succès ",
            });
          }

          EventBus.$emit("flashDok", {
            type: "errorr",
            message: "Erreur de partage, réessayer!",
          });
        },
      });
    },
    async onDemandeAccess() {
      this.$vs.loading();
      try {
        await this.$store.dispatch("dmp/SHARE_WITH_DOCTOR", {
          id: this.data._id,
          onData: ({ ok, message }) => {
            if (ok) {
              this.$performTracking({
                data: {
                  id: this.$auth.getUserId(),
                  route: "CONSULTATION",
                  action: "SHARE",
                  data: {},
                },
              });
              this.data.shareRequestStatus = "PENDING";
              return EventBus.$emit("flashDok", {
                type: "success",
                message: "Demande envoyée avec succès",
              });
            }
          },
        });
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
      }
    },
  },
};
</script>
