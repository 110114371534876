/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_LIST(state, payload) {
    state.clients = payload;
  },
  SET_ITEM(state, payload) {
    state.client = payload;
  },
  ADD_ITEM(state, payload) {
    state.clients.unshift(payload);
  },
  DELETE(state, payload) {
    state.clients = state.clients.filter(client => client._id !== payload);
  },
  UPDATE(state, payload) {
    state.clients = state.clients.map(client => {
      if (client._id === payload._id) {
        return payload;
      }
      return client;
    });
  }
};
