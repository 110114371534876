/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  recipes: state => state.recipes.map(item => {
    item.clientName = item.client ? (item.client.reasonSocial || `${item.client.firstName} ${item.client.lastName}`) : "";
    item.receivedText = item.received ? "Oui" : "Non";
    return item;
  }),
  totalItems: state => state.totalItems
};
