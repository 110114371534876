<template>
  <div :dir="$defaultLang === 'ar' && pathname === '/' ? 'rtl' : 'ltr'">
    <template>
      <!-- Dialog Confirmation-->
      <vue-confirm-dialog></vue-confirm-dialog>
      <!-- Dialog Confirmation End -->

      <!-- Alerts Dok -->
      <alerts-dok />
      <!-- End Alerts -->

      <!-- Phone Verification -->
      <phoneVerfication v-if="$auth.isAuthenticated()" />
      <!-- End Phone Verification -->

      <!-- Component Video Lancer -->
      <div
        class="fixed inset-x-0 h-full w-full z-1000"
        v-if="currentVideoGlobal"
      >
        <div class="relative bg-reddok h-full w-full">
          <video-call :height-full="true"></video-call>
        </div>
      </div>
      <!-- End Video Call -->

      <!-- Content -->
      <div class="h-full">
        <router-view v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </div>
      <!-- End Of Content -->
    </template>
    <!-- <template v-if="$root.loading">
      <div
        class="h-screen w-full bg-rosDok-lighter flex flex-col gap-y-10 items-center justify-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-[200px] h-[200px] lg:w-[300px] lg:h-[300px] animate-spin"
          fill="none"
          viewBox="0 0 512 512"
        >
          <path
            fill="#FD5B68"
            fill-rule="evenodd"
            d="M355.837 107.414c28.465 19.22 51.321 46.255 65.034 78.57 9.142 21.543 14.129 45.199 14.337 69.91h-84.15c0-9.504-1.454-18.797-4.156-27.457-2.908-8.659-6.856-16.897-11.843-24.289-10.181-14.785-24.725-26.824-41.763-34.005-11.428-4.646-23.894-7.392-37.192-7.392-9.973 0-19.531 1.478-28.466 4.224-8.934 2.746-17.453 6.759-24.933 11.828-15.168 10.138-27.011 24.5-34.075 41.186-4.779 10.983-7.272 23.022-7.272 35.905 0 14.498-7.102 27.33-17.958 35.092-6.871 4.933-15.272 7.834-24.344 7.834C95.829 298.82 77 279.806 77 256.351v-.227-.23c0-18.375 2.909-36.539 8.103-53.436 5.195-16.896 12.883-32.526 22.648-46.888 19.531-28.513 46.75-51.113 78.955-64.63C207.9 81.858 231.586 77 256.104 77c18.284 0 36.153 2.746 52.983 8.026 16.83 5.28 32.413 12.884 46.75 22.388z"
            clip-rule="evenodd"
            class="svg-elem-1"
          ></path>
          <path
            fill="#297AFB"
            fill-rule="evenodd"
            d="M434.999 255.593l.001-.294c0-23.455-18.829-42.469-42.056-42.469-15.899 0-29.737 8.908-36.887 22.051-3.447 6.212-5.415 13.382-5.415 21.013 0 51.958-42.802 93.355-94.746 93.355-52.568 0-94.746-41.82-94.746-93.355 0 23.656-18.908 42.876-42.179 42.876C95.908 298.77 77 279.55 77 255.894c0 98.635 79.994 178.895 178.896 178.895C354.798 435 435 354.952 435 255.894l-.001-.301z"
            clip-rule="evenodd"
            class="svg-elem-2"
          ></path>
        </svg>
      </div>
    </template> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";
import alertActivation from "@/views/global-components/alertActivation";
import phoneVerfication from "@/views/global-components/phoneVerfication";
import videoCall from "@/views/global-components/video_call.vue";

import FirebasePlugin from "@/plugins/firebase.js";
export default {
  name: "App",
  data() {
    return {
      pathname: window.location.pathname,
      currentVideoGlobal: false,
      currentConversationId: null
    };
  },
  methods: {
    ...mapActions({
      saveFirebaseToken: "user/UPDATE_FIREBASE_TOKEN",
      getFirebaseToken: "user/GET_FIREBASE_TOKEN"
    }),
    onEndCallClicked() {
      this.currentConversationId = null;
    },
    onCollectionVideo({ conversationId }) {
      if (conversationId && conversationId !== this.currentConversationId) {
        this.currentConversationId = conversationId;
      }
    },
    onAcceptCallCicked(conversationId) {
      if (conversationId && conversationId !== this.currentConversationId) {
        this.currentConversationId = conversationId;
      }
    },
    refreshFirebaseToken() {
      this.getFirebaseToken({
        onData: ({ data }) => {
          console.log("Refreshed", data);
          this.$auth.setFirebaseToken(data);
          FirebasePlugin.initialize(data);
        }
      });
    }
  },
  mounted() {
    // Expand Video Call
    EventBus.$on("EXPAND_VIDEO_CALL_DOK", () => {
      this.currentVideoGlobal = !this.currentVideoGlobal;
    });

    EventBus.$on(
      "refresh-firebase-custom-token",
      this.refreshFirebaseToken.bind(this)
    );

    // Check if success login
    if (this.$auth.isAuthenticated()) {
      FirebasePlugin.initialize(this.$auth.getFirebaseToken());

      const {
        _id,
        email,
        idHash,
        emailHash,
        firstName,
        lastName
      } = this.$auth.getUserInfo();

      // Intercom boot
      this.$intercom.boot({
        user_id: _id,
        user_hash: idHash,
        name: [firstName, lastName].join(" "),
        email
      });

      if (!this.$socketInstance().isConnected()) {
        this.$socketInstance();

        // Notifications Global
        EventBus.$on("newNotification", payload => {
          this.$vs.notify({
            time: 8000,
            title: payload.title,
            text: payload.body,
            color: "success",
            iconPack: "feather",
            icon: "icon-check"
          });
        });
      }
    }
  },
  components: { alertActivation, phoneVerfication, videoCall }
};
</script>

<style lang="scss">
.intercom-lightweight-app-launcher {
  bottom: 90px !important;
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }
  100% {
    fill: rgb(253, 91, 104);
  }
}

.svg-elem-1 {
  animation: animate-svg-fill-1 0.3s linear 0.4s both;
}

@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }
  100% {
    fill: rgb(41, 122, 251);
  }
}

.svg-elem-2 {
  animation: animate-svg-fill-2 0.3s linear 0.5s both;
}

.vs-notifications {
  z-index: 99999999999 !important;
}

.vs-notifications p {
  font-family: "Effra Regular";
  font-size: 15px !important;
  color: #fff !important;
}

.vc-text {
  font-family: "Effra Regular";
  font-size: 16px !important;
}
</style>
