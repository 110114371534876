<template>
  <div class="relative">
    <button
      class="
        z-1
        relative
        flex
        items-center
        focus:outline-none
        select-none
        bg-transparent
        border-0
        cursor-pointer
      "
      @click="open = !open"
      aria-label="dropdown"
    >
      <slot name="button"></slot>
    </button>

    <!-- to close when click background desktop-->
    <button
      class="
        z-2
        border-0
        fixed
        inset-0
        h-full
        w-full
        cursor-default
        focus:outline-none
        bg-black bg-opacity-10
      "
      v-if="open"
      @click="open = false"
      tabindex="-1"
      aria-label="close"
    ></button>

    <!--Desktop-->
    <div
      class="
        z-3
        hidden
        md:block
        absolute
        shadow-lg
        border
        min-w-370
        rounded
        py-1
        px-2
        text-sm
        mt-4
        bg-white
        max-h-500
        overflow-y-scroll
        dok-downdrop-scroll
      "
      :class="placement === 'right' ? 'right-0' : 'left-0'"
      v-if="open"
    >
      <slot name="content"></slot>
    </div>

    <!--Mobile-->
    <div
      class="
        z-3
        md:hidden
        fixed
        inset-x-0
        bottom-0
        bg-white
        w-full
        px-2
        py-2
        shadow-2xl
        leading-loose
        max-h-400
        overflow-y-scroll
        scrolling-touch
        dok-downdrop-scroll
      "
      v-if="open"
    >
      <slot name="content"></slot>
    </div>

    <!-- to close when click background mobile-->
    <div
      class="
        z-2
        border-0
        fixed
        inset-0
        h-full
        w-full
        cursor-default
        focus:outline-none
        bg-black bg-opacity-10
      "
      @click="open = false"
      v-if="open"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.dok-downdrop-scroll::v-deep {
  &::-webkit-scrollbar {
    width: 6px;
    height: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 100vh;
    @apply bg-gray-200;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-dokBlue-ultra;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300;
  }
}
</style>

<script>
import { EventBus } from "@/event-bus";

export default {
  name: "dok-downdrop",
  data() {
    return {
      open: false
    };
  },
  props: {
    placement: {
      type: String,
      default: "right",
      validator: value => ["right", "left"].indexOf(value) !== -1
    }
  },
  mounted() {
    EventBus.$on("RefreshCountDown", () => {
      this.$forceUpdate();
    });

    // EventBus Listner
    EventBus.$on("Close_dropdown", () => {
      this.open = false;
    });

    const onEscape = e => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.open = false;
      }
    };

    document.addEventListener("keydown", onEscape);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", onEscape);
    });
  }
};
</script>
