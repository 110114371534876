<template>
  <div class="relative w-full">
    <input
      class="
        border-2 border-solid border-gray-300
        py-5
        px-3
        rounded-cu
        font-EffraR
        text-lg
        w-full
      "
      v-model="inputVal"
      :type="type ? type : 'text'"
      :class="hasImage ? `pl-10 ${customClass}` : `${customClass}`"
      :style="getSize()"
      :placeholder="xplacehoder"
    />
    <slot name="othericon"></slot>
    <slot name="othericonright"></slot>
  </div>
</template>

<script>
export default {
  props: {
    ruleValidation: {
      type: String,
      required: false
    },
    customClass: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false
    },
    xplacehoder: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  watch: {
    inputVal: (newVal, oldVal) => {
      //
    }
  },
  data() {
    return {
      valueInput: null
    };
  },
  computed: {
    hasImage() {
      return !!this.$slots.othericon;
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    getSize() {
      if (this.size === "lg") return "height: 48px;";
      if (this.size === "md") return "height: 39px;";
      if (this.size === "sm") return "height: 33px;";
    },
    setContent() {
      // //console.log(event);
    }
  },
  destroyed() {
    this.$off();
  }
};
</script>
