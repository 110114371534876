<template>
  <modal
    :is-active="modal"
    :exit-modal="
      () => {
        modal = false;
      }
    "
    :no-padding="true"
    :max-width="510"
  >
    <section id="ForgetPassword" class="py-5">
      <template v-if="page === 1">
        <header
          class="flex flex-col gap-y-4 items-center justify-center px-5 mb-10"
        >
          <h1 class="text-2xl font-EffraM text-black">
            Entrez le code de vérification
          </h1>
          <span class="text-lg font-EffraR text-black"
            >Entrez le code à 6 chiffres que nous avons envoyé au
            {{ $auth.getUserInfo().mobile }}</span
          >
          <span
            class="font-EffraR text-base text-gray-600 flex items-start text-center"
            >Pour modifier votre numéro de téléphone mobile rendez-vous dans
            l'onglet "paramètres"</span
          >
        </header>

        <div class="px-5 w-full flex justify-center gap-x-3 mb-10">
          <multi-input
            inputClasses="input-mask"
            :numInputs="6"
            separator=""
            :shouldAutoFocus="true"
            @on-complete="handleOnComplete"
            @on-change="handleOnChange"
            input-type="number"
          />
        </div>

        <footer
          class="flex flex-col gap-y-4 border-0 border-t border-solid border-gray-400 px-5 pt-5"
        >
          <button
            type="button"
            class="border-0  py-3 text-white text-lg font-EffraM w-full rounded"
            :class="
              !data.codeVerification.length || data.codeVerification.length < 6
                ? 'bg-dokBlue-light cursor-not-allowed'
                : 'bg-dokBlue-ultra cursor-pointer'
            "
            @click="
              !data.codeVerification.length || data.codeVerification.length < 6
                ? null
                : onActiveAccount()
            "
          >
            Confirmer le code
          </button>

          <button
            type="button"
            class="bg-transparent border-0  py-3 text-red-600 text-lg font-EffraM w-full rounded cursor-pointer"
            @click="onSentSMS"
          >
            {{ smsSent ? "Renvoyer" : "Envoyer" }} le code
          </button>
        </footer>
      </template>
      <template v-if="page === 2">
        <div class="px-5 w-full flex justify-center gap-x-3 mb-10">
          <img src="/svg/frame.svg" />
        </div>
        <div class="flex flex-col gap-y-2 px-5 mb-5">
          <h1 class="text-black font-EffraM text-2xl flex justify-center">
            Félicitations
          </h1>
          <span class="text-lg text-black font-EffraR">
            Votre numéro de téléphone a été vérifié avec succès
          </span>
        </div>
        <footer
          class="flex flex-col gap-y-4 border-0 border-t border-solid border-gray-400 px-5 pt-5"
        >
          <button
            type="button"
            class="border-0 bg-dokBlue-ultra cursor-pointerborder-0  py-3 text-white text-lg font-EffraM w-full rounded cursor-pointer"
            @click="
              () => {
                this.modal = false;
                this.page = 1;
                this.data.codeVerification = '';
              }
            "
          >
            D'accord merci
          </button>
        </footer>
      </template>
    </section>
  </modal>
</template>

<script>
import modal from "@/views/global-components/dragModal";
import multiInput from "@/views/global-components/multiInput";
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      modal: false,
      page: 1,
      smsSent: false,
      data: {
        codeVerification: ""
      }
    };
  },
  mounted() {
    EventBus.$on("Call_Phone_Verification", () => {
      this.modal = true;
      this.page = 1;
      this.data.codeVerification = "";
    });
  },
  methods: {
    ...mapActions("user", ["SENT_2FA_ACCOUNT", "ACTIVE_2FA_ACCOUNT"]),
    async onSentSMS() {
      this.$vs.loading({ background: "#000" });
      const data = await this.SENT_2FA_ACCOUNT({
        onData: ({ ok, message }) => {
          this.smsSent = true;
          this.$vs.notify({
            time: 4000,
            text: message,
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: "icon-check"
          });

          this.$vs.loading.close();
        }
      });
    },
    async onActiveAccount() {
      this.$vs.loading({ background: "#000" });
      const data = await this.ACTIVE_2FA_ACCOUNT({
        token: this.data.codeVerification,
        onData: ({ ok, message }) => {
          if (ok) {
            // Change Params isMobileVerified to True
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            userInfo.isMobileVerified = true;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));

            EventBus.$emit("reload_nav_bar");

            this.page = 2;
          }

          this.$vs.notify({
            time: 4000,
            text: message,
            color: ok ? "success" : "danger",
            iconPack: "feather",
            icon: "icon-check"
          });

          this.$vs.loading.close();
        }
      });
    },
    handleOnComplete(value) {
      EventBus.$emit("reloadProfileData");
      this.data.codeVerification = value;
    },
    handleOnChange(value) {
      this.data.codeVerification = value;
    }
  },
  components: { modal, multiInput }
};
</script>

<style lang="scss" scoped>
::v-deep .input-mask {
  @apply h-16 w-16 border-2 border-solid border-gray-500 rounded-cu px-1 text-4xl font-EffraM text-center;
  &:focus {
    @apply border-dokBlue-ultra;
  }
}

::v-deep .input-mask::-webkit-inner-spin-button,
::v-deep .input-mask::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
