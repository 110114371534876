<template>
  <div class="flex flex-col w-full" :class="label && 'gap-y-1'">
    <label
      :for="`${label}-${generationRandom}`"
      class="text-base text-gray-800 font-EffraR"
      >{{ label }}</label
    >
    <div class="relative">
      <!-- `{customClass ? `border-borderInput ${customClass}` :
            'w-full border-borderInput'}   ${sizeInput} ${
            hasLeftIcon ? 'pl-13' : ''
          }` -->

      <!--  -->
      <input
        :placeholder="dPlaceholder"
        :type="dType"
        :pattern="dPattern"
        :class="
          [
            'rounded px-2 font-EffraR text-graylabel placeholder-graylabel border border-solid w-full',
            customClass && customClass.includes('border')
              ? customClass
              : 'border-borderInput',
            sizeInput,
            hasLeftIcon ? 'pl-[42px] ' : '',
          ].join(' ')
        "
        :id="`${label || 'dok'}-${generationRandom}`"
        v-model="valueInput"
        @input="dChange ? dChange($event) : () => {}"
        :disabled="dDisbled"
        @focus="dFocus"
        @blur="dBlur"
        lang="fr-fr"
      />
      <span
        class="absolute top-0 right-0 flex items-center h-full px-2"
        v-if="hasRightIcon"
      >
        <slot name="rightIcon"></slot>
      </span>
      <span
        class="absolute top-0 left-0 flex items-center h-full px-2"
        v-if="hasLeftIcon"
      >
        <slot name="leftIcon"></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      generationRandom: Math.random(),
    };
  },
  props: {
    dModel: {
      type: String | Number | Date,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: "sm", // sm, md, lg
    },
    dPlaceholder: {
      type: String,
      required: false,
      default: "",
    },
    dChange: {
      type: Function,
      required: false,
      default: () => {},
    },
    dType: {
      type: String,
      required: false,
      default: "text",
    },
    dPattern: {
      type: String,
      required: false,
    },
    dDisbled: {
      type: Boolean,
      required: false,
    },
    dFocus: {
      type: Function,
      required: false,
      default: () => {},
    },
    dBlur: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  computed: {
    valueInput: {
      get() {
        return this.dModel;
      },
      set(value) {
        this.$emit("update:dModel", value);
      },
    },
    hasLeftIcon() {
      return this.$slots.leftIcon;
    },
    hasRightIcon() {
      return this.$slots.rightIcon;
    },
    sizeInput() {
      switch (this.size) {
        case "sm":
          return "input-sm";
          break;
        case "md":
          return "input-md";
          break;
        case "lg":
          return "input-lg";
          break;
        default:
          return "input-md";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-sm {
  height: 32px;
  font-size: 16px;
}
.input-md {
  height: 40px;
  font-size: 16px;
}
.input-lg {
  height: 48px;
  font-size: 16px;
}
</style>
