var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full",class:_vm.label && 'gap-y-1'},[_c('label',{staticClass:"text-base text-gray-800 font-EffraR",attrs:{"for":(_vm.label + "-" + _vm.generationRandom)}},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"relative"},[((_vm.dType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueInput),expression:"valueInput"}],class:[
          'rounded px-2 font-EffraR text-graylabel placeholder-graylabel border border-solid w-full',
          _vm.customClass && _vm.customClass.includes('border')
            ? _vm.customClass
            : 'border-borderInput',
          _vm.sizeInput,
          _vm.hasLeftIcon ? 'pl-[42px] ' : '' ].join(' '),attrs:{"placeholder":_vm.dPlaceholder,"pattern":_vm.dPattern,"id":((_vm.label || 'dok') + "-" + _vm.generationRandom),"disabled":_vm.dDisbled,"lang":"fr-fr","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.valueInput)?_vm._i(_vm.valueInput,null)>-1:(_vm.valueInput)},on:{"input":function($event){_vm.dChange ? _vm.dChange($event) : function () {}},"focus":_vm.dFocus,"blur":_vm.dBlur,"change":function($event){var $$a=_vm.valueInput,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueInput=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueInput=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueInput=$$c}}}}):((_vm.dType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueInput),expression:"valueInput"}],class:[
          'rounded px-2 font-EffraR text-graylabel placeholder-graylabel border border-solid w-full',
          _vm.customClass && _vm.customClass.includes('border')
            ? _vm.customClass
            : 'border-borderInput',
          _vm.sizeInput,
          _vm.hasLeftIcon ? 'pl-[42px] ' : '' ].join(' '),attrs:{"placeholder":_vm.dPlaceholder,"pattern":_vm.dPattern,"id":((_vm.label || 'dok') + "-" + _vm.generationRandom),"disabled":_vm.dDisbled,"lang":"fr-fr","type":"radio"},domProps:{"checked":_vm._q(_vm.valueInput,null)},on:{"input":function($event){_vm.dChange ? _vm.dChange($event) : function () {}},"focus":_vm.dFocus,"blur":_vm.dBlur,"change":function($event){_vm.valueInput=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueInput),expression:"valueInput"}],class:[
          'rounded px-2 font-EffraR text-graylabel placeholder-graylabel border border-solid w-full',
          _vm.customClass && _vm.customClass.includes('border')
            ? _vm.customClass
            : 'border-borderInput',
          _vm.sizeInput,
          _vm.hasLeftIcon ? 'pl-[42px] ' : '' ].join(' '),attrs:{"placeholder":_vm.dPlaceholder,"pattern":_vm.dPattern,"id":((_vm.label || 'dok') + "-" + _vm.generationRandom),"disabled":_vm.dDisbled,"lang":"fr-fr","type":_vm.dType},domProps:{"value":(_vm.valueInput)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.valueInput=$event.target.value},function($event){_vm.dChange ? _vm.dChange($event) : function () {}}],"focus":_vm.dFocus,"blur":_vm.dBlur}}),(_vm.hasRightIcon)?_c('span',{staticClass:"absolute top-0 right-0 flex items-center h-full px-2"},[_vm._t("rightIcon")],2):_vm._e(),(_vm.hasLeftIcon)?_c('span',{staticClass:"absolute top-0 left-0 flex items-center h-full px-2"},[_vm._t("leftIcon")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }