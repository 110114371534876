var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"is-active":_vm.modal,"exit-modal":function () {
      _vm.modal = false;
    },"no-padding":true,"max-width":510}},[_c('section',{staticClass:"py-5",attrs:{"id":"ForgetPassword"}},[(_vm.page === 1)?[_c('header',{staticClass:"flex flex-col gap-y-4 items-center justify-center px-5 mb-10"},[_c('h1',{staticClass:"text-2xl font-EffraM text-black"},[_vm._v(" Entrez le code de vérification ")]),_c('span',{staticClass:"text-lg font-EffraR text-black"},[_vm._v("Entrez le code à 6 chiffres que nous avons envoyé au "+_vm._s(_vm.$auth.getUserInfo().mobile))]),_c('span',{staticClass:"font-EffraR text-base text-gray-600 flex items-start text-center"},[_vm._v("Pour modifier votre numéro de téléphone mobile rendez-vous dans l'onglet \"paramètres\"")])]),_c('div',{staticClass:"px-5 w-full flex justify-center gap-x-3 mb-10"},[_c('multi-input',{attrs:{"inputClasses":"input-mask","numInputs":6,"separator":"","shouldAutoFocus":true,"input-type":"number"},on:{"on-complete":_vm.handleOnComplete,"on-change":_vm.handleOnChange}})],1),_c('footer',{staticClass:"flex flex-col gap-y-4 border-0 border-t border-solid border-gray-400 px-5 pt-5"},[_c('button',{staticClass:"border-0  py-3 text-white text-lg font-EffraM w-full rounded",class:!_vm.data.codeVerification.length || _vm.data.codeVerification.length < 6
              ? 'bg-dokBlue-light cursor-not-allowed'
              : 'bg-dokBlue-ultra cursor-pointer',attrs:{"type":"button"},on:{"click":function($event){!_vm.data.codeVerification.length || _vm.data.codeVerification.length < 6
              ? null
              : _vm.onActiveAccount()}}},[_vm._v(" Confirmer le code ")]),_c('button',{staticClass:"bg-transparent border-0  py-3 text-red-600 text-lg font-EffraM w-full rounded cursor-pointer",attrs:{"type":"button"},on:{"click":_vm.onSentSMS}},[_vm._v(" "+_vm._s(_vm.smsSent ? "Renvoyer" : "Envoyer")+" le code ")])])]:_vm._e(),(_vm.page === 2)?[_c('div',{staticClass:"px-5 w-full flex justify-center gap-x-3 mb-10"},[_c('img',{attrs:{"src":"/svg/frame.svg"}})]),_c('div',{staticClass:"flex flex-col gap-y-2 px-5 mb-5"},[_c('h1',{staticClass:"text-black font-EffraM text-2xl flex justify-center"},[_vm._v(" Félicitations ")]),_c('span',{staticClass:"text-lg text-black font-EffraR"},[_vm._v(" Votre numéro de téléphone a été vérifié avec succès ")])]),_c('footer',{staticClass:"flex flex-col gap-y-4 border-0 border-t border-solid border-gray-400 px-5 pt-5"},[_c('button',{staticClass:"border-0 bg-dokBlue-ultra cursor-pointerborder-0  py-3 text-white text-lg font-EffraM w-full rounded cursor-pointer",attrs:{"type":"button"},on:{"click":function () {
              this$1.modal = false;
              this$1.page = 1;
              this$1.data.codeVerification = '';
            }}},[_vm._v(" D'accord merci ")])])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }