<template>
  <div class="flex flex-initial">
    <button
      v-for="(button, index) in groups"
      :key="index"
      class="
        bg-white
        font-EffraR
        text-base
        py-2
        border border-solid border-dokBlue-ultra
        text-dokBlue-ultra
        px-5
        cursor-pointer
      "
      :class="roundedClass(index)"
      @click="button.click"
    >
      {{ button.name }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  methods: {
    roundedClass(index) {
      if (this.groups.length === 1) {
        return "rounded";
      }

      if (index === 0) {
        return "rounded-l";
      }

      if (this.groups.length - 1 === index) {
        return "rounded-r";
      }
    },
  },
};
</script>

<style>
</style>