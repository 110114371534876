import axios from "axios";
import { isArray } from "lodash";

export default {
  async GET_CONSULTATIONS({ commit }, payload) {
    const { data } = await axios.get(
      `patients/${payload.patientId}/dmp/consultations`,
      {
        params: {
          page: payload.page || 1
        }
      }
    );
    payload.onData(data);
  },
  async SHARE_WITH_DOCTOR({ commit }, payload) {
    const { data } = await axios.post(`consultations/share/${payload.id}`);
    payload.onData(data);
  },
  async SET_SHARE_WITH_DOCTOR({ commit }, payload) {
    const { data } = await axios.put(
      `/agenda/events/${payload.data._id}/share`,
      {
        doctorsId: payload.data.sharedWith
      }
    );
    payload.onData(data);
  },
  async GET_NOTE_CONFIDENTIELLE_PATIENT({ commit }, payload) {
    const { data } = await axios.get(
      `patients/${payload.patientId}/note-confidentielle`
    );
    payload.onData(data);
  },
  async SAVE_NOTE_CONFIDENTIELLE_PATIENT({ commit }, payload) {
    const {
      data
    } = await axios.put(`patients/${payload.patientId}/note-confidentielle`, {
      note: payload.note
    });
    payload.onData(data);
  }
};
