import Vue from "vue";

//Vuesax
// import Vuesax from "vuesax";
// import "material-icons/iconfont/material-icons.css";
import "vuesax/dist/vuesax.css";
// Vue.use(Vuesax);

// Dialog Confirmation
import VueConfirmDialog from "vue-confirm-dialog";
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

// Google Validation Phone Number
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

// Validations
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

// install rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend("verify_password", {
  validate: value => {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return strongRegex.test(value);
  }
});

extend("phoneNumber", value => {
  return new Promise(resolve => {
    resolve({
      valid:
        value &&
        value.length > 6 &&
        phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value, "MA"))
    });
  });
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// import moment from "moment-timezone";
// require("moment/locale/fr");
// moment.tz.setDefault("Africa/Casablanca");
import moment from "moment";
import "moment/locale/fr"; // without this line it didn't work
moment.locale("fr");

// Vue Moment
Vue.use(require("vue-moment"));

import "vue-googlemaps/dist/vue-googlemaps.css";
import VueGoogleMapsss from "vue-googlemaps";

Vue.use(VueGoogleMapsss, {
  load: {
    // Google API key
    apiKey: "AIzaSyDIw6UoPEGDOvd3E4AlKiZ6CVbWxZSnwaQ",
    // Enable more Google Maps libraries here
    libraries: ["places"],
    // Use new renderer
    useBetaRenderer: false
  }
});

// Algolia Search
import InstantSearch from "vue-instantsearch";
Vue.use(InstantSearch);

// ReadMore Content
import ReadMore from "vue-read-more";
Vue.use(ReadMore);

// Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Authorization Plugin
import AuthPlugin from "./plugins/auth";
Vue.use(AuthPlugin);
import axios from "./axios";
Vue.prototype.$http = axios;
// Vue Multi Select
import Multiselect from "vue-multiselect";
require("vue-multiselect/dist/vue-multiselect.min.css");
Vue.component("multiselect", {
  mixins: [Multiselect],
  props: {
    selectLabel: {
      default: ""
    },
    selectedLabel: {
      default: ""
    },
    selectGroupLabel: {
      default: "Multi selectionnez"
    },
    deselectLabel: {
      default: "Cliquer pour supprimer"
    },
    placeholder: {
      default: ""
    }
  }
});

// Toggle button
import { ToggleButton } from "vue-js-toggle-button";
Vue.component("ToggleButton", ToggleButton);

// Tooltip
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

// As a plugin
import VueMask from "v-mask";
Vue.use(VueMask);

// Main JS (in UMD format)
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
Vue.component("VueTimepicker", VueTimepicker);

if (process.env.VUE_APP_NODE_ENV != "development") {
  Vue.config.errorHandler = (err, vm, info) => {
    // vm.$rollbar.error(err);
  };
}

import VueIntercom from "vue-intercom";

Vue.use(VueIntercom, { appId: "sv31idt5" });

// Vue I18 Langugues
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
