<template>
  <div v-if="flashMessage">
    <!-- Update Success Alert -->
    <div
      v-if="type === 'success'"
      style="box-shadow: 0 8px 52px 0 rgba(0, 0, 0, 0.18); z-index: 1500"
      :style="`top: ${top}px;`"
      class="
        fixed
        right-0
        m-5
        bg-white
        border-0 border-l-4 border-solid border-green-700
        px-2
        py-8
        w-auto
        md:w-400
        h-120
      "
    >
      <div class="flex flex-row gap-x-2 w-full items-center">
        <div class="flex flex-row items-start gap-x-2 flex-1">
          <img src="/svg/check-square.svg" />
          <span class="font-EffraR text-2xl text-green-700">{{
            message || "Changes have been saved"
          }}</span>
        </div>
        <span class="cursor-pointer" @click="flashMessage = false"
          ><img src="/svg/x.svg"
        /></span>
      </div>
    </div>
    <!-- Error Alert -->
    <div
      v-if="type === 'errorr'"
      style="box-shadow: 0 8px 52px 0 rgba(0, 0, 0, 0.18); z-index: 1500"
      :style="`top: ${top}px;`"
      class="
        fixed
        right-0
        m-5
        bg-white
        border-0 border-l-4 border-solid border-rosDok-ultra
        px-2
        py-8
        w-auto
        md:w-400
        min-h-100
      "
    >
      <div class="flex flex-row gap-x-2 w-full items-center">
        <div class="flex flex-row items-start gap-x-2 flex-1">
          <img src="/svg/alert-triangle-red.svg" />
          <span class="font-EffraR text-2xl text-rosDok-ultra">
            {{ message ? message : "--" }}
          </span>
        </div>
        <span class="cursor-pointer" @click="flashMessage = false"
          ><img src="/svg/x.svg"
        /></span>
      </div>
    </div>
    <!-- Delete Alert -->
    <div
      v-if="type === 'delete'"
      style="box-shadow: 0 8px 52px 0 rgba(0, 0, 0, 0.18); z-index: 1500"
      class="
        absolute
        right-0
        m-5
        bg-white
        rounded-cu
        px-2
        py-8
        w-auto
        md:w-400
        min-h-75
        flex flex-col
        gap-y-1
      "
    >
      <div class="flex justify-between">
        <div class="flex-1 flex items-center gap-x-2">
          <img src="/svg/alert-triangle.svg" />
          <span class="text-2xl font-EffraR text-black">{{ title }}</span>
        </div>
        <span class="cursor-pointer" @click="flashMessage = false">
          <img src="/svg/x.svg" />
        </span>
      </div>
      <p class="text-base font-EffraR" style="color: #515151">
        {{
          message ||
          "If you delete this, you will lose it permanently and will never have access again."
        }}
      </p>

      <div class="flex justify-end">
        <div class="flex items-center gap-x-4">
          <button
            class="
              bg-transparent
              border-0
              py-3
              px-5
              font-EffraM
              text-base text-black
              rounded-cu
              cursor-pointer
            "
            @click="cancelClicked"
          >
            Non, Garder
          </button>
          <button
            class="
              bg-rosDok-ultra
              border-0
              py-3
              px-5
              font-EffraM
              text-base text-white
              rounded-cu
              cursor-pointer
            "
            @click="confirmClicked"
          >
            Oui, Supprimer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      top: 70,
      message: null,
      successmessage: null,
      type: null,
      flashMessage: false,
      defualtTime: 12000,
      confirm: null,
      cancel: null,
      title: null,
    };
  },
  mounted() {
    EventBus.$on("flashDok", (data) => {
      this.message = data.message || null;
      this.successmessage = data.successmessage;
      this.type = data.type;
      this.title = data.title ? data.title : "Suppression du rendez-vous";
      this.flashMessage = true;

      if (data.type === "delete") {
        this.confirm = data.confirm;
        this.cancel = data.cancel;
      }

      setTimeout(() => {
        this.flashMessage = false;
        this.type = null;
      }, 2000);
      //data.timer || this.defualtTime
    });
  },
  methods: {
    async confirmClicked() {
      await this.confirm();
      this.flashMessage = false;
      EventBus.$emit("Modal_Close", true);
    },
    async cancelClicked() {
      this.flashMessage = false;
    },
  },
};
</script>

<style></style>
