"use strict";

import axios from "axios";
export default {
  async GET_CERTIFICATES_MODELS({}, payload) {
    const { data } = await axios.get(`certificates/models`);
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async SAVE_DOCTOR_MODEL({}, payload) {
    // console.log(payload)
    const { data } = await axios.post(
      `certificates/models/save`,
      payload.params
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async UPDATE_DOCTOR_MODEL({}, payload) {
    // console.log(payload)
    const { data } = await axios.put(
      `certificates/models/${payload.modelId}`,
      payload.params
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async DELETE_DOCTOR_MODEL({}, payload) {
    // console.log(payload)
    const { data } = await axios.delete(
      `certificates/models/${payload.modelId}`,
      // payload.params
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async GET_CERTIFICATES_CONSULTATION({}, payload) {
    const { data } = await axios.get(
      `certificates/consultation/${payload.consultationId}`
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async GET_CERTIFICATE({}, payload) {
    const { data } = await axios.get(`certificates/${payload.certificateId}`);
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async SAVE_CERTIFICATES_FOR_PATIENT({}, payload) {
    const { data } = await axios.post(
      `certificates/consultation/${payload.consultationId}`,
      payload.params
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async SAVE_CERTIFICATES_FOR_PATIENT_NO_CALLBACK({}, payload) {
    const { data } = await axios.post(
      `certificates/consultation/${payload.consultationId}`,
      payload.params
    );

    return data;
  },

  async UPDATE_CERTIFICATES_FOR_PATIENT({}, payload) {
    const { data } = await axios.put(
      `certificates/${payload.patientSoinId}`,
      payload.params
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async REMOVE_CERTIFICATE({}, payload) {
    const { data } = await axios.delete(
      `certificates/${payload.certificateId}`
    );
    if (payload.onData) {
      payload.onData(payload.certificateId, data);
    }
  },
  async GENERATE_CERTIFICATE({}, payload) {
    const { data } = await axios.get(
      `certificates/${payload.certificateId}/generate`
    );
    if (payload.onData) {
      payload.onData(data);
    }
  }
};
