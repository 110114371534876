/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_LIST(state, payload) {
    state.files = payload;
  },
  UDATE_CURRENTFILE(state, payload) {
    state.filesHistory.push(payload);
    state.currentFile = payload;
  },
  GO_TO_THE_PARENT(state, payload) {
    state.currentFile =
      state.filesHistory.length <= 1
        ? null
        : state.filesHistory[state.filesHistory.length - 2];
    state.filesHistory =
      state.filesHistory.length <= 1 ? [] : state.filesHistory.splice(-1, 1);
  },
  ADD_FILE(state, payload) {
    if (payload.isArray == true) {
      payload.data.map((item, index) => {
        state.files.unshift(item);
      });
    } else {
      state.files.unshift(payload.data);
    }
  },
  REMOVE_RECORD(state, payload) {
    state.files.splice(payload, 1);
  },
  UPDATE(state, payload) {
    state.files[payload.index] = payload.file;
  }
};
