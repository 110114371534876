var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full flex flex-col",class:_vm.label && 'gap-y-1',attrs:{"id":_vm.generationRandom}},[_c('label',{staticClass:"font-EffraR text-base text-gray-800",attrs:{"for":(_vm.label + "-" + _vm.generationRandom)}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"relative"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueInput),expression:"valueInput"}],class:[
          _vm.dokDisabled ? 'bg-grayxx ' : 'bg-transparent ',
          !_vm.noBorder ? 'border-2 ' : 'border-0 ',
          'border-solid rounded px-1 w-full font-EffraR text-graylabel appearance-none ',
          _vm.sizeInput,
          _vm.hasLeftIcon && 'pl-10 ',
          _vm.hasRightIcon && 'pr-10 ',
          _vm.customClass && _vm.customClass.includes('border')
            ? _vm.customClass
            : 'border-borderInput'
        ].join(' '),attrs:{"multiple":_vm.multiple ? 'multiple' : false,"disabled":!_vm.dokDisabled ? false : true},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.valueInput=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.changeValue ? _vm.changeValue : function () {}}]}},[_vm._t("options")],2),(_vm.hasRightIcon)?_c('span',{staticClass:"absolute right-0 top-0 h-full flex items-center px-2"},[_vm._t("rightIcon")],2):_vm._e(),(_vm.hasLeftIcon)?_c('span',{staticClass:"absolute left-0 top-0 h-full flex items-center px-2"},[_vm._t("leftIcon")],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }