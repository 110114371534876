var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
      _vm.iconButton,
      _vm.customClass,
      _vm.sizeInput,
      _vm.backgroundInput,
      _vm.hasRounded,
      'font-EffraR cursor-pointer '
    ].join(' '),attrs:{"type":_vm.type}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }