"use strict";

import axios from "axios";

export default {
  async GET_PRESCRIPTION({}, payload) {
    const { data } = await axios.get(`laboratory/prescription/${payload.id}`);
    payload.onData(data);
  },

  async GET_PATIENTS({}, payload) {
    const { data } = await axios.get(`laboratory/patients`, {
      params: { filterName: payload.filterName, page: payload.page }
    });
    return data;
  },

  async GET_PATIENT_ORDANNANCES({}, payload) {
    const { data } = await axios.get(
      `laboratory/patient/${payload.id}/ordonnances`
    );
    return data;
  },

  async UPDATE_PROFILE({}, payload) {
    const { data } = await axios.put(`pharmacy/updateProfile`, {
      parcours: payload.parcours,
      diplomes: payload.diplomes
    });
    return data;
  },

  async GET_EMPLOYES({}) {
    const { data } = await axios.get(`laboratory/users`);
    return data;
  },

  async STORE_EMPLOYE({}, payload) {
    const { data } = await axios.post(`laboratory/user`, { ...payload });
    return data;
  },

  async UPDATE_EMPLOYE({}, payload) {
    const { data } = await axios.put(`laboratory/user`, { ...payload });
    return data;
  },

  async DELETE_EMPLOYE({}, payload) {
    const { data } = await axios.delete(`laboratory/user`, {
      params: { id: payload.id }
    });
    return data;
  }
};
