import Vue from "vue";
import Router from "vue-router";
import qs from "qs";

Vue.use(Router);

// {
//   path: "/consultations/:id",
//   name: "folders_detail",
//   component: () =>
//     import("./views/dashboard/consultations/Details.vue"),
//   meta: { isAuth: true, rule: "doctor" }
// },

const router = new Router({
  scrollBehavior() {
    // After Change Route Scoll go to top auto
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes: [
    // Emails & Chat
    {
      path: "/dashboard",
      component: () => import("@/layouts/chat/dashboard.vue"),
      children: [
        // Email && Messangrie
        {
          path: "/messagre/pro",
          name: "messagesPro",
          component: () => import("@/views/dashboard/messagrePro/index.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/chat/pro",
          name: "chatDashboard",
          component: () => import("@/views/dashboard/chatPro/index.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
      ],
    },
    // Dashboard
    {
      path: "/dashboard",
      component: () => import("@/layouts/newdashboard/index_consultation.vue"),
      children: [
        {
          path: "/consultations/:id",
          name: "folders_detail",
          component: () => import("./views/dashboard/consultationsN"),
          meta: { isAuth: true, rule: "doctor" },
        },
      ],
    },
    {
      path: "/dashboard",
      component: () => import("@/layouts/newdashboard/index.vue"),
      children: [
        {
          path: "/social-network",
          name: "socialNetwork",
          component: () => import("./views/dashboard/social/index.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/factures",
          name: "factures",
          component: () => import("./views/dashboard/facture/Factures.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/support",
          name: "page_support",
          component: () => import("./views/dashboard/support/index.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/support/:id",
          name: "page_support_discussion",
          component: () => import("./views/dashboard/support/discussion.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/posologie",
          name: "dashboardPosologie",
          component: () => import("./views/dashboard/posologie/index.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/patients",
          name: "listPatients",
          component: () => import("./views/dashboard/clients/ListPatients.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/consultations",
          name: "consultations",
          component: () => import("./views/dashboard/consultations"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/dmp/:id",
          name: "dmp",
          component: () => import("./views/dashboard/clients/dmp/index.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/users",
          name: "users",
          component: () => import("./views/dashboard/users/index.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/agenda/settings",
          name: "settingsAgenda",
          component: () =>
            import("./views/dashboard/agenda/settings/index.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/agenda",
          name: "agenda",
          component: () => import("./views/dashboard/agenda/index.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("./views/dashboard/settings/Settings.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
        {
          path: "/invoicing",
          name: "invoicing",
          component: () => import("./views/dashboard/invoicing/index.vue"),
          meta: { isAuth: true, rule: "doctor" }
        },
        {
          path: "/patient/:patientId",
          name: "patientDetails",
          component: () => import("./views/dashboard/patients_n/show.vue"),
          meta: { isAuth: true, rule: "doctor" },
        },
      ],
    },
    // ===================================  Home Side Routes =================================================//
    {
      path: "/",
      component: () => import("@/layouts/home/index.vue"),
      children: [
        {
          path: "/simulation-gains",
          name: "simulation",
          component: () => import("@/views/simulation/index.vue"),
          meta: { rule: "user" },
        },
        {
          path: "/pricing",
          name: "pricingPage",
          component: () => import("@/views/home/pricing/index.vue"),
          meta: { rule: "user" },
        },
        {
          path: "/",
          name: "newHome",
          component: () => import("@/views/home/index.vue"),
          meta: {
            title: "dok.ma | Construire ensemble la santé de demain",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
        {
          path: "/medecin/acte/:acte?/:city?",
          name: "doctorsListActes",
          component: () => import("@/views/home/doctorsList/doctors.vue"),
          meta: {
            title: "dok.ma | Construire ensemble la santé de demain",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
        {
          path: "/medecins/:city?/:query?",
          name: "doctorsList",
          component: () => import("@/views/home/doctorsList/doctors.vue"),
          meta: {
            title: "dok.ma | Construire ensemble la santé de demain",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
        {
          path: "/docteurs/:city?/:query?",
          name: "doctorsListold",
          component: () => import("@/views/home/doctorsList/doctors.vue"),
          meta: {
            title: "dok.ma | Construire ensemble la santé de demain",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
        {
          path: "/register",
          name: "newSingUp",
          component: () => import("@/views/home/signup.vue"),
          meta: {
            title: "Votre Espace sur dok.ma - Créer votre compte sur dok.ma",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            isGuest: true,
            rule: "user",
          },
        },
        {
          path: "/login",
          name: "newSignIn",
          component: () => import("@/views/home/signin.vue"),
          meta: {
            title: "Votre Espace sur dok.ma - Se connecter à mon compte dok.ma",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            isGuest: true,
            rule: "user",
          },
        },
        {
          path: "/medecin/:speciality?/:city?/:slug",
          name: "profileDoctor",
          component: () => import("@/views/home/profile/index.vue"),
          meta: {
            title: "dok.ma | Prendre rendez-vous chez un professionnel",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
        {
          path: "/docteur/:speciality?/:city?/:slug",
          name: "profileDoctorOld",
          component: () => import("@/views/home/profile/index.vue"),
          meta: {
            title: "dok.ma | Prendre rendez-vous chez un professionnel",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
        {
          path: "/verfication/email",
          name: "verficationEmail",
          component: () => import("@/views/home/verficationEmail.vue"),
          meta: {
            title: "dok.ma | vérifier votre email",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            isGuest: true,
            rule: "user",
          },
        },
        {
          path: "/identity/verify",
          name: "identityVerify",
          component: () => import("@/views/home/identity-verification"),
          meta: { isGuest: true, rule: "user" },
        },
        {
          path: "/book/appointment",
          name: "bookAppointment",
          component: () => import("@/views/home/appointment/create"),
          meta: {
            title: "dok.ma",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
        {
          path: "/settings/profile",
          name: "patintSettings",
          component: () => import("@/views/home/settings/index.vue"),
          meta: {
            title: "dok.ma",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
        {
          path: "/policies",
          name: "policies",
          component: () => import("@/views/statics/page-policies.vue"),
          meta: {
            rule: "user",
            title:
              "dok.ma | Politique de protection des données à caractère personnel",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
          },
        },
        {
          path: "/cgu",
          name: "cgu",
          component: () => import("@/views/statics/page-cgu.vue"),
          meta: {
            rule: "user",
            title: "dok.ma | CGU - Conditions d'utilisation des services",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
          },
        },
        {
          path: "/cgv",
          name: "cgv",
          component: () => import("@/views/statics/page-cgv.vue"),
          meta: {
            rule: "user",
            title: "dok.ma maroc | CGV - Conditions d'utilisation des ventes",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
          },
        },
        {
          path: "/faq",
          name: "faq",
          component: () => import("@/views/statics/page-faq.vue"),
          meta: {
            rule: "user",
            title: "dok.ma maroc | CGV - Conditions d'utilisation des ventes",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
          },
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/fullPage/index.vue"),
      children: [
        {
          path: "/discovery",
          name: "HomeNew",
          component: () => import("@/views/new-home/index.vue"),
          meta: {
            rule: "user",
            title: "dok.ma maroc | Construire ensemble la santé de demain",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
          },
        },
        {
          path: "/pages/not-authorized",
          name: "notAuthorized",
          component: () => import("./views/notFound.vue"),
          meta: {
            title: "dok.ma | Construire ensemble la santé de demain",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
      ],
    },
    {
      path: "/gateway/:maskPath",
      name: "gateway",
      component: () => import("./views/gateway.vue"),
      meta: { rule: "user" },
    },
    // ===================================  Full Blank Page  ================================================= //
    {
      path: "",
      component: () => import("@/layouts/auth/index.vue"),
      children: [
        {
          path: "/auth/2fa",
          name: "2fa",
          component: () => import("./views/auth/MobileVerification.vue"),
          meta: { rule: "user" },
        },
      ],
    },
    {
      path: "",
      component: () => import("@/layouts/auth/index.vue"),
      children: [
        {
          path: "/auth/two-factor-auth",
          name: "TwoFactorAuth",
          component: () => import("./views/auth/TwoFactorAuth.vue"),
          meta: { rule: "user" },
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/layouts/fullPage/index.vue"),
      children: [
        {
          path: "/magic-link/:token",
          name: "magicLink",
          component: () => import("./views/magic-link.vue"),
          meta: { rule: "user" },
        },
        {
          path: "/auth/password-reset",
          name: "newPassword",
          component: () => import("./views/auth/newPassword.vue"),
          meta: { isGuest: true, rule: "user" },
        },
        {
          path: "/forget/password",
          name: "forgetPassword",
          component: () => import("./views/auth/forgetPassword.vue"),
          meta: { isGuest: true, rule: "user" },
        },
        {
          path: "/invitation",
          name: "invitation",
          component: () => import("@/views/pages/Invitation.vue"),
          meta: { isGuest: true, rule: "user" },
        },
        {
          path: "/invitation/inscription",
          name: "invitationInscription",
          component: () => import("@/views/pages/InvitationInscription.vue"),
          meta: { isGuest: true, rule: "user" },
        },
      ],
    },

    // ===================================  Dok Pharma  ================================================= //
    {
      path: "",
      component: () => import("@/layouts/pharmacy/Main.vue"),
      children: [
        {
          path: "/pharmacy/prescription",
          name: "pharmacyPrescription",
          component: () => import("@/views/pharmacy/prescription.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/prescription/:id",
          name: "pharmacyPrescriptionByID",
          component: () => import("@/views/pharmacy/prescriptionView.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/patients",
          name: "pharmacyPatients",
          component: () => import("@/views/pharmacy/patientel/patients.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/patients/:id/drugs",
          name: "pharmacyPatient",
          component: () => import("@/views/pharmacy/patientel/patient.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/patients/:id/ordonnances",
          name: "pharmacyordonnances",
          component: () => import("@/views/pharmacy/patientel/ordonnance.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/medication",
          name: "pharmacyMedication",
          component: () => import("@/views/pharmacy/medication.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/posologie",
          name: "pharmacyPosologie",
          component: () => import("@/views/pharmacy/posologie.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/users",
          name: "pharmacyMedicaments",
          component: () => import("@/views/pharmacy/users.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/setting",
          name: "pharmacySetting",
          component: () =>
            import("@/views/pharmacy/settings/teamMemberProfile.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/support",
          name: "pharmacySupport",
          component: () => import("@/views/dashboard/support/index.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
        {
          path: "/pharmacy/support/:id",
          name: "pharmacySupportDiscussion",
          component: () => import("@/views/dashboard/support/discussion.vue"),
          meta: { isAuth: true, rule: "pharmacy" },
        },
      ],
    },
    // ===================================  Dok Labo  ================================================= //
    {
      path: "",
      component: () => import("./layouts/laboratory/Main.vue"),
      children: [
        {
          path: "/laboratory/prescription",
          name: "laboratoryPrescription",
          component: () => import("@/views/laboratory/prescription.vue"),
          meta: { isAuth: true, rule: "laboratory" },
        },
        {
          path: "/laboratory/prescription/:id",
          name: "laboratoryPrescriptionByID",
          component: () => import("@/views/laboratory/prescriptionView.vue"),
          meta: { isAuth: true, rule: "laboratory" },
        },
        {
          path: "/laboratory/patients",
          name: "laboratoryPatients",
          component: () => import("@/views/laboratory/patientel/patients.vue"),
          meta: { isAuth: true, rule: "laboratory" },
        },
        {
          path: "/laboratory/patients/:id/ordonnances",
          name: "laboratoryordonnances",
          component: () =>
            import("@/views/laboratory/patientel/ordonnance.vue"),
          meta: { isAuth: true, rule: "laboratory" },
        },
        {
          path: "/laboratory/users",
          name: "laboratoryUsers",
          component: () => import("@/views/laboratory/users.vue"),
          meta: { isAuth: true, rule: "laboratory" },
        },
        {
          path: "/laboratory/setting",
          name: "laboratorySetting",
          component: () =>
            import("@/views/laboratory/settings/teamMemberProfile.vue"),
          meta: { isAuth: true, rule: "laboratory" },
        },
        {
          path: "/laboratory/support",
          name: "laboratorySupport",
          component: () => import("@/views/dashboard/support/index.vue"),
          meta: { isAuth: true, rule: "laboratory" },
        },
        {
          path: "/laboratory/support/:id",
          name: "laboratorySupportDiscussion",
          component: () => import("@/views/dashboard/support/discussion.vue"),
          meta: { isAuth: true, rule: "laboratory" },
        },
      ],
    },
    // ===================================  Any  ================================================= //
    {
      path: "/",
      component: () => import("@/layouts/fullPage/index.vue"),
      children: [
        {
          path: "*",
          name: "notFound",
          component: () => import("./views/notFound.vue"),
          meta: {
            title: "dok.ma | Construire ensemble la santé de demain",
            description:
              "La plateforme dok.ma est un écosystème digital de soins de santé. Prenez rendez-vous chez votre praticien, partagez votre dossier médical en toute sécurité, bénéficiez d'un meilleur suivi et bien plus encore... ",
            rule: "user",
          },
        },
      ],
    },
  ],

  // set custom query resolver
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query);
    return result ? `?${result}` : "";
  },
});

function patchRouterMethod(router, methodName) {
  router["old" + methodName] = router[methodName];
  router[methodName] = async function (location) {
    return router["old" + methodName](location).catch((error) => {
      if (error.name === "NavigationDuplicated") {
        return this.currentRoute;
      }
      throw error;
    });
  };
}

patchRouterMethod(router, "push");
patchRouterMethod(router, "replace");

export default router;
