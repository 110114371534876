<template>
  <div class="relative" id="contentModal">
    <!-- Desktop -->
    <div
      v-if="isActive"
      class="
        fixed
        inset-0
        hidden
        lg:flex
        justify-center
        items-end
        bg-placeholderInput
        bg-opacity-40
        pb-5
      "
      style="z-index: 1001"
    >
      <div
        id="modal"
        ref="draggableContainer"
        class="bg-white rounded-cu fixed shadow-blue"
        :style="`position: relative;`"
      >
        <div
          class="
            flex
            items-center
            justify-betwwen
            border-0 border-b border-solid border-gray-300
            bg-[#f9f9f9] rounded-t-lg
            px-2
            py-3
          "
        >
          <div class="flex-1">
            <i
              @mousedown="dragMouseDown"
              class="fas fa-arrows-alt text-blackdok text-2xl cursor-move"
            ></i>
            <span v-if="title" class="text-blackdok text-2xl">
              {{ title }}
            </span>
          </div>

          <i
            @click="exitModal"
            class="
              fas
              fa-times
              text-blackdok
              hover:text-dokBlue-ultra
              cursor-pointer
            "
          ></i>
        </div>
        <div
          class="overflow-y-scroll overflow-x-hidden scroll"
          :class="noPadding ? 'py-0' : 'py-2'"
          :style="
            `width: ${width ? `${width}px` : '100%'}; min-width: ${
              minWidth ? `${minWidth}px` : '100%'
            }px; max-width: ${maxWidth ? `${maxWidth}px` : '100%'}; height: ${
              height ? `${height}px` : 'auto'
            }; min-height:  ${minHeight ? `200px` : 'auto'}; max-height: ${
              maxHeight ? `${maxHeight}px` : '550px'
            }`
          "
        >
          <slot></slot>
        </div>
      </div>
    </div>
    <!--Mobile-->
    <div
      class="z-1000 lg:hidden fixed inset-x-0 bottom-0 bg-white w-full shadow-drop"
      v-if="isActive"
    >
      <div class="flex justify-end p-2">
        <i
          @click="exitModal"
          class="
            fas
            fa-times
            text-blackdok
            hover:text-dokBlue-ultra
            cursor-pointer
          "
        ></i>
      </div>
      <div
        class="
          px-2
          py-2
          scrolling-touch
          overflow-y-scroll
          overflow-hidden
          max-h-[450px]
          scroll
          leading-loose
        "
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 100vh;
    @apply bg-gray-200;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-dokBlue-ultra;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-300;
  }
}
</style>

<script>
export default {
  data() {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
        move: true
      }
    };
  },
  methods: {
    dragMouseDown(event) {
      event.preventDefault();
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag(event) {
      event.preventDefault();

      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;

      if (!this.positions.move) return;

      if (this.$refs.draggableContainer) {
        this.$refs.draggableContainer.style.top =
          this.$refs.draggableContainer.offsetTop -
          this.positions.movementY +
          "px";
        this.$refs.draggableContainer.style.left =
          this.$refs.draggableContainer.offsetLeft -
          this.positions.movementX +
          "px";
      }
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    }
  },
  props: {
    title: {
      type: String,
      required: false
    },
    noPadding: {
      type: Boolean,
      required: false
    },
    isActive: {
      type: Boolean,
      required: true
    },
    exitModal: {
      type: Function,
      required: true
    },
    width: {
      type: Number,
      required: false
    },
    maxWidth: {
      type: Number,
      required: false
    },
    minWidth: {
      type: Number,
      required: false
    },
    height: {
      type: Number,
      required: false
    },
    minHeight: {
      type: Number,
      required: false
    },
    maxHeight: {
      type: Number,
      required: false
    }
  }
};
</script>
