export default {
  SET_LIST(state, payload) {
    state.users = !payload.more
      ? payload.data
      : [...state.users, ...payload.data];
  },
  ADD_ITEM(state, payload) {
    state.users.unshift(payload);
  },
  EDIT_ITEM(state, payload) {
    state.users[payload.index] = payload.user;
  },
  DELETE(state, payload) {
    state.users = state.users.filter(client => client._id !== payload);
  }
};
