/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_LIST(state, payload) {
    state.folders = payload.data;
    state.totalItems = payload.totalItems;
  },
  ADD_NEW(state, payload) {
    state.folders.unshift(payload);
  },
  REMOVE_RECORD(state, payload) {
    state.folders.splice(payload, 1);
  },
  EDIT(state, payload) {
    state.folders = state.folders.map(folder => {
      if (folder._id === payload.folder._id) {
        return payload.folder;
      }
      return folder;
    });
  },
  SET(state, payload) {
    state.folder = payload;
  },
  SET_LOADING_AGENDA_STATUS(state, payload) {
    state.isLoadingAgenda = payload;
  }
};
