import axios from "axios";

export default {
  async LIST_INVITATIONS({ commit }, payload) {
    const { data } = await axios.get("network", {
      params: {
        ...payload.data
      }
    });
    payload.onData(data);
  },
  async SENT_INVITATION({ commit }, payload) {
    const { data } = await axios.post("network", payload.data);
    payload.onData(data);
  },
  async CHANGE_STATUS({ commit }, payload) {
    const { data } = await axios.get(
      `network/${payload.id}/${payload.status}`,
      {
        params: {
          ...payload.params
        }
      }
    );
    payload.onData(data);
  },
  async GET_INVITAION({ commit }, payload) {
    const { data } = await axios.get(`network/${payload.id}`);
    payload.onData(data);
  }
};
