/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  folders: state => state.folders,
  folder: state => state.folder,
  totalItems: state => state.totalItems,
  isLoadingAgenda: state => state.isLoadingAgenda
};
