import authService from "../auth/authService";
import { EventBus } from "../event-bus";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
  getDatabase,
  ref,
  onChildAdded,
  onChildRemoved
} from "firebase/database";
export default {
  initialize(token) {
    let firebaseApp = initializeApp({
      apiKey: "AIzaSyD0lhItGJfe6ZNSkNuVIL4lJ_YurWEElYc",
      authDomain: "dok-ma-project.firebaseapp.com",
      databaseURL: "https://dokma.europe-west1.firebasedatabase.app/",
      projectId: "dok-ma-project",
      storageBucket: "dok-ma-project.appspot.com",
      messagingSenderId: "17652200391",
      appId: "1:17652200391:web:a3a37b731bc81b08109fe2",
      measurementId: "G-JCHTG1S350"
    });
    this.database = getDatabase(
      firebaseApp,
      "https://dokma.europe-west1.firebasedatabase.app/"
    );
    this.newMessageListener = [];

    const auth = getAuth();
    signInWithCustomToken(auth, token)
      .then(userCredential => {
        this.attachListeners();
      })
      .catch(err => {
        if (err.code === "auth/invalid-custom-token") {
          EventBus.$emit("refresh-firebase-custom-token");
          // alert("refresh token");
        }
      });
  },
  attachListeners() {
    const waitingRoomRef = ref(
      this.database,
      `/waiting-rooms/${authService.getUserId()}`
    );
    onChildAdded(waitingRoomRef, this.whenPatientJoined);
    onChildRemoved(waitingRoomRef, this.whenPatientLeft);
  },
  onNewMessage(conversationIdHash, listener) {
    if (!this.newMessageListener.includes(listener.name)) {
      this.newMessageListener.push(listener.name);
      const conversationRef = ref(
        this.database,
        `/conversations/${conversationIdHash}`
      );
      onChildAdded(conversationRef, listener.handler);
    } else {
      this.newMessageListener.indexOf();
    }
  },
  whenPatientJoined(snapshot) {
    const data = snapshot.val();
    console.log("new patient joined", data);
  },
  whenPatientLeft(snapshot) {
    const data = snapshot.val();
    console.log("new patient left", data);
  }
};
