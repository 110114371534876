/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/factures/pixinvent
==========================================================================================*/
import axios from "axios";
export default {
  async GET_DATA({ commit }, payload) {
    if (!payload) return;
    const { data } = await axios.get("/posologie", {
      params: {
        term: payload
      }
    });
    commit("SET_LIST", data.data);

    return data;
  }
};
