/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

// import moduleTodo from "./todo/moduleTodo.js";
// import moduleCalendar from "./calendar/moduleCalendar.js";
// import moduleEmail from "./email/moduleEmail.js";
// import moduleECommerce from "./eCommerce/moduleECommerce.js";

import moduleChat from "./chat/moduleChat.js";
import moduleAuth from "./auth/moduleAuth.js";
import moduleClient from "./clients/moduleClient";
import moduleService from "./services/moduleService";
import moduleFolder from "./folders/moduleFolder";
import moduleUser from "./users/moduleUser";
import details from "./details/moduleTask";

import facture from "./factures";
import analytics from "./analytics";
import settings from "./settings";
import task from "./tasks";
import groupe from "./groupes";
import agenda from "./agenda";
import notification from "./notifications";
import recipe from "./recipes";
import file from "./files";
import posologie from "./posologie";
import doctor from "./doctors";
import pharmacy from "./pharmacies";
import laboratory from "./laboratory";
import consultation from "./consultation";
import location from "./location";
import authorization from "./authorization";
import dmp from "./dmp";
import healthbook from "./healthbook";
import support from "./support";
import certificates from "./certificates";
import MedicalDictionary from "./MedicalDictionary";
import payments from "./payments";
import socialNetwork from "./socialNetworks"

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    chat: moduleChat,
    auth: moduleAuth,
    client: moduleClient,
    service: moduleService,
    folder: moduleFolder,
    user: moduleUser,
    social: socialNetwork,
    facture,
    analytics,
    task,
    details,
    settings,
    groupe,
    agenda,
    notification,
    recipe,
    file,
    posologie,
    doctor,
    pharmacy,
    laboratory,
    consultation,
    location,
    authorization,
    dmp,
    healthbook,
    support,
    certificates,
    MedicalDictionary,
    payments,
  },
  strict: false
});
