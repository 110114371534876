"use strict";

import axios from "axios";

export default {
  async GET_PRESCRIPTION({}, payload) {
    const { data } = await axios.get(`pharmacy/prescription/${payload.id}`);
    payload.onData(data);
  },

  async SERVE_DRUG_PRESCRIPTION({}, payload) {
    const { data } = await axios.post(
      `pharmacy/prescription/${payload.data.prescription}/serve-drug`,
      payload.data
    );
    payload.onData(data, payload.data.drug_id);
  },

  async GET_PATIENTS({}, payload) {
    const { data } = await axios.get(`pharmacy/patients`, {
      params: { filterName: payload.filterName, page: payload.page }
    });
    return data;
  },

  async GET_PATIENT_DRUGS({}, payload) {
    const { data } = await axios.get(`pharmacy/patient/${payload.id}/drugs`, {
      params: {
        filterName: payload.filterName,
        filterDate: payload.filterDate,
        page: payload.page
      }
    });
    return data;
  },

  async GET_PATIENT_ORDANNANCES({}, payload) {
    const { data } = await axios.get(
      `pharmacy/patient/${payload.id}/ordonnances`
    );
    return data;
  },

  async GET_SERVED_DRUGS({}, payload) {
    const { data } = await axios.get(`pharmacy/served-drugs`, {
      params: {
        page: payload.page,
        drugName: payload.drugName,
        drugDate: payload.drugDate
      }
    });
    return data;
  },

  async UPDATE_PROFILE({}, payload) {
    const { data } = await axios.put(`pharmacy/updateProfile`, {
      parcours: payload.parcours,
      diplomes: payload.diplomes
    });
    return data;
  },

  async GET_EMPLOYES({}) {
    const { data } = await axios.get(`pharmacy/users`);
    return data;
  },

  async STORE_EMPLOYE({}, payload) {
    const { data } = await axios.post(`pharmacy/user`, { ...payload });
    return data;
  },

  async UPDATE_EMPLOYE({}, payload) {
    const { data } = await axios.put(`pharmacy/user`, { ...payload });
    return data;
  },

  async DELETE_EMPLOYE({}, payload) {
    const { data } = await axios.delete(`pharmacy/user`, {
      params: { id: payload.id }
    });
    return data;
  }
};
