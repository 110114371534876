/**
 * cities action
 */

import axios from "axios";
export default {
  /**
   * [GET_CITIES_LIST
   * @param options.commit
   * @param payload
   */
  async GET_CITIES_LIST({ commit }, payload) {
    if (!payload) return;
    const { data } = await axios.get("/cities", {
      /* params: { term: payload } */
    });
    commit("SET_CITIES_LIST", data.data);
    return data;
  },

  async GET_CITIES_QUARTIERS({}, payload) {
    const { data } = await axios.get(`/cities/${payload.city}`, {});
    return data;
  },
};
