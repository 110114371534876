<template>
  <div
    class="w-full flex flex-col"
    :class="label && 'gap-y-1'"
    :id="generationRandom"
  >
    <label
      :for="`${label}-${generationRandom}`"
      class="font-EffraR text-base text-gray-800"
    >
      {{ label }}
    </label>
    <div class="relative">
      <select
        :class="
          [
            dokDisabled ? 'bg-grayxx ' : 'bg-transparent ',
            !noBorder ? 'border-2 ' : 'border-0 ',
            'border-solid rounded px-1 w-full font-EffraR text-graylabel appearance-none ',
            sizeInput,
            hasLeftIcon && 'pl-10 ',
            hasRightIcon && 'pr-10 ',
            customClass && customClass.includes('border')
              ? customClass
              : 'border-borderInput'
          ].join(' ')
        "
        v-model="valueInput"
        :multiple="multiple ? 'multiple' : false"
        :disabled="!dokDisabled ? false : true"
        @change="changeValue ? changeValue : () => {}"
      >
        <slot name="options"></slot>
      </select>
      <span
        class="absolute right-0 top-0 h-full flex items-center px-2"
        v-if="hasRightIcon"
      >
        <slot name="rightIcon"></slot>
      </span>
      <span
        class="absolute left-0 top-0 h-full flex items-center px-2"
        v-if="hasLeftIcon"
      >
        <slot name="leftIcon"></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      generationRandom: Math.random()
    };
  },
  props: {
    multiple: {
      type: Boolean,
      required: false
    },
    dModel: {
      type: String | Number | Date,
      required: false
    },
    customClass: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: "sm" // sm, md, lg
    },
    dokDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    changeValue: {
      type: Function | Promise,
      required: false,
      default: false
    },
    noBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    valueInput: {
      get() {
        return this.dModel;
      },
      set(value) {
        this.$emit("update:dModel", value);
      }
    },
    hasList() {
      return this.$slots.options ? true : false;
    },
    hasLeftIcon() {
      return this.$slots.leftIcon;
    },
    hasRightIcon() {
      return this.$slots.rightIcon;
    },
    sizeInput() {
      switch (this.size) {
        case "sm":
          return "select-sm";
          break;
        case "md":
          return "select-md";
          break;
        case "lg":
          return "select-lg";
          break;
        default:
          return "select-md";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.select-sm {
  height: 32px;
  font-size: 16px;
}
.select-md {
  height: 40px;
  font-size: 16px;
}
.select-lg {
  height: 48px;
  font-size: 16px;
}
</style>
