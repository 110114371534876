<template>
  <div
    class="w-full relative rounded bg-grayDok-focus shadow"
    :class="!heightFull ? 'h-350' : 'h-full'"
  >
    <!-- Video Frame -->
    <!--------------------------------OWNER---------------------------------->
    <div
      id="ownerUser"
      class="absolute inset-x-0 inset-y-0 overflow-hidden bg-cover bg-no-repeat bg-center"
      :style="`background-image: url('${$checkAvatar(owner.image)}');`"
      ref="patientTrack"
    ></div>
    <!--------------------------------CURRENT---------------------------------->
    <div
      id="currentUser"
      :style="
        `left: 5px; top: 5px; background-image: url('${$checkAvatar(
          $auth.getUserInfo().image
        )}');`
      "
      class="absolute bg-cover bg-no-repeat rounded shadow group"
      :class="!heightFull ? 'h-100 w-100' : 'h-300 w-300'"
      ref="doctorTrack"
    >
      <div
        class="bg-black absolute inset-y-0 inset-x-0 h-full w-full bg-opacity-20 hidden group-hover:flex items-center justify-center"
      >
        <dok-button
          bg="no-class"
          custom-class="w-16 h-16 bg-black bg-opacity-40 flex items-center justify-center"
          @click.native="onSetAction('switchVideo')"
        >
          <i class="fas fa-expand-arrows-alt"></i>
        </dok-button>
      </div>
    </div>
    <!-- End Video Frame -->
    <div class="absolute right-0 inset-y-0">
      {{
        callStatus === "CONNECTED"
          ? "le patient " + statuses[patientStatus]
          : statuses[callStatus]
          ? statuses[callStatus]
          : callStatus
      }}
    </div>
    <!-- Actions -->
    <div
      class="absolute bottom-0 inset-x-0 bg-black bg-opacity-10 h-78 flex justify-center items-center gap-x-2"
    >
      <!--  -->
      <!-- <dok-button
        bg="no-class"
        custom-class="w-16 h-16 bg-black bg-opacity-40 flex items-center justify-center hover:bg-dokBlue-lighter hover:text-dokBlue-dark"
        @click.native="onSetAction('inzoomOwner')"
      >
        <i class="fas fa-search-minus"></i>
      </dok-button> -->
      <dok-button
        bg="no-class"
        custom-class="w-16 h-16 bg-black bg-opacity-40 flex items-center justify-center hover:bg-dokBlue-lighter hover:text-dokBlue-dark"
        @mousedown.native="onClickExpand($event)"
      >
        <i class="fas fa-expand-arrows-alt"></i>
      </dok-button>
      <!-- <dok-button
        bg="no-class"
        custom-class="w-16 h-16 bg-black bg-opacity-40 flex items-center justify-center hover:bg-dokBlue-lighter hover:text-dokBlue-dark"
        @click.native="onSetAction('muted')"
      >
        <i class="fas fa-microphone-alt-slash"></i>
      </dok-button> -->
      <dok-button
        bg="no-class"
        :custom-class="getCallButtonStyle()"
        @click.native="onSetAction('startCall')"
      >
        <i class="fas fa-phone"></i>
      </dok-button>
      <!-- <dok-button
        bg="no-class"
        custom-class="w-16 h-16 bg-black bg-opacity-40 flex items-center justify-center hover:bg-dokBlue-lighter hover:text-dokBlue-dark"
        @click.native="onSetAction('disbledVideo')"
      >
        <i class="fas fa-video"></i>
      </dok-button> -->
      <!-- <dok-button
        bg="no-class"
        custom-class="w-16 h-16 bg-black bg-opacity-40 flex items-center justify-center hover:bg-dokBlue-lighter hover:text-dokBlue-dark"
        @click.native="onSetAction('config')"
      >
        <i class="fas fa-cog"></i>
      </dok-button>
      <dok-button
        bg="no-class"
        custom-class="w-16 h-16 bg-black bg-opacity-40 flex items-center justify-center hover:bg-dokBlue-lighter hover:text-dokBlue-dark"
        @click.native="onSetAction('zoomOnwer')"
      >
        <i class="fas fa-search-plus"></i>
      </dok-button> -->
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import {
  connect,
  createLocalVideoTrack,
  createLocalTracks
} from "twilio-video";

export default {
  props: {
    heightFull: {
      type: Boolean,
      required: false
    },
    conversationIdHash: {
      type: String,
      required: true
    },
    conversationId: {
      type: String,
      required: true
    },
    owner: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      positions: {},
      switchVideo: false,
      zoomOnwer: false,
      zoomMuch: 1,
      accessToken: null,
      callStatus: null,
      patientStatus: "DISCONNECTED",
      statuses: {
        RECONNECTING: "reconnecté",
        DISCONNECTED: "déconnecté",
        CONNECTED: "connecté",
        CONNECTING: "En cours de connexion",
        FAILED: "échoué"
      },
      isVideoEnabled: true
    };
  },
  mounted() {
    this.doctorTrack = this.$refs.doctorTrack;
    this.patientTrack = this.$refs.patientTrack;
    this.initialize();
  },
  beforeDestroy() {
    if (this.activeRoom) {
      this.activeRoom.disconnect();
    }
  },
  methods: {
    initialize() {
      this.$store.dispatch("user/GET_TWILIO_TOKEN", this.onTwilioToken);
    },
    onTwilioToken(data) {
      const {
        data: { token: accessToken }
      } = data;
      this.accessToken = accessToken;
    },
    async startCall() {
      if (this.callStatus === "CONNECTED") {
        this.callStatus = null;
        this.activeRoom.disconnect();
        this.$store.dispatch("chat/SEND_VIDEO_CALL_STATUS", {
          conversationId: this.conversationId,
          consultationId: this.$route.params.id,
          status: "CALL-DECLINED"
        });
        return;
      }
      this.doctorVideoTrack = await createLocalTracks({
        audio: true,
        video: true
      });
      this.doctorVideoTrack.forEach(track => {
        if (track.kind === "video") {
          this.doctorTrack.appendChild(track.attach());
        }
      });
      // this.doctorTrack.appendChild(.attach());
      this.callStatus = "CONNECTING";
      connect(this.accessToken, {
        name: this.conversationIdHash,
        audio: true,
        video: { width: 300 }
      })
        .then(this.onConnect)
        .catch(this.onFailure);
    },
    onConnect(room) {
      this.activeRoom = room;
      this.$store.dispatch("chat/SEND_VIDEO_CALL_STATUS", {
        conversationId: this.conversationId,
        consultationId: this.$route.params.id,
        status: "CALL-OFFER"
      });
      room.participants.forEach(this.attachParticipantTracks);
      this.callStatus = "CONNECTED";
      room.on("participantConnected", this.attachParticipantTracks);
      room.on("disconnected", () => {
        console.log("disconnected");

        this.callStatus = null;
        // this.doctorVideoTrack.stop();
        this.doctorVideoTrack.forEach(track => {
          track.stop();
          track.detach().forEach(function(mediaElement) {
            mediaElement.srcObject = null;
            mediaElement.remove();
          });
        });
      });
      room.on("participantDisconnected", participant => {
        console.log("participantDisconnected");

        this.patientStatus = "DISCONNECTED";
      });
      room.on("participantReconnected", participant => {
        console.log("participantReconnected");

        this.patientStatus = "CONNECTED";
      });
      room.on("participantReconnecting", participant => {
        console.log("participantReconnecting");
        this.patientStatus = "RECONNECTING";
      });
      room.on("reconnecting", error => {
        console.log("reconnecting");

        this.callStatus = "CONNECTING";
        if (error.code === 53001) {
          console.log("Reconnecting your signaling connection!", error.message);
        } else if (error.code === 53405) {
          console.log("Reconnecting your media connection!", error.message);
        }
      });
      room.on("reconnected", error => {
        console.log("reconnected");
        this.callStatus = "CONNECTED";
        if (error.code === 53001) {
          console.log("Reconnecting your signaling connection!", error.message);
        } else if (error.code === 53405) {
          console.log("Reconnecting your media connection!", error.message);
        }
      });
    },
    onFailure(err) {
      this.callStatus = "FAILED";
    },
    attachParticipantTracks(participant) {
      this.patientStatus = "CONNECTED";
      participant.tracks.forEach(publication => {
        if (publication.track) {
          this.patientTrack.appendChild(publication.track.attach());
        }
      });
      participant.on("trackSubscribed", track => {
        console.log("trackSubscribed");

        if (track.kind === "video") {
          this.patientTrack.appendChild(track.attach());
        }
      });
      participant.on("trackUnsubscribed", (track, publication, participant) => {
        this.patientStatus = "DISCONNECTED";
        console.log("trackUnsubscribed");

        if (track.kind === "video" || track.kind === "audio") {
          track.detach().forEach(function(mediaElement) {
            mediaElement.remove();
            mediaElement.srcObject = null;
          });
        }
      });
    },
    onDragEnter(event) {
      let domVideo = document.getElementById("videoOwner");
      domVideo.style.transformOrigin = "top center";
    },
    onClickExpand(event) {
      EventBus.$emit("EXPAND_VIDEO_CALL_DOK");
    },
    onSetAction(type) {
      let domVideo = document.getElementById("videoOwner");
      let self = this;
      switch (type) {
        case "muted":
          console.log("MUTED VIDEO CALL");
          break;
        case "startCall":
          this.startCall();
          break;
        case "disbledVideo":
          if (this.isVideoEnabled) {
            this.doctorVideoTrack.forEach(track => {
              if (track.kind === "video") {
                track.disable();
              }
            });
            this.isVideoEnabled = false;
          } else {
            this.doctorVideoTrack.forEach(track => {
              if (track.kind === "video") {
                track.enable();
              }
            });
            this.isVideoEnabled = true;
          }
          console.log("DISBLE VIDEO CALL");
          break;
        case "config":
          console.log("CONFIG VIDEO CALL");
          break;
        case "zoomOnwer":
          self.zoomMuch = self.zoomMuch + 0.5;
          self.zoomOnwer = true;
          self.$nextTick(() => {
            domVideo.style.cssText = `-ms-zoom: ${self.zoomMuch}; -moz-transform: scale(${self.zoomMuch}); -moz-transform-origin: 0 0; -o-transform: scale(${self.zoomMuch}); -o-transform-origin: 0 0; -webkit-transform: scale(${self.zoomMuch}); -webkit-transform-origin: 0 0;`;
          });
          break;
        case "inzoomOwner":
          if (self.zoomMuch === 1) return;
          self.zoomMuch = self.zoomMuch - 0.5;
          self.zoomOnwer = true;
          self.$nextTick(() => {
            domVideo.style.cssText = `-ms-zoom: ${self.zoomMuch}; -moz-transform: scale(${self.zoomMuch}); -moz-transform-origin: 0 0; -o-transform: scale(${self.zoomMuch}); -o-transform-origin: 0 0; -webkit-transform: scale(${self.zoomMuch}); -webkit-transform-origin: 0 0;`;
          });
          break;
        case "switchVideo":
          self.switchVideo = !self.switchVideo;

          self.$nextTick(() => {
            console.log(self.switchVideo);
          });
          self.$forceUpdate();
          break;
        default:
          break;
      }
    },
    getCallButtonStyle() {
      let defaultStyle = "w-20 h-20 flex items-center justify-center group ";
      if (!this.callStatus) {
        defaultStyle += "bg-green-500";
        return defaultStyle;
      }
      if (this.callStatus === "CONNECTING") {
        defaultStyle += "bg-yellow-600";
        return defaultStyle;
      }
      if (this.callStatus === "CONNECTED") {
        defaultStyle += "bg-red-600";
        return defaultStyle;
      }
      if (this.callStatus === "FAILED") {
        defaultStyle += "bg-green-600";
        return defaultStyle;
      }
    }
  }
};
</script>

<style lang="scss">
#currentUser {
  video {
    @apply h-full w-full object-cover;
  }
}
#ownerUser {
  video {
    @apply h-full w-full object-cover;
  }
}
</style>
