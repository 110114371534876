import axios from "axios";
export default {
  async GET_LIST({ commit }, payload) {
    let search = payload ? { ...payload.search } : {};
    let other = payload ? { ...payload.other } : {};
    let sort = payload ? { ...payload.sortBy } : {};
    let page = payload ? { ...payload.page } : {};

    const { data } = await axios.get("users/patients", {
      params: { ...search, ...other, ...sort, ...page }
    });

    if (payload) {
      payload.onData(data);
    }

    commit("SET_LIST", data.data);

    return data.data;
  },

  async SET_CONVERSATION({ commit }, payload) {
    const { data } = await axios.post("chat/conversations", payload);
    if (data.ok) return data;
  },

  async GET_ITEM({ commit }, payload) {
    const { data } = await axios.get(`users/${payload}`);
    if (payload && payload.onData) {
      payload.onData(data);
    }
    commit("SET_ITEM", data.data);
  },

  async CREATE_CLIENT({ commit }, payload) {
    const { data } = await axios.post("users", payload);
    if (data.ok) {
      commit("ADD_ITEM", data.data);
      return {
        ok: true
      };
    }
    return data;
  },

  async UPDATE_CLIENT({ commit }, payload) {
    const { data } = await axios.put(`users/${payload._id}`, payload);
    commit("UPDATE", data.data);
    return data;
  },

  async DELETE({ commit }, payload) {
    const { data } = await axios.delete(`client/${payload}`);
    if (data.ok) commit("DELETE", payload);
    return data;
  },

  async GET_PATIENT({ commit }, payload) {
    const { data } = await axios.get(`users/${payload.patientId}`);
    if (payload && payload.onData) {
      payload.onData(data);
    }
    commit("SET_ITEM", data.data);
  }
};
