<template>
  <div>
    <div
      class="bg-white w-auto border-2 border-solid border-gray-300 font-EffraM rounded-cu flex items-center px-3 cursor-pointer relative"
      :class="icon ? 'pl-8' : ''"
      :style="getSize()"
      @click="dropdown = !dropdown"
    >
      {{ size }}
      <span
        class="absolute top-0 left-0 h-full flex items-center px-3"
        v-if="icon"
      >
        <i :class="icon"></i>
      </span>
      <span class="absolute right-0 h-full flex items-center px-3">
        <i class="fas fa-chevron-down"></i>
      </span>
    </div>
    <div
      v-if="dropdown"
      class="rounded-cu border-2 border-solid border-gray-300 bg-white my-2 flex flex-col"
    >
      <a
        href="#homme"
        class="w-full py-3 px-2 text-black font-EffraR border-0 border-b border-solid border-gray-300"
        >HOMME</a
      >
      <a href="#homme" class="w-full py-3 px-2 text-black font-EffraR">MALE</a>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    size: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      dropdown: false
    };
  },
  methods: {
    getSize() {
      if (this.size === "lg") return "height: 48px;";
      if (this.size === "md") return "height: 39px;";
      if (this.size === "sm") return "height: 33px;";
    }
  }
};
</script>

<style>
</style>