export default {
  eventById: state => eventId =>
    state.calendarEvents.filter(event => event._id == eventId)[0],
  settings: state => state.settings,
  // Simple Calendar Getters
  simpleCalendareventById: state => eventId =>
    state.simpleCalendarEvents.filter(event => event.id == eventId)[0],
  categories: state => state.categories,
  slots: state => state.slots,
  reasons: state => state.reasons
};
