let colors = {
  primary: "#00439a",
  success: "#28C76F",
  danger: "#EA5455",
  warning: "#FF9F43",
  dark: "#1E1E1E"
};

import Vue from "vue";
import Vuesax from "vuesax";
Vue.use(Vuesax, {
  theme: {
    colors
  }
});

// CONFIGS
const themeConfig = {
  theme: "light", // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: true, // options[Boolean]: true, false(default)
  navbarColor: "#fff", // options[String]: HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: "floating", // options[String]: floating(default) / static / sticky / hidden
  footerType: "static", // options[String]: static(default) / sticky / hidden
  routerTransition: "zoom-fade",
  disableCustomizer: false,
  hideScrollToTop: false,
  disableThemeTour: true
};

export default themeConfig;
