<template>
  <button
    :type="type"
    :class="
      [
        iconButton,
        customClass,
        sizeInput,
        backgroundInput,
        hasRounded,
        'font-EffraR cursor-pointer '
      ].join(' ')
    "
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    dClick: {
      type: Function,
      required: false
    },
    customClass: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: "sm" // sm, md, lg
    },
    type: {
      type: String,
      required: false,
      default: "button" // button, submit
    },
    bg: {
      type: String,
      required: false,
      default: "blue" // Blue, Transparent, White
    },
    hasIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    hasRounded() {
      return this.rounded ? "rounded-cu" : "rounded";
    },
    iconButton() {
      return this.hasIcon ? "rounded-full h-4848 w-4848" : "";
    },
    backgroundInput() {
      switch (this.bg) {
        case "no":
          return "bg-transparent";
          break;
        case "blue":
          return "px-2 py-1 bg-dokBlue-ultra text-white border-0";
          break;
        case "bluelighter":
          return "px-2 py-1 bg-dokBlue-lighter text-dokBlue-ultra border-0";
          break;
        case "rose":
          return "px-2 py-1 bg-rosDok-ultra text-white border-0";
          break;
        case "gray":
          return "px-2 py-1 bg-grayborder text-black border-0";
          break;
        case "trans":
          return "px-2 py-1 bg-white text-dokBlue-ultra border-2 border-solid border-dokBlue-ultra";
          break;
        case "whiteBLackText":
          return "bg-white text-[#333638] font-EffraR text-base leading-5 border-0";
          break;
        case "trans_red":
          return "px-2 py-1 bg-transparent text-red-700 border-2 border-solid border-red-700 hover:bg-red-700 hover:text-white";
          break;
        case "yellow":
          return "px-2 py-1 bg-white text-[#AE851A] border-2 border-solid border-[#AE851A]";
          break;
        case "action":
          return "px-2 py-1 bg-transparent border-0";
          break;
        case "green":
          return "px-2 py-1 bg-green-500 text-white border-0";
          break;
        case "red":
          return "px-2 py-1 bg-red-500 text-white border-0";
          break;
        case "white":
          return "px-2 py-1 bg-transparent text-dokBlue-ultra border-0";
          break;
        case "none":
          return "px-2 py-1 bg-transparent border-0";
          break;
        case "no-class":
          return "text-white rounded-full border-0";
          break;
        case "bBlue":
          return "bg-transparent border-2 border-solid border-dokBlue-ultra font-EffraR text-dokBlue-ultra";
          break;
        case "grayBorder":
          return "border-2 border-solid border-borderInput rounded px-3 font-EffraR text-borderInput bg-transparent";
          break;
      }
    },
    sizeInput() {
      switch (this.size) {
        case "sm":
          return "button-sm";
          break;
        case "md":
          return "button-md";
          break;
        case "lg":
          return "button-lg";
          break;
        case "delete":
          return "button-delete";
          break;
        default:
          return "button-md";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.button-sm {
  height: 32px;
  font-size: 16px;
}

.button-md {
  height: 40px;
  font-size: 16px;
}

.button-lg {
  height: 48px;
  font-size: 16px;
}

.button-delete {
  background: #bc3939;
}
</style>
