/*=========================================================================================
  File Name: moduleTodoState.js
  Description: Todo Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
export default {
  tasks: [],
  taskStatus: [
    {
      text: "EN COURS",
      value: "INPROGRESS"
    },
    {
      text: "TERMINÉ",
      value: "DONE"
    },
    {
      text: "EN ATTENTE",
      value: "PENDING"
    },
    {
      text: "REJETÉ",
      value: "REJECTED"
    }
  ],
  taskTags: [
    {
      text: "Pas important",
      value: "not",
      color: "primary"
    },
    {
      text: "Important",
      value: "important",
      color: "warning"
    },
    {
      text: "Urgent",
      value: "urgent",
      color: "danger"
    }
  ],
  taskFilter: "all",
  taskSearchQuery: ""
};
