/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_LIST(state, payload) {
    state.groupes = payload.data;
    state.totalItems = payload.totalItems;
  },

  ADD_NEW(state, payload) {
    state.groupes.unshift(payload);
  },
  REMOVE_RECORD(state, payload) {
    state.groupes.splice(payload, 1);
  },
  UPDATE(state, payload) {
    state.groupes[payload.index] = payload.groupe;
  }
};
