import axios from "axios";
import { EventBus } from "@/event-bus";
import { event } from "vue-analytics";
export default {
  // New Code

  async CREATE_EVENT({ commit }, payload) {
    // Create New Event
    const { data } = await axios.post("agenda/events/create", payload.form);
    return data;
  },

  async UPDATE_EVENT({ commit }, payload) {
    const { data } = await axios.put(
      `agenda/events/${payload._id}/change-state`,
      payload
    );
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },

  async INDEX_EVENTS({ commit }, payload) {
    // Get All Events
    const { data } = await axios.get("agenda/events", {
      params: {
        ...payload,
      },
    });
    return data;
  },

  async INDEX_PENDING_EVENTS({ commit }, payload) {
    // Get All Events
    const { data } = await axios.get("agenda/events/pending", {
      params: {
        ...payload,
      },
    });
    return data;
  },

  async UPDATE_DATE_EVENT({ commit }, payload) {
    const { data } = await axios.put(
      `agenda/events/${payload.id}/change-date`,
      {
        startAt: payload.toDate,
      }
    );
    return data;
  },

  async UPDATE_STATUS_EVENT({ commit }, payload) {
    const { data } = await axios.put(
      `agenda/events/${payload._id}/change-status`,
      {
        status: payload.status,
      }
    );
    return data;
  },

  async TRANSFER_EVENT({ commit }, { doctorId, eventId }) {
    const { data } = await axios.put(`agenda/events/${eventId}/change-owner`, {
      doctorId,
    });
    return data;
  },

  async GENERATED_HOURS({ commit }, payload) {
    // GENERATED HOURS
    const { data } = await axios.get(
      `agenda/events/available-slots?date=${payload.date}&categoryId=${payload.category}`
    );
    return data;
  },

  async DELETE_EVENT({ commit }, payload) {
    // DELETE EVENT
    const { data } = await axios.delete(`agenda/events/${payload._id}`);
    return data;
  },

  async EVENT_CHANGE_VISIBLILITY({ commit }, payload) {
    const { data } = await axios.get(
      `agenda/events/${payload.id}/change-visibility?status=${payload.status}`
    );
    return data;
  },

  /////////////////////////////////////////////// From Here to the bottom is Old Code ///////////////////////////////////////////////
  async getList({ commit }) {
    const { data } = await axios.get("events");
    commit("SET_LIST", data.data);
  },

  async addEventToCalendar({ commit }, payload) {
    const { data } = await axios.post("events", payload);
    if (data.ok) {
      commit("ADD_EVENT_TO_CALENDAR", data.data);
    }
  },

  async ediCalendarEvent({ commit }, payload) {
    const { data } = await axios.put(`events/${payload._id}`, payload);
    if (data.ok) {
      commit("EDIT_CALENDAR_EVENT", data.data);
    }
  },

  async removeCalendarEvent({ commit }, eventId) {
    const { data } = await axios.delete(`events/${eventId}`);
    if (data.ok) commit("REMOVE_CALENDAR_EVENT", eventId);
  },

  async UPDATE_SETTINGS({ commit }, payload) {
    const { data } = await axios.post("agenda/settings", payload.data);
    commit("SET_SETTINGS", data.data);
    payload.onData(data.data);
  },

  async GET_SETTINGS({ commit }) {
    const { data } = await axios.get("agenda/settings");
    commit("SET_SETTINGS", data.data);
  },

  async GET_UPCOMING_RDV({ commit }, payload) {
    const { data } = await axios.get(
      "agenda/events/upcoming-video-consultations"
    );
    //console.log(data);
    payload.onData(data);
  },

  async GET_CATEGORIES({ commit }, payload) {
    const { data } = await axios.get("agenda/categories", {
      params: payload.data,
    });
    if (data.data.length === 0) {
      commit("SET_CATEGORIES", data.data);
      EventBus.$emit("on-setting-agenda", { isAgendSettingsVisible: false });
    }
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async UPDATE_CATEGORY({ commit }, payload) {
    const { data } = await axios.post("agenda/categories", payload.data);
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async DELETE_CATEGORY({ commit }, payload) {
    const { data } = await axios.delete("agenda/categories/".concat(payload));
  },

  async REACTIVATE_CATEGORY({ commit }, payload) {
    const { data } = await axios.post(
      "agenda/categories/".concat(payload) + "/reactivate"
    );
  },
  // async GET_REASONS({ commit }, payload) {
  //   const { data } = await axios.get("agenda/reasons");
  //   commit("SET_REASONS", data.data);
  //   if (payload.onData) {
  //     payload.onData(data);
  //   }
  // },
  // async UPDATE_REASON({ commit }, payload) {
  //   const { data } = await axios.post("agenda/reasons", payload.data);
  //   if (payload.onData) {
  //     payload.onData(data);
  //   }
  // },
  // async DELETE_REASON({ commit }, payload) {
  //   const { data } = await axios.delete("agenda/reasons/".concat(payload));
  // },
  async GET_SLOTS_BY_DOCTOR({ commit }, payload) {
    const { data } = await axios.get(
      `agenda/slots/doctor/${payload.id}/available`
    );
    payload.onData(data);
  },

  async GET_SLOTS({ commit }, payload) {
    const { data } = await axios.get("agenda/slots");
    data.data = data.data.sort(function (a, b) {
      return a.day - b.day;
    });

    commit("SET_SLOTS", data.data);

    if (payload && payload.onData) {
      payload.onData(data);
    }
  },

  async UPDATE_SLOT({ commit }, payload) {
    const { data } = await axios.post("agenda/slots", payload.data);
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async GET_AVAILABLE_SLOTS({ commit }, payload) {
    // //console.log(payload);
    const { data } = await axios.get(
      `agenda/slots/${payload.id}/${payload.categoryId}`,
      {
        params: { day: payload.day },
      }
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async GET_DOCTOR_AVAILABLE_SLOTS({ commit }, payload) {
    const { data } = await axios.get(
      "agenda/doctor/slots/".concat(payload.categoryId),
      {
        params: { day: payload.day },
      }
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async GET_WEEKDAYS_AVAILIBILITY({ commit }, payload) {
    const { data } = await axios.get(
      `doctors/${payload.id}/${payload.categoryId}/available-days`
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async GET_DOCTOR_CATEGORIES({ commit }, payload) {
    const { data } = await axios.get("agenda/doctors/categories", {
      params: payload.data,
    });
    commit("SET_CATEGORIES", data.data);
    if (data.data.length === 0) {
      EventBus.$emit("on-setting-agenda", { isAgendSettingsVisible: false });
    }
    if (payload.onData) {
      payload.onData(data);
    }
  },
};
