import fr from "./lang/fr";
import ar from "./lang/ar";

export default {
  fr: {
    ...fr
  },
  ar: {
    ...ar
  }
};
