import axios from "axios";

export default {
  async LOGIN({ commit }, payload) {
    const { data } = await axios.post("users/auth", payload.userDetails);

    if (data.ok) {
      commit("UPDATE_AUTHENTICATED_USER", data);
    } else {
      if (data.errCode === "INACTIVE-ACCOUNT") {
        if (!payload.isBooking) {
          window.location = `/identity/verify?token=${data.activationToken}`;
        }
      }
    }

    payload.onData(data);
  },
  async REGISTER({ commit }, payload) {
    const { data } = await axios.post("users/register", payload.userDetails);
    payload.onData(data);
  },
  async CREATE_PATIENT({ commit }, payload) {
    const { data } = await axios.post(
      "users/create-patient",
      payload.userDetails
    );
    payload.onData(data);
  },
  async UPDATE_PASSWORD({ commit }, payload) {
    const { data } = await axios.put("users/password-update", payload.data);
    payload.onData(data);
  },
  async RESEND_TWO_FACTOR_TOKEN({ commit }, { token, onData }) {
    const { data } = await axios.post("users/auth/resend-two-factor-token", {
      token,
    });
    onData(data);
  },
  async VERIFY_TWO_FACTOR_TOKEN({ commit }, { token, authToken, onData }) {
    const { data } = await axios.post("users/auth/verify-two-factor-token", {
      token,
      authToken,
    });
    if (data.ok) {
      commit("UPDATE_AUTHENTICATED_USER", data);
    }
    onData(data);
  },
  async EXCHANGE_TOKEN({ commit }, { token, authToken, onData }) {
    console.log("users/exchange-token");
    const { data } = await axios.get("users/exchange-token", {
      params: {
        token,
      },
    });
    if (data.ok) {
      commit("UPDATE_AUTHENTICATED_USER", data);
    }
    onData(data);
  },
};
