import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "@/router";

Vue.use(AclInstaller);

let initialRole = "doctor";
if (localStorage.getItem("userRole"))
  initialRole = localStorage.getItem("userRole");

export default new AclCreate({
  initial: initialRole,
  notfound: {
    path: "/pages/not-authorized",
    forwardQueryParams: true
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    laboratory: new AclRule("laboratory").or("laboratory-member").generate(),
    doctor: new AclRule("doctor").or("team-member").generate(),
    pharmacy: new AclRule("pharmacy").or("pharmacy-member").generate(),
    user: new AclRule("user")
      .or("patient")
      .or("doctor")
      .or("team-member")
      .or("pharmacy")
      .or("pharmacy-member")
      .or("laboratory")
      .or("laboratory-member")
      .generate(),
    patient: new AclRule("user").or("patient").generate(),
    isPublic: new AclRule("read").generate(),
    praticien: new AclRule("praticien")
      .or("doctor")
      .or("team-member")
      .or("pharmacy")
      .or("pharmacy-member")
      .or("laboratory")
      .or("laboratory-member")
      .generate()
  }
});
