import Vue from "vue";
import router from "./router";
// Configuration VueAnalytics

/*
console.log("MODDDEEEEE:"+process.env.VUE_APP_NODE_ENV, process.env.VUE_APP_GOOGLE_ANALYTICS_UA);
//console.log("____________))))))))))) ", process.env.VUE_APP_GOOGLE_ANALYTICS, process.env.VUE_APP_GOOGLE_ANALYTICS_UI)
import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS,
  router: router,
  commands: {
    trackName(name = "unknown") {
      this.$ga.event("randomClicks", "click", "name", name);
    },
    trackAction(
      category = "category",
      action = "action",
      label = "label",
      value = 0
    ) {
      this.$ga.event({
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value
      });
    }
  }
});

*/
