/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  tasks: state => state.tasks,
  taskTags: state => state.taskTags,
  taskStatus: state => state.taskStatus,
  taskFilter: state => state.taskFilter,
  taskSearchQuery: state => state.taskSearchQuery,
};
