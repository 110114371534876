/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_LIST(state, payload) {
    state.factures = payload;
  },
  ADD_ITEM(state, payload) {
    state.factures.push(payload);
  },
  DELETE(state, payload) {
    state.factures = state.factures.filter(facture => facture._id !== payload);
  }
};
