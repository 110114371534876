/*=========================================================================================
  File Name: moduleTodoState.js
  Description: Todo Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  totalItems: 0,
  services: [{
      id: 0,
      title: "Refactor Code",
      desc: "Pie liquorice wafer cotton candy danish. Icing topping jelly-o halvah pastry lollipop.",
    },
    {
      id: 0,
      title: "Refactor Code",
      desc: "Pie liquorice wafer cotton candy danish. Icing topping jelly-o halvah pastry lollipop.",
    },
    {
      id: 0,
      title: "Refactor Code",
      desc: "Pie liquorice wafer cotton candy danish. Icing topping jelly-o halvah pastry lollipop.",
    },


  ],
};
