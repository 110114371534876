// axios
import authService from "./auth/authService";
import axios from "axios";
import axiosRetry from "axios-retry";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function(response) {
    if (
      response &&
      response.data &&
      !response.data.ok &&
      response.data.message &&
      (response.data.errCode === "E_INVALID_JWT_TOKEN" ||
        response.data.errCode === "E_JWT_TOKEN_EXPIRED")
    ) {
      authService.logOut();
      window.location = `/login?errCode=${response.data.errCode}`;
      return;
    }
    return response;
  },
  function(error) {
    if (error.response && error.response.status === 401) {
      authService.logOut();
      window.location = "/login";
      return;
    }

    return Promise.reject(error);
  }
);

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: () => {
    return 2;
  }
});
export default axios;
