/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_LIST(state, payload) {
    state.tasks = payload;
  },
  ADD_NEW(state, payload) {
    state.tasks.unshift(payload);
  },
  REMOVE_RECORD(state, payload) {
    state.tasks.splice(payload, 1);
  },
  EDIT(state, payload) {
    state.tasks[payload.index] = payload.task;
  },
  APPLY_TASK_FILTER(state, payload) {
    state.taskFilter = payload;
  },
  SET_TASK_QUERY(state, payload) {
    state.taskSearchQuery = payload;
  },


};
