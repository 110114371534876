<template>
  <div class="grid gap-x-4 gap-y-3 grid-cols-3">
    <!-- Fullname -->
    <div class="flex flex-col">
      <label for="fullName" class="font-EffraR text-gray-700 text-base"
        >Prénom & nom:
      </label>
      <div class="text-black font-EffraR text-lg">
        {{ `${user.firstName} ${user.lastName}` }}
      </div>
    </div>
    <!-- Téléphone -->
    <div class="flex flex-col">
      <label for="telephone" class="font-EffraR text-gray-700 text-base"
        >Téléphone
      </label>
      <div class="text-black font-EffraR text-lg">
        {{ `${user.mobile || "--"}` }}
      </div>
    </div>
    <!-- Numero d'affici... -->
    <div class="flex flex-col">
      <label for="cnss" class="font-EffraR text-gray-700 text-base"
        >N° d'affiliation CNSS
      </label>
      <div class="text-black font-EffraR text-lg">
        {{ `${user.CNSS || "--"}` }}
      </div>
    </div>
    <!-- Numero cin... -->
    <div class="flex flex-col">
      <label for="cin" class="font-EffraR text-gray-700 text-base">CIN </label>
      <div class="text-black font-EffraR text-lg">
        {{ `${user.cin || "--"}` }}
      </div>
    </div>
    <!-- Email address. -->
    <div class="flex flex-col">
      <label for="email" class="font-EffraR text-gray-700 text-base"
        >Email</label
      >
      <div class="text-black font-EffraR text-lg">
        {{ `${user.email || "--"}` }}
      </div>
    </div>
    <!-- Assurance complémentaire. -->
    <div class="flex flex-col">
      <label for="assurance" class="font-EffraR text-gray-700 text-base"
        >Assurance complémentaire</label
      >
      <div class="text-black font-EffraR text-lg">
        {{
          `${
            user.insurance && user.insurance.name ? user.insurance.name : "--"
          }`
        }}
      </div>
    </div>
    <!-- Birthday. -->
    <div class="flex flex-col">
      <label for="assurance" class="font-EffraR text-gray-700 text-base"
        >Date de naissance</label
      >
      <div class="text-black font-EffraR text-lg">
        {{ user.birthday | moment("DD/MM/YYYY") }}
        <span v-if="formatAge(user.birthday)"
          >({{ formatAge(user.birthday) }} ans)</span
        >
      </div>
    </div>
    <!-- full Address. -->
    <div class="flex flex-col">
      <label for="assurance" class="font-EffraR text-gray-700 text-base"
        >Adresse</label
      >
      <div class="text-black font-EffraR text-lg">
        {{
          user.landingPage && user.landingPage.address
            ? user.landingPage.address.label
            : "-"
        }}
        <strong>{{
          user.landingPage &&
          user.landingPage.address &&
          user.landingPage.address.city
            ? user.landingPage.address.city.name
            : "-"
        }}</strong>
      </div>
    </div>
    <!-- Société contractonte -->
    <div class="flex flex-col hidden">
      <label for="assurance" class="font-EffraR text-gray-700 text-base">
        Société contractante</label
      >
      <div class="text-black font-EffraR text-lg">
        {{
          user.insurance && user.insurance.company
            ? user.insurance.company
            : "--"
        }}
      </div>
    </div>
    <!-- Lieu-->
    <div class="flex flex-col">
      <label for="assurance" class="font-EffraR text-gray-700 text-base"
        >Lieu</label
      >
      <div class="text-black font-EffraR text-lg">{{
          user.birthplace
            ? user.birthplace
            : "--"
        }}</div>
    </div>
    <!-- Profession-->
    <div class="flex flex-col">
      <label for="assurance" class="font-EffraR text-gray-700 text-base"
        >Profession</label
      >
      <div class="text-black font-EffraR text-lg">{{ user.job || "--" }}</div>
    </div>
    <!-- Number police -->
    <div class="flex flex-col">
      <label for="assurance" class="font-EffraR text-gray-700 text-base"
        >N° police</label
      >
      <div class="text-black font-EffraR text-lg">
        {{
          user.insurance && user.insurance.numPolice
            ? user.insurance.numPolice
            : "--"
        }}
      </div>
    </div>
    <!-- Etat civil-->
    <div class="flex flex-col">
      <label for="assurance" class="font-EffraR text-gray-700 text-base"
        >Etat civil</label
      >
      <div class="text-black font-EffraR text-lg">
        {{ $FaSituation(user.relationship) || "-" }}
      </div>
    </div>
    <!-- Langues-->
    <div class="flex flex-col">
      <label for="assurance" class="font-EffraR text-gray-700 text-base"
        >Langues</label
      >
      <div class="text-black font-EffraR text-lg">
        {{
          user.landingPage && user.landingPage.languages.length
            ? user.landingPage.languages.join(", ")
            : "--"
        }}
      </div>
    </div>
    <!-- Langues-->
    <div class="flex flex-col">
      <label for="adhesion" class="font-EffraR text-gray-700 text-base"
        >N° d'adhésion</label
      >
      <div class="text-black font-EffraR text-lg">
        {{
          user.insurance && user.insurance.numAdhesion
            ? user.insurance.numAdhesion
            : "--"
        }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatAge: function (dateString) {
      return moment().diff(dateString, "years", false);
    },
    moment() {
      return moment;
    },
  },
};
</script>
