<template>
  <div>
    <div
      v-if="dropdown"
      @click="dropdown = false"
      class="fixed inset-0  z-1"
      style="background-color:rgba(0, 0, 0, 0);"
    ></div>
    <div class="relative z-2">
      <div
        class="relative w-full bg-transparent border border-solid rounded pl-2 md:pl-2 pr-2 md:pr-3 py-2 h-12 flex items-center cursor-pointer"
        style="border-color: #e4e4e4;"
        @click="onDropDown"
      >
        <span class="flex items-center gap-x-1 " v-if="!dropdown">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              fill="#ABABAB"
              d="M10 17.416l4.125-4.125c.816-.815 1.371-1.855 1.596-2.986.225-1.132.11-2.305-.332-3.37-.441-1.066-1.189-1.977-2.148-2.618-.96-.641-2.087-.983-3.241-.983s-2.281.342-3.24.983c-.96.64-1.708 1.552-2.15 2.617-.44 1.066-.556 2.24-.331 3.37.225 1.132.78 2.172 1.596 2.987L10 17.416zm0 2.357L4.697 14.47c-1.05-1.05-1.764-2.386-2.053-3.84-.29-1.455-.14-2.963.427-4.334.568-1.37 1.529-2.541 2.762-3.366C7.067 2.106 8.517 1.666 10 1.666c1.483 0 2.933.44 4.167 1.264 1.233.825 2.194 1.996 2.762 3.366.568 1.37.716 2.879.427 4.334-.29 1.454-1.004 2.79-2.053 3.84L10 19.773zm0-8.94c.442 0 .866-.175 1.178-.488.313-.313.489-.737.489-1.179 0-.442-.176-.866-.489-1.178-.312-.313-.736-.488-1.178-.488-.442 0-.866.175-1.179.488-.312.312-.488.736-.488 1.178 0 .442.176.866.488 1.179.313.313.737.488 1.179.488zm0 1.667c-.884 0-1.732-.351-2.357-.976-.625-.626-.976-1.473-.976-2.358 0-.884.35-1.731.976-2.357.625-.625 1.473-.976 2.357-.976.884 0 1.732.351 2.357.976.625.626.976 1.473.976 2.357 0 .884-.35 1.732-.976 2.357-.625.626-1.473.977-2.357.977z"
            />
          </svg>
          <span
            class="block truncate font-EffraR text-base text-black"
            v-if="cityName"
            >{{ cityName }}</span
          >
        </span>
        <input
          id="typingSearchCity"
          type="text"
          placeholder="Ville"
          class="w-50 border-0 font-EffraR"
          v-model="searchTyping"
          v-if="dropdown"
        />
      </div>

      <ul
        class="absolute z-10 mt-3 w-full bg-white rounded p-1 overflow-auto min-w-200 max-h-48 overflow-y-scroll shadow border border-solid border-gray-200"
        v-if="dropdown"
        tabindex="-1"
      >
        <li
          v-for="city in citys"
          :key="city._id"
          class="text-black cursor-pointer relative py-2 px-2 font-EffraR block hover:bg-gray-100"
          :class="
            cityName === city.name
              ? 'bg-dokBlue-lighter text-dokBlue-ultra'
              : ''
          "
          @click="onClickCity(city)"
        >
          {{ city.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";

export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchTyping: null,
      citys: [],
      dropdown: false,
      searchClient: algoliasearch(
        "2BJUOM1BDB",
        "7906f008c0346b78f39d051fa293387f"
      ),
      cityName: null
    };
  },
  watch: {
    searchTyping(newVal, oldVal) {
      if (newVal.length) {
        this.onCitys();
      }
    }
  },
  async created() {
    this.onCitys();
  },
  computed: {
    propsValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    }
  },
  methods: {
    onClickCity(city) {
      if (city.name) {
        this.propsValue = city.name;
        this.cityName = city.name;
        this.dropdown = false;
      }
    },
    async onCitys() {
      const index = this.searchClient.initIndex(
        "dokma_dev_cities_principal_first"
      );

      const data = await index.search(
        this.searchTyping ? this.searchTyping : "",
        {
          hitsPerPage: 618
        }
      );

      this.citys = data.hits;
    },
    onDropDown() {
      this.dropdown = true;
      setTimeout(() => {
        document.getElementById("typingSearchCity").focus();
      }, 500);
    }
  }
};
</script>

<style></style>
