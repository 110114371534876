/**
 * cities action
 */

import axios from "axios";
export default {
  async GET_VACCIN_LIST({ commit }, payload) {
    const { data } = await axios.get("/healtbook/datas/vaccins", {});
    // commit("SET_VACCIN_LIST", data.data);
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },
  /**
   * GET_VACCIN_LIST data
   * @param options.commit
   * @param payload
   */
  async GET_PATIENT_VACCIN_LIST({ commit }, payload) {
    const { data } = await axios.get(
      "/healtbook/patient/" + payload.patientId + "/vaccins",
      {}
    );
    // commit("SET_VACCIN_LIST", data.data);
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },
  async REMOVE_PATIENT_VACCIN({ commit }, payload) {
    if (!payload) return;

    let data = await axios.delete(
      "healtbook/patient/" + payload.patientId + "/vaccins/" + payload.vaccinId
    );

    if (payload.onData) {
      payload.onData(payload.vaccinId, data.data);
    }

    return data;
  },
  /**
   * assign/remove vaccin for patient
   * @param {[type]} options.commit
   * @param Object payload
   */
  async SAVE_PATIENT_VACCIN({ commit }, payload) {
    if (!payload) return;
    const { data } = await axios.post(
      "healtbook/patient/" + payload.patientId + "/vaccins",
      payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  /**
   * GET_ALLERGIE_LIST
   * @param {[type]} options.commit
   * @param Object payload
   */
  async GET_PATIENT_ALLERGIE_LIST({ commit }, payload) {
    // const { data } = await axios.get("/healtbook/datas/allergies", {});
    const { data } = await axios.get(
      "/healtbook/patient/" + payload.patientId + "/allergies",
      {}
    );
    // commit("SET_VACCIN_LIST", data.data);
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },

  /**
   * GET_ALLERGIE_LIST
   * @param {[type]} options.commit
   * @param Object payload
   */
  async GET_ALLERGIE_LIST({ commit }, payload) {
    // const { data } = await axios.get("/healtbook/datas/allergies", {});
    const { data } = await axios.get(
      "/healtbook/datas/allergies/" + payload.category,
      {}
    );

    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },
  /**
   * GET_ANTECEDENT_MEDICAUX_LIST
   * @param {[type]} options.commit
   * @param Object payload
   */
  async GET_ANTECEDENT_MEDICAUX_LIST({ commit }, payload) {
    // const { data } = await axios.get("/healtbook/datas/antecedentmedicaux", {});
    const { data } = await axios.get(
      "/healtbook/datas/antecedentmedicaux/patient/" + payload.patientId,
      {}
    );
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },

  /**
   * GET_ANTECEDENT_CHIRURGICAUX_LIST
   * @param {[type]} options.commit
   * @param Object payload
   */
  async GET_ANTECEDENT_CHIRURGICAUX_LIST({ commit }, payload) {
    if (!payload) return;
    // const { data } = await axios.get(
    //   `healtbook/datas/antecedentchirurgicaux`,{}
    // );
    const { data } = await axios.get(
      "/healtbook/datas/antecedentchirurgicaux/patient/" + payload.patientId,
      {}
    );

    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },

  /**
   * GET_ANTECEDENT_MEDICAUX_LIST
   * @param {[type]} options.commit
   * @param Object payload
   */
  async GET_MEDICAMENTS_LIST({ commit }, payload) {
    // const { data } = await axios.get("/healtbook/datas/medicaments", {});
    const { data } = await axios.get(
      "/healtbook/datas/medicaments/patient/" + payload.patientId,
      {}
    );
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },

  /**
   * SAVE   or remove PATIENT_ANTECEDENT_MEDICAL
   * @param {[type]} options.commit
   * @param Object payload
   */
  async SAVE_PATIENT_ANTECEDENT_MEDICAL({ commit }, payload) {
    if (!payload) return;
    let data = {};
    // if(payload.data.isChecked) {
    data = await axios.post(
      "healtbook/patient/" + payload.patientId + "/antecedentmedical",
      payload.data
    );
    // }
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async REMOVE_PATIENT_ANTECEDENT_MEDICAL({ commit }, payload) {
    if (!payload) return;
    let data = {};

    data = await axios.delete(
      "healtbook/patient/" +
        payload.patientId +
        "/antecedentmedical/" +
        payload.medId
    );

    if (payload.onData) {
      payload.onData(data);
    }
  },

  /**
   * SAVE  or remove PATIENT_ANTECEDENT_CHIRURGICAL
   * @param {[type]} options.commit
   * @param Object payload
   */
  async SAVE_PATIENT_ANTECEDENT_CHIRURGICAL({ commit }, payload) {
    if (!payload) return;
    const { data } = await axios.post(
      `healtbook/patient/${payload.patientId}/antecedentchirurgical`,
      payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async REMOVE_PATIENT_ANTECEDENT_CHIRURGICAL({ commit }, payload) {
    if (!payload || !payload.data._id) return;
    const { data } = await axios.delete(
      `healtbook/patient/${payload.patientId}/antecedentchirurgical/${payload.data._id}`
      // payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async REMOVE_ALL_PATIENT_ANTECEDENT_CHIRURGICAUX({}, payload) {
    const { data } = await axios.delete(
      `healtbook/patient/${payload.patientId}/antecedentchirurgicaux/all`
      // payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  /**
   * SAVE  or remove medicament for a patient
   * @param {[type]} options.commit
   * @param Object payload
   */
  async SAVE_PATIENT_MEDICAMENT({ commit }, payload) {
    if (!payload) return;
    const { data } = await axios.post(
      `healtbook/patient/${payload.patientId}/medicament`,
      payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  async REMOVE_PATIENT_MEDICAMENT({}, payload) {
    const { data } = await axios.delete(
      `healtbook/patient/${payload.patientId}/medicament/${payload.medicamentId}`
      // payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },
  /**
   * REMOVE_ALL_PATIENT_MEDICAMENTS
   * @param {[type]} payload
   */
  async REMOVE_ALL_PATIENT_MEDICAMENTS({}, payload) {
    const { data } = await axios.delete(
      `healtbook/patient/${payload.patientId}/medicaments/all`
      // payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  /**
   * SAVE_PATIENT_ALLERGIE
   * @param {[type]} options.commit
   * @param Object payload
   */
  async SAVE_PATIENT_ALLERGIE({ commit }, payload) {
    if (!payload) return;
    const { data } = await axios.post(
      "healtbook/patient/" + payload.patientId + "/allergies",
      payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  /**
   * remove_PATIENT_ALLERGIE
   * @param  {[type]} options.commit
   * @param  Object payload
   * @return {[type
   */
  async REMOVE_PATIENT_ALLERGIE({ commit }, payload) {
    if (!payload) return;
    //console.log(payload);
    const { data } = await axios.delete(
      `healtbook/patient/${payload.patientId}/allergie/${payload.allergy}`,
      payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  /**
   * REMOVE_ALL_PATIENT_ALLERGIE
   * @param {[type]} payload
   */
  async REMOVE_ALL_PATIENT_ALLERGIE({}, payload) {
    const { data } = await axios.delete(
      `healtbook/patient/${payload.patientId}/allergies/all`
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  /**
   * GET LIST of ANTECEDENT FAMILIAUX
   * @param {[type]} options.commit
   * @param {[type]} payload
   */
  async GET_ANTECEDENT_FAMILIAUX_LIST({ commit }, payload) {
    // const { data } = await axios.get("/healtbook/datas/antecedentfamiliaux", {});
    const { data } = await axios.get(
      "/healtbook/datas/antecedentfamiliaux/patient/" + payload.patientId,
      {}
    );

    if (payload.onData) {
      payload.onData(data);
    }
    // return data;
  },

  async SAVE_PATIENT_ANTECEDENT_FAMILIAL({ commit }, payload) {
    if (!payload) return;
    const { data } = await axios.post(
      `healtbook/patient/${payload.patientId}/antecedentfamilial`,
      payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  /**
   * remove PATIENT ALLERGIE
   * @param  {[type]} options.commit
   * @param  Object payload
   * @return {[type
   */
  async REMOVE_PATIENT_ANTECEDENT_FAMILIAUX({ commit }, payload) {
    if (!payload) return;
    const { data } = await axios.delete(
      `healtbook/patient/${payload.patientId}/antecedentfamilial/${payload.data._id}`,
      payload.data
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  /**
   * REMOVE_ALL_PATIENT_ANTECEDENT_FAMILIAUX
   * @param {[type]} payload
   */
  async REMOVE_ALL_PATIENT_ANTECEDENT_FAMILIAUX({}, payload) {
    if (!payload) return;
    const { data } = await axios.delete(
      `healtbook/patient/${payload.patientId}/antecedentfamiliaux/all`,
      {}
    );
    if (payload.onData) {
      payload.onData(data);
    }
  },

  async GET_PATIENT_HEALT_BOOK({ commit }, payload) {
    const { data } = await axios.get(
      "/healtbook/patient/" + payload.patientId + "/healtbook",
      {}
    );
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },
  /**
   * SEARCH_ANTECEDENT_FAMILIAUX_LIST
   * @param {[type]} options.commit
   * @param {[type]} payload
   */
  async SEARCH_ANTECEDENT_FAMILIAUX_LIST({ commit }, payload) {
    const { data } = await axios.get(
      "/healtbook/datas/antecedentmedicals/search",
      {
        params: {
          ...payload.params
        }
      }
    );
    return data;
  },
  /**
   * UPDATE_PATIENT_HEALTH_PROFILE
   * @param {[type]} payload
   */
  async UPDATE_PATIENT_HEALTH_PROFILE({}, payload) {    
    const { data } = await axios.post(
      `healtbook/patient/${payload.patientId}/healthprofile`,
      {
        ...payload.params
      }
    );
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },
  /**
   * GET_PATIENT_HEALTH_PROFILE
   * @param {[type]} options.commit
   * @param {[type]} payload
   */
  async GET_PATIENT_HEALTH_PROFILE({ commit }, payload) {
    const { data } = await axios.get(
      `/healtbook/patient/${payload.patientId}/healthprofile`,
      {}
    );
    if (payload.onData) {
      payload.onData(data);
    }
    return data;
  },

  async CHECK_AUTHORIZATION(_, { patientId, onData }) {
    const { data } = await axios.get(
      `/patients/${patientId}/check-healthbook-authorization`
    );
    return onData(data);
  }
};
