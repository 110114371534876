<template>
  <div></div>
  <!-- <div
    class="w-full bg-blackdok py-3 text-center text-white text-base font-bold block z-1000"
  >
    Ce site est en version bêta. Nous travaillons dur pour vous fournir le
    meilleur service possible.
  </div> -->
</template>

<script>
export default {};
</script>

<style>
</style>