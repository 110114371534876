import axios from "axios";

export default {
  async REQUEST_AUTHORIZATION({ commit }, payload) {
    // //console.log(payload);
    if (!payload.patientId || !payload.authScope) {
      throw Error("patientId AND authScope is required");
    }
    const { data } = await axios.get(
      `patients/${payload.patientId}/request-authorization`,
      {
        params: {
          authScope: payload.authScope
        }
      }
    );
    payload.onData(data);
  },
  async VALIDATE_AUTHORIZATION({ commit }, payload) {
    if (!payload.patientId || !payload.token || !payload.authScope) {
      throw Error(
        "patientId AND authScope, token is required to validate authorization"
      );
    }
    const { data } = await axios.get(
      `patients/${payload.patientId}/validate-authorization`,
      {
        params: {
          token: payload.token,
          authScope: payload.authScope
        }
      }
    );
    payload.onData(data);
  }
};
